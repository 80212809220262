import React from "react";

function MobilePreview({ setPreview, allContentValues }) {
  return (
    <section className="main-div preview-div mobile">
      <div className="heading">
        <div className="title">
          <h6>Preview Content Piece On Mobile</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              setPreview("");
            }}
          >
            CLOSE
          </button>
        </div>
      </div>

      <div className="mobile-div">
        <div className="pt24">
          <div className="cards-row">
            <div>
              <article className="article-card">
                <figure>
                  {<img src={allContentValues.mobileImage} alt={"Front"} />}
                </figure>
                <div className="text">
                  <div className="labels">
                    <mark>{allContentValues?.attribute || ""}</mark>
                    <small>IMAGE: {allContentValues?.imageSource || ""}</small>
                  </div>

                  <p>{allContentValues?.title || ""}</p>
                </div>
                <footer>
                  <div>
                    <button className="blank-btn">
                      <i className="bookmark-icon"></i>
                    </button>
                    <button className="blank-btn">
                      <i className="share-icon"></i>
                    </button>
                  </div>
                  <div>
                    <button className="blank-btn listen">
                      {allContentValues.ctaText}
                      <i className="right-caret-icon"></i>
                    </button>
                  </div>
                </footer>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobilePreview;
