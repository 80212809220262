import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  GeographyOptions,
  MembershipOptions,
  PaymentPlanOptions,
  StatusOptions,
} from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ReportTable from "../components/ReportPaidTable";
const heading = ["Live", "Expired", "Lapsed", "Cancelled"];

const Referral_Paid_Memberships = ({
  setLoading,
  selectedDate,
  activeSalesAgent,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [referralPaidMemberships, setReferralPaidMemberships] = useState();
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);
  const [membershipType, setMembershipType] = useState("");
  const [planType, setPlanType] = useState(PaymentPlanOptions[0].value);
  const [paymentType, setPaymentType] = useState(StatusOptions[0].value);
  const [salesAgentId, setSalesAgentId] = useState("");

  useEffect(() => {
    getReferralPidMemberships();
  }, [byGeography, salesAgentId]);

  useEffect(() => {
    getReferralPidMemberships();
  }, [selectedDate]);

  useEffect(() => {
    if (membershipType || planType || paymentType) {
      getReferralPidMemberships();
    }
  }, [membershipType, planType, paymentType]);

  const getReferralPidMemberships = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-paid-memberships?byGeography=${byGeography}&planType=${planType}&paymentType=${paymentType}&isSendEmailReport=${isSendEmailReport}&`;
    if (selectedDate !== null) {
      url = url + `selectedDate=${selectedDate.toISOString()}&`;
    }
    if (membershipType && membershipType !== "ALL") {
      url = url + `membershipType=${membershipType}&`;
    }
    if (salesAgentId) {
      url = url + `salesAgentId=${salesAgentId}&`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setReferralPaidMemberships(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getReferralPidMemberships(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Referral Paid Memberships</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          options={GeographyOptions}
          defaultValue={GeographyOptions[0]}
          onChange={(data) => {
            setGeography(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsByGeography: data.value,
            });
          }}
        />
        <Select
          options={MembershipOptions}
          defaultValue={MembershipOptions[0]}
          onChange={(data) => {
            setMembershipType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsMembershipType: data.value,
            });
          }}
        />
        <Select
          options={PaymentPlanOptions}
          defaultValue={PaymentPlanOptions[0]}
          onChange={(data) => {
            setPlanType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsPlanType: data.value,
            });
          }}
        />
        <Select
          options={StatusOptions}
          defaultValue={StatusOptions[0]}
          onChange={(data) => {
            setPaymentType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsPaymentType: data.value,
            });
          }}
        />
        <Select
          options={activeSalesAgent}
          defaultValue={activeSalesAgent && activeSalesAgent[0]}
          getOptionLabel={(data) => {
            return data.email;
          }}
          getOptionValue={(data) => {
            return data._id;
          }}
          onChange={(data) => {
            setSalesAgentId(data._id);
            setDataForSendReport({
              ...dataForSendReport,
              salesAgentIdForReferralPaidMemberships: data._id,
            });
          }}
        />
      </div>
      <div>
        <ReportTable heading={heading} data={referralPaidMemberships} />
      </div>
    </div>
  );
};

export default Referral_Paid_Memberships;
