import React from "react";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import DesktopPreview from "./DesktopPreview";
import MobilePreview from "./MobilePreview";

const Preview = ({
  preview,
  setPreview,
  setIsPreview,
  allContentValues,
  onSave,
  initialValues,
  isEdit,
  setAddNew,
  setView,
}) => {
  const handlePublishContent = () => {
    if (initialValues._id) {
      //call api
      publishContent(initialValues._id);
    } else {
      errorAlert("Save All content to publish");
    }
  };

  const publishContent = (id) => {
    customAxios
      .patch("admin/contentBank/publish-card", {
        cardId: id,
      })
      .then((res) => {
        const message = res.data.message;
        successAlert(message);
        setAddNew(false);
        setView(false);
      })
      .catch((err) => {
        const message = err?.response?.data?.message || "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <div className="">
        <p>
          {preview === "desktop" ? (
            <DesktopPreview
              setPreview={setPreview}
              setIsPreview={setIsPreview}
              allContentValues={allContentValues}
            />
          ) : (
            <MobilePreview
              setPreview={setPreview}
              setIsPreview={setIsPreview}
              allContentValues={allContentValues}
            />
          )}
        </p>
      </div>

      <div className="main-div content-btns mt24">
        <div className="btns-lower">
          <div>
            <button
              className="btn-white long"
              onClick={() => {
                onSave(allContentValues);
              }}
              disabled={!isEdit}
            >
              SAVE CONTENT PIECE
            </button>
          </div>
          {initialValues && initialValues.status === "DRAFT" && (
            <div>
              <button className="btn long" onClick={handlePublishContent}>
                PUBLISH
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Preview;
