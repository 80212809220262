import { createSlice } from "@reduxjs/toolkit";
import { getCategory, createCategory} from "src/redux/services/category.service";

const initialState = {
  category: [],
  loading: false,
  message: "",
  count: 0

};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    // getUsers data
    [getCategory.pending]: (state) => {
      state.loading = true;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.category = payload?.data?.data;
      state.count= payload?.data?.count
    },
    [getCategory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.category = null;
    },


    // createUser data
    [createCategory.pending]: (state) => {
      state.loading = true;
    },
    [createCategory.fulfilled]: (state, { payload }) => {
        state.loading = false;
       state.message = payload?.data?.message;
     },
    [createCategory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
     },


  },
});

export default categorySlice.reducer;
