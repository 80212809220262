import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import axios from "src/utils/axios";
import PersonalMembership from "./PersonalMembership";
import GiftMemberShip from "./GiftMemberShip";
import { getUsers } from "src/redux/services/user.service";
import { useDispatch, useSelector } from "react-redux";
import { successAlert, errorAlert } from "src/utils/alerts";
import { errorMessage, isAddAccess } from "src/helpers";
import Loader from "src/components/common/loaders";
import AddUser from "./AddUser";
import {
  MAX_PAGE_LIMIT_IN_SLIDER,
  NOT_AUTHORIZED_TEXT,
} from "src/constant/global";

const InitialFilterData = {
  name: "",
  email: "",
  transactionId: "",
  customerId: "",
};

// Users Management

const Users = () => {
  const [filterData, setFilterData] = useState(InitialFilterData);
  const [isGiftMemberShip, setGiftMemberShip] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [membershipType, setMembershipType] = useState("");
  const [users, setUsers] = useState([]);
  const [isAddUser, setIsAddUser] = useState(false);
  const [pageNo, setPage] = useState(1);

  const dispatch = useDispatch();
  const { loading: apiLoader } = useSelector((state) => {
    return state.users;
  });

  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key !== "customerId"
        ? event.target.value.toLowerCase()
        : event.target.value.toUpperCase();

    setFilterData({
      ...filterData,
      [key]: value.trim(),
    });
  };

  const handleSearch = ({ pageNo = 1, resetData }) => {
    const isEmpty = Object.values(filterData).every(
      (x) => x === null || x === ""
    );
    if (!isEmpty) {
      const data = {
        ...filterData,
        membershipType: membershipType,
        pageNo: pageNo || 1,
        limit: MAX_PAGE_LIMIT_IN_SLIDER,
      };
      if (resetData) {
        setCurrentSlide(0);
      }
      dispatch(
        getUsers({
          ...data,
          callback: (response) => {
            const userDataArray = resetData
              ? response?.data?.result
              : users.concat(response?.data?.result);
            setUsers(userDataArray);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (isGiftMemberShip) {
      const isEmpty = Object.values(filterData).every(
        (x) => x === null || x === ""
      );
      const noGifted =
        users && users[currentSlide] && users[currentSlide]?.giftedTo?.length;

      if (isEmpty) {
        setUsers([]);
        setMembershipType("GIFTED");
      } else if (!isEmpty && noGifted === undefined) {
        setMembershipType("GIFTED");
      }
    }
  }, [isGiftMemberShip, filterData]);

  const handleAddUser = (values, { resetForm }) => {
    axios
      .post("admin/add-user", {
        email: values.email,
      })
      .then((res) => {
        if (res && res.data && res?.data?.data) {
          resetForm();
          successAlert(res?.data?.message);
          setIsAddUser(false);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data?.message) {
          message = errorMessage(err);
        }
        errorAlert(message);
      });
  };

  useEffect(() => {
    if (membershipType !== "GIFTED" && Object.values(filterData).length > 0) {
      handleSearch({});
    }
  }, [membershipType]);

  return (
    <>
      {
        <AddUser
          open={isAddUser}
          setOpen={setIsAddUser}
          handleAddUser={handleAddUser}
        />
      }
      <div className="main-div user-manage">
        <div className="heading">
          <div className="title">
            <h6 className="">User Management</h6>
          </div>

          <div className="btn-grp bg-grey">
            <button
              className={`${!isGiftMemberShip ? "btn" : "btn-blank"}`}
              onClick={() => {
                setGiftMemberShip(false);
                if (membershipType === "GIFTED") {
                  setUsers([]);
                }
                setMembershipType("");
              }}
            >
              Personal Membership
            </button>
            <button
              className={`${isGiftMemberShip ? "btn" : "btn-blank"}`}
              onClick={() => {
                setGiftMemberShip(true);
                if (
                  !(
                    users &&
                    users?.length > 0 &&
                    users[currentSlide]?.giftedTo &&
                    users[currentSlide]?.giftedTo?.length > 0
                  )
                ) {
                  setUsers([]);
                  setMembershipType("GIFTED");
                }
              }}
            >
              Gift Membership{" "}
              <span>
                {users &&
                  users?.length > 0 &&
                  membershipType !== "GIFTED" &&
                  users[currentSlide]?.giftedTo &&
                  users[currentSlide]?.giftedTo?.length > 0 &&
                  `(${users[currentSlide].giftedTo?.length})`}
              </span>
            </button>
          </div>

          <div className="ml-auto">
            <button
              className="btn"
              autoFocus
              onClick={() => {
                const isAdd = isAddAccess("USER_MANAGEMENT");
                if (isAdd) {
                  setIsAddUser(true);
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {" "}
              ADD USER
            </button>
          </div>
        </div>
        <Filters
          onChange={handleChange}
          onSearch={() => {
            handleSearch({ resetData: true });
          }}
        />
        {apiLoader && <Loader />}
        {!isGiftMemberShip ? (
          <PersonalMembership
            membershipType={membershipType}
            usersData={users}
            setCurrentSlide={setCurrentSlide}
            currentSlide={currentSlide}
            getUserList={handleSearch}
            pageNo={pageNo}
            setPage={setPage}
          />
        ) : (
          <GiftMemberShip
            usersData={
              membershipType !== "GIFTED"
                ? users[currentSlide]?.giftedTo &&
                  users[currentSlide].giftedTo?.length > 0
                  ? [users[currentSlide]]
                  : []
                : users
            }
            membershipType={membershipType}
            setCurrentSlide={setCurrentSlide}
            currentSlide={currentSlide}
            getUserList={handleSearch}
            pageNo={pageNo}
            setPage={setPage}
          />
        )}
        {users && users?.length === 0 && (
          <div className="no-content">
            <h6>No User Data</h6>
            <p>
              Search user by Email, Name, Customer ID,
              <br /> and Transaction ID.
            </p>
          </div>

          // <div className="no-content">
          //   <h6>No Content</h6>
          //   <p>No data available right now.</p>
          // </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Users);
