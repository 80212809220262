import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import userSlice from "./features/userSlice";
import navSlice from "./features/navSlice";
import authSlice from "./features/authSlice";
import categorySlice from "./features/categorySlice";
import groupSlice from "./features/groupSlice";
import paginationSlice from "./features/paginateSlice";
import tutorialSlice from "./features/tutorialSlice";
import contentBankSlice from "./features/contentBankSlice";
import membershipRatesSlice from "./features/membershipRatesSlice";
import { successAlert } from "src/utils/alerts";
import subAdminSlice from "./features/subAdminSlice";
import complimentaryMembershipSlice from "./features/complimentaryMembership";
import editorsPickSlice from "./features/editorPickSlice";
import referralPartnerSlice from "./features/referralPartnerSlice";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["auth"],
};

const reducers = combineReducers({
  users: userSlice,
  navSlice,
  auth: authSlice,
  category: categorySlice,
  groups: groupSlice,
  pagination: paginationSlice,
  tutorial: tutorialSlice,
  content: contentBankSlice,
  membershipRates: membershipRatesSlice,
  subAdmin: subAdminSlice,
  complimentaryMembership: complimentaryMembershipSlice,
  editorPick: editorsPickSlice,
  referralPartner: referralPartnerSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    // this applies to all keys defined in persistConfig(s)
    storageSession.removeItem("persist:root");
    state = {};
    successAlert("User logout successfully.");
  }
  return persistedReducer(state, action);
};

export var store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
