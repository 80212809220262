import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "src/views/pages/dashboard";

// Local imports
import { PrivateRoute } from "./privateRoutes";

// Containers
import DefaultLayout from "../components/common/layout/DefaultLayout";

// Pages
import Login from "../views/pages/login";
import ResetPassword from "../views/pages/reset-password";

import {
  UserManagement,
  SalesManagement,
  SalesAgentManagement,
  ReportAnalytics,
  ComplimentaryMembership,
  // ContentEdition,
  ContentManagement,
  Notifications,
  PaymentManagement,
  Preferences,
  StaticContent,
  SubscriptionManagement,
  CategoryManagement,
  TopicManagement,
  MembershipRates,
  SubAdminManagement,
  EditorPickEmailer,
  ReferralPartnerManagement,
  CancellationAndRefund,
  ErrorLogs,
} from "src/views/pages";
import VerifyOTP from "src/views/pages/verify-otp";
import WeekDayContentBank from "src/views/pages/weekday-content-bank";
import ForgotPassword from "../views/pages/forgot-password";
import CouponManagement from "src/views/pages/coupon-management";
import BillingAndPayments from "src/views/pages/billing-and-payments";

const MyProfile = React.lazy(() => import("../views/pages/my-profile"));
const Page404 = React.lazy(() => import("../views/pages/page404"));

export default function Router() {
  return (
    <Routes>
      <Route exact path="/" name="Login Page" element={<Login />} />
      <Route
        exact
        path="/forgot-password"
        name="Forgot Password Page"
        element={<ForgotPassword />}
      />
      <Route
        exact
        path="/verify-otp"
        name="Verify Otp Page"
        element={<VerifyOTP />}
      />
      <Route
        exact
        path="/reset-password"
        name="Reset Password Page"
        element={<ResetPassword />}
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DefaultLayout component={<Dashboard />} />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/my-profile"
        element={
          <PrivateRoute>
            <DefaultLayout component={<MyProfile />} />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/sales-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<SalesManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<UserManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports-analytics"
        element={
          <PrivateRoute>
            <DefaultLayout component={<ReportAnalytics />} />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/sales-agent-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<SalesAgentManagement />} />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/complimentary-membership"
        element={
          <PrivateRoute>
            <DefaultLayout component={<ComplimentaryMembership />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/billing-payments"
        element={
          <PrivateRoute>
            <DefaultLayout component={<BillingAndPayments />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/content-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<ContentManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/weekday-content-bank"
        element={
          <PrivateRoute>
            <DefaultLayout component={<WeekDayContentBank />} />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <DefaultLayout component={<Notifications />} />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path="/payment-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<PaymentManagement />} />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/preferences"
        element={
          <PrivateRoute>
            <DefaultLayout component={<Preferences />} />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/static-content-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<StaticContent />} />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path="/subscription-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<SubscriptionManagement />} />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/category-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<CategoryManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/topic-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<TopicManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/membership-rates"
        element={
          <PrivateRoute>
            <DefaultLayout component={<MembershipRates />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/sub-admin-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<SubAdminManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/referral-partner-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<ReferralPartnerManagement />} />
          </PrivateRoute>
        }
      />

      <Route
        path="/cancellation-and-refund"
        element={
          <PrivateRoute>
            <DefaultLayout component={<CancellationAndRefund />} />
          </PrivateRoute>
        }
      />

      <Route
        path="/editor_pick_emailer"
        element={
          <PrivateRoute>
            <DefaultLayout component={<EditorPickEmailer />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/coupon-management"
        element={
          <PrivateRoute>
            <DefaultLayout component={<CouponManagement />} />
          </PrivateRoute>
        }
      />
      <Route
        path="/error-logs"
        element={
          <PrivateRoute>
            <DefaultLayout component={<ErrorLogs />} />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        name="Page 404"
        element={<DefaultLayout component={<Page404 />} />}
      />
    </Routes>
  );
}
