import { createSlice } from "@reduxjs/toolkit";
import {
  addContentCard,
  getContentCardList,
} from "src/redux/services/contentBank.service";

const initialState = {
  loading: false,
  contentList: [],
};

const contentBankSlice = createSlice({
  name: "contentBank",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    //add content card

    [addContentCard.pending]: (state) => {
      state.loading = true;
    },
    [addContentCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload.data.message;
    },
    [addContentCard.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },

    ///getContentCardList

    [getContentCardList.pending]: (state) => {
      state.loading = true;
    },
    [getContentCardList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.contentList = payload.data.data;
      state.message = payload.message;
    },
    [getContentCardList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
  },
});

export default contentBankSlice.reducer;
