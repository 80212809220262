import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import moment from "moment-timezone";
import Details from "./Details";
import Action from "src/components/common/Action";
import { errorAlert } from "src/utils/alerts";

const heading = [
  "Sr. No.",
  "Name",
  "Email",
  "Transaction ID",
  "Membership Plan",
  "Payment Plan",
  "Cancellation Date",
  "Refund Amount",
  "Refund Status",
  "Action",
];
const InReview = ({
  list,
  curPage,
  totalCount,
  setCurrPage,
  getPendingCancellationRequest,
  getInReviewList,
}) => {
  const [open, setOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(-1);
  return (
    <>
      <div className="table-sec full">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell key={`key_${index + 1}`} align="left">
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>{item?.userDetails?.firstName || "-"}</TableCell>
                    <TableCell>{item?.userDetails?.email || "-"}</TableCell>
                    <TableCell>
                      {item?.transactionDetails?.transactionId || "-"}
                    </TableCell>
                    <TableCell>
                      {item?.subscriptionDetails?.planName
                        ? firstLetterUpper(item?.subscriptionDetails?.planName)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.subscriptionDetails?.planType
                        ? firstLetterUpper(item?.subscriptionDetails?.planType)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.cancellationDate
                        ? moment(item?.cancellationDate).format("DD MMM, YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      <span className="text-green">
                        {item?.transactionDetails?.refundAmount
                          ? `$${item?.transactionDetails?.refundAmount}`
                          : "-"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div
                        className={`status-label  ${
                          item?.refundStatus === "PENDING" ? "red" : "blue"
                        }`}
                      >
                        {item?.refundStatus
                          ? firstLetterUpper(item?.refundStatus)
                          : "-"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Action
                        id={item._id}
                        isDelete={false}
                        isEdit={item.refundStatus === "PENDING"}
                        isView={item.refundStatus !== "PENDING"}
                        handleViewClick={(id) => {
                          setOpen(true);
                          setItemToEdit(index);
                        }}
                        setEditContentId={(id) => {
                          const isEdit = isEditAccess("CANCELLATION_REFUND");
                          if (isEdit) {
                            setItemToEdit(index);
                            setOpen(true);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>

      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {list && list.length === 0 && (
        <div className="no-content">
          <h6>No Data For Review</h6>
        </div>
      )}
      <Details
        open={open}
        setOpen={setOpen}
        getPendingCancellationRequest={getPendingCancellationRequest}
        getInReviewList={getInReviewList}
        details={list && list.length > 0 && list[itemToEdit]}
      />
    </>
  );
};

export default InReview;
