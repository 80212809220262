import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { firstLetterUpper } from "src/helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment-timezone";
const heading = [
  "Sr. No.",
  "Date",
  "Payment for",
  "Payment Plan",
  "Payment Type",
  "Transaction ID",
  "Amount",
  "Payment Valid Till",
];

const PaymentHistory = ({ paymentData }) => {
  const [data, setData] = useState([]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const newArr =
      paymentData &&
      paymentData.length > 0 &&
      paymentData.map((item, i) => {
        return {
          // ...item,
          id: i + 1,
          price: `$${item?.amount}`,
          payment_Date:
            item?.paymentDate &&
            format(new Date(item?.paymentDate), "MMM dd, yyyy"),
          payment_Valid_Till:
            item?.paymentValidTill &&
            moment.utc(item?.paymentValidTill).format("MMM DD, yyyy"),
          payment_For: firstLetterUpper(item?.paymentFor),
          payment_Plan: firstLetterUpper(item?.paymentPlan),
          payment_Type: firstLetterUpper(item?.transactionType),
          transaction_Id: capitalizeFirstLetter(item?.transactionId),
        };
      });
    setData(newArr);
  }, [paymentData]);

  return (
    <div>
      <h6>Payment History</h6>
      <div className="full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h) => {
                  return <TableCell align="left">{h}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.payment_Date}</TableCell>
                    <TableCell>{item.payment_For}</TableCell>
                    <TableCell>{item.payment_Plan}</TableCell>
                    <TableCell>{item.payment_Type}</TableCell>
                    <TableCell>{item.transaction_Id}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>{item.payment_Valid_Till}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PaymentHistory;
