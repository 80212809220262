import React from "react";


const TabButton = ({ handleTabSwitch, editionTab }) => {
  return (
    <div className="btn-grp bg-grey">
      <button
        className={editionTab === "Weekday" ? "btn" : "btn-blank"}
        onClick={() => handleTabSwitch("Weekday")}
      >
        Weekday Edition
      </button>
      <button
        className={editionTab === "Weekend" ? "btn" : "btn-blank"}
        onClick={() => handleTabSwitch("Weekend")}
      >
        Weekend Edition
      </button>
    </div>
  );
};

export default TabButton;
