import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ErrorGraph = ({ graphData }) => {
  return (
    <div className="main-div mt24 h-auto">
      <h6 className="m18">Error Graph</h6>
      <ResponsiveContainer width="95%" height={220}>
        <BarChart
          width={500}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="module" />
          <YAxis dataKey="count" />
          <Tooltip />
          <Bar dataKey="count" fill="#4d7ef9" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ErrorGraph;
