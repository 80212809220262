import React, { useEffect, useState } from "react";
import Select from "react-select";
import { StatusOptions } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";

const ReferralRevenueAndComission = ({
  setLoading,
  setDataForSendReport,
  dataForSendReport,
  activeSalesAgent,
}) => {
  const [transactionType, setTransactionType] = useState(
    StatusOptions[0].value
  );
  const [data, setData] = useState();
  const [salesAgentId, setSalesAgentId] = useState("");

  useEffect(() => {
    getReferralRevenueAndCommission();
  }, [transactionType, salesAgentId]);

  const getReferralRevenueAndCommission = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-revenue-and-commission?transactionType=${transactionType}&isSendEmailReport=${isSendEmailReport}`;
    if (salesAgentId !== "") {
      url = url + `&salesAgentId=${salesAgentId}`;
    }

    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setData(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getReferralRevenueAndCommission(true);
  };

  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Referral Revenue & Commission</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          options={activeSalesAgent}
          defaultValue={activeSalesAgent && activeSalesAgent[0]}
          getOptionLabel={(data) => {
            return data.email;
          }}
          getOptionValue={(data) => {
            return data._id;
          }}
          onChange={(data) => {
            setSalesAgentId(data._id);
            setDataForSendReport({
              ...dataForSendReport,
              salesAgentIdForReferralRevenueCommission: data._id,
            });
          }}
        />

        <Select
          options={StatusOptions}
          defaultValue={StatusOptions[0]}
          onChange={(data) => {
            setTransactionType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              transactionTypeForReferralRevenueCommission: data.value,
            });
          }}
        />
      </div>

      <div className="content-flex">
        <p>
          Total Revenue
          <b>{data && data?.netRevenue}</b>
        </p>
        <p>
          Total Commission
          <b>{data && data?.salesAgentCommission}</b>
        </p>
      </div>
    </div>
  );
};

export default ReferralRevenueAndComission;
