import React, { useState } from "react";

import Select from "react-select";

import {
  getUploadResponse,
  errorMessage,
  isValidImage,
  isValidUrl,
  isValidFileUploaded,
} from "src/helpers";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import CancelButton from "src/components/common/modal-cancel-btn";
import { ClosedCaptionRounded } from "@material-ui/icons";
import { errorAlert, successAlert } from "src/utils/alerts";

const AddCard = ({
  editionType,
  currentEditionId = "",
  cardCount,
  hideCard,
  categoriesData,
  groupData,
  hidePreview,
  enableAddCardButton,
  closeCard,
}) => {
  const [groupIds, setGroupIds] = useState([]);
  const [topicIds, setTopicIds] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [contentData, setContentData] = useState({ ctaText: "READ" });
  const [error, setError] = useState("");
  const [imgLoader, setImageLoader] = useState(false);
  const [isUrlValid, setValidUrl] = useState(false);
  const [isCardSaved, setIsCardSaved] = useState(false);
  const [mobileImage, setMobileImage] = useState(null);
  const [topicError, setTopicErrors] = useState("");

  const handleAddContentSubmit = () => {
    let data = {
      editionId: currentEditionId,
      mobileImage,
      ...contentData,
    };

    if (topicIds && topicIds.length > 0) {
      data = {
        ...data,
        topics: topicIds,
      };
    }
    if (groupIds && groupIds.length > 0) {
      data = {
        ...data,
        groups: groupIds,
      };
    }

    if (!isWeekday) {
      delete data["topics"];
      delete data["groups"];
      delete data["categoryId"];
    }

    axios
      .post(`admin/edition/add-card`, data)
      .then((res) => {
        if (res?.data?.statusCode) {
          successAlert(res?.data?.message || "");
          setIsCardSaved(true);
          enableAddCardButton();
          hideCard();
          hidePreview();
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }

        errorAlert(message || API_MESSAGES.getError);
      });
  };

  const isWeekday = editionType === "WEEKDAY";

  const getDisabledStatus = () => {
    if (isWeekday) {
      return (
        !contentData.categoryId ||
        !contentData.attribute ||
        !contentData.ctaText ||
        !contentData.redirectUrl ||
        !contentData.imageSource ||
        !contentData.title ||
        !currentEditionId ||
        !mobileImage ||
        isUrlValid
      );
    } else {
      return (
        !contentData.attribute ||
        !contentData.ctaText ||
        !contentData.redirectUrl ||
        !contentData.imageSource ||
        !contentData.title ||
        !currentEditionId ||
        !mobileImage ||
        isUrlValid
      );
    }
  };

  return (
    <div key={cardCount}>
      <section className="main-div mt24">
        <div className="heading bb">
          <div>
            <h6 className="title">Content Piece #{cardCount}</h6>
          </div>
          {/* <div className="ml-div">
            <span className="delete-btn">
              <CancelButton
                onClose={() => {
                  closeCard()
                  
                }}
              />
            </span>
          </div> */}
        </div>

        <div className="content-piece">
          <div className="pr30">
            <div className="content-img">
              <div className={`img-upload ${mobileImage ? "uploaded" : ""}`}>
                {imgLoader && (
                  <div className="spinner">
                    <span></span>
                  </div>
                )}

                {mobileImage && (
                  <span className="delete-img">
                    <CancelButton
                      onClose={() => {
                        if (!isCardSaved) {
                          setMobileImage(null);
                        }
                      }}
                    />
                  </span>
                )}
                {mobileImage ? (
                  <img src={mobileImage} />
                ) : (
                  <input
                    disabled={!currentEditionId || isCardSaved}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={async (e) => {
                      if (e.target.files && e.target.files[0]) {
                        let img = e.target.files[0];
                        const height = 625;
                        const width = 500;
                        setImageLoader(true);

                        const isValidExtension = isValidFileUploaded(img);

                        if (isValidExtension) {
                          const isValid = await isValidImage({
                            file: img,
                            height,
                            width,
                          });
                          if (isValid) {
                            const res = await getUploadResponse(img);
                            setMobileImage(res);
                            setError("");
                          } else {
                            const message = `Image Height And Width Must Be ${width}px * ${height}px And Size Should Be Less Than 3 MB.`;
                            setError(message);
                          }
                        } else {
                          setError("File Format Not Supported");
                        }

                        setImageLoader(false);
                      }
                    }}
                  />
                )}
                <div className="text">
                  <span className="icon icon-upload">
                    <i className="upload-icon"></i>
                  </span>
                  <p>Upload from gallery</p>
                  <p>(500x625)</p>
                  <p className="accept-img">
                    (accepts .png .jpeg .jpg images only)
                  </p>
                </div>
              </div>
              <div name="mobileImage" className="input-feedback error-msg">
                {error}
              </div>
            </div>
          </div>

          <div className="fb-1">
            <div className="row">
              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Content Category </label>
                    <div className="f-in">
                      <Select
                        isDisabled={!currentEditionId || isCardSaved}
                        onChange={(val) => {
                          const newData = {
                            ...contentData,
                            categoryId: val?._id,
                          };
                          setContentData(newData);
                        }}
                        options={
                          categoriesData && categoriesData.length > 0
                            ? categoriesData
                            : []
                        }
                        getOptionLabel={(val) => {
                          return val?.name;
                        }}
                        getOptionValue={(val) => {
                          return val?._id;
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="form-in">
                  <label>Attribution Text (20 Char) </label>
                  <div className="f-in">
                    <input
                      disabled={!currentEditionId || isCardSaved}
                      maxLength={20}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          attribute: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Attribution text here"
                    />
                  </div>
                </div>
              </div>

              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>CTA for Mobile </label>
                    <div className="f-in">
                      <select
                        disabled={!currentEditionId || isCardSaved}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            ctaText: e.target.value,
                          });
                        }}
                      >
                        <option value="READ">READ</option>
                        <option value="VIEW">VIEW</option>
                        <option value="LISTEN">LISTEN</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Redirection URL ( Max 1000 Char) </label>
                    <div className="f-in">
                      <input
                        disabled={!currentEditionId || isCardSaved}
                        maxLength={1000}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            redirectUrl: e.target.value,
                          });
                          setValidUrl(false);
                        }}
                        type="text"
                        value={contentData?.redirectUrl}
                      />
                      <a
                        className="icon-right"
                        onClick={() => {
                          if (contentData?.redirectUrl) {
                            window.open(contentData?.redirectUrl, "_blank");
                            const checkValidUrl = !isValidUrl(
                              contentData.redirectUrl
                            );
                            if (checkValidUrl) {
                              setValidUrl(true);
                            }
                          }
                        }}
                      >
                        <p className="text-blue">TEST</p>
                      </a>
                    </div>
                    {isUrlValid && (
                      <div
                        name="mobileImage"
                        className="input-feedback error-msg"
                      >
                        Please Enter Valid URL
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="form-in">
                  <label>Image Source (15 Char)</label>
                  <div className="f-in">
                    <input
                      disabled={!currentEditionId || isCardSaved}
                      maxLength={15}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          imageSource: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Image Source"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-in">
                  <label>Title Text (250 Char)</label>
                  <div className="f-in">
                    <textarea
                      rows="3"
                      maxLength={250}
                      disabled={!currentEditionId || isCardSaved}
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          title: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>

              {isWeekday && (
                <div className="col-md-12">
                  <div className="form-in multi-select">
                    <label>Group (optional)</label>
                    <div className="f-in">
                      {/* <Select
                    isMulti
                    isDisabled={!currentEditionId}
                      onChange={(val) => {
                      const arr = val?.map((item) => ({groupId: item._id}))
                      let topList = [] 
                       val?.forEach((item) => {
                        topList.push(...item.topics)
                       })

                      setGroupIds(arr);
                      setTopicsList(topList);
                      }}
                      options={
                        groupData && groupData.length > 0 ? groupData : []
                      }
                      getOptionLabel={(val) => {
                        return val?.name;
                      }}
                      getOptionValue={(val) => {
                        return val?._id;
                      }}
                    /> */}

                      <Select
                        isMulti
                        isClearable
                        onChange={(data) => {
                          let topicData = [];
                          const groupData = data.map((el) => {
                            return {
                              groupId: el._id,
                            };
                          });

                          data.forEach((el) => {
                            topicData.push(
                              ...el.topics.map((data) => {
                                return {
                                  ...data,
                                  groupName: el.name,
                                };
                              })
                            );
                          });

                          setTopicsList(topicData);

                          setGroupIds(groupData);
                          const updatedTopicIds = topicIds.filter((el) => {
                            const filteredTopicIds = topicData.find((data) => {
                              return data._id === el.topicId;
                            });
                            return filteredTopicIds ? true : false;
                          });

                          setTopicIds(updatedTopicIds);

                          if (updatedTopicIds && updatedTopicIds.length > 0) {
                            const errorForTopic =
                              updatedTopicIds.length !== groupData.length;
                            setTopicErrors(
                              errorForTopic
                                ? "Please Select Topics From All Groups"
                                : ""
                            );
                          } else {
                            setTopicErrors("");
                          }
                        }}
                        value={
                          groupIds &&
                          groupIds.length > 0 &&
                          groupIds.map((option) => {
                            const data = groupData.find((el) => {
                              return el._id === option.groupId;
                            });
                            return data;
                          })
                        }
                        options={groupData}
                        getOptionLabel={(value) => {
                          return value.name;
                        }}
                        getOptionValue={(value) => {
                          return value._id;
                        }}
                        isDisabled={!currentEditionId || isCardSaved}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isWeekday && (
                <div className="col-md-12">
                  <div className="form-in multi-select">
                    <label>Topics (optional)</label>
                    {/* <Select
                  isDisabled={!currentEditionId}
                    isMulti
                      onChange={(val) => {
                      const arr = val?.map((item) => ({topicId: item._id}))
                      setTopicIds(arr);
                      }}
                      options={
                        topicsList && topicsList.length > 0 ? topicsList : []
                      }
                      getOptionLabel={(val) => {
                        return val?.name;
                      }}
                      getOptionValue={(val) => {
                        return val?._id;
                      }}
                    /> */}
                    <div className="f-in">
                      <Select
                        isMulti
                        isClearable
                        isDisabled={!currentEditionId || isCardSaved}
                        // isDisabled={topicList.length === 0 || !isEdit}
                        onChange={(data) => {
                          const topicData = data.map((el) => {
                            return {
                              topicId: el._id,
                            };
                          });
                          setTopicIds(topicData);
                          if (data.length > 0) {
                            const groups = data
                              .map((el) => el.groupId)
                              .filter((item, i, ar) => ar.indexOf(item) === i);
                            const errorTopic =
                              groups.length !== groupIds.length;

                            setTopicErrors(
                              errorTopic
                                ? "Please Select Topics From All Groups"
                                : ""
                            );
                          } else {
                            setTopicErrors("");
                          }
                        }}
                        options={topicsList}
                        getOptionLabel={(value) => {
                          return `${value.name} (${value.groupName})`;
                        }}
                        getOptionValue={(value) => {
                          return value._id;
                        }}
                        value={
                          topicIds &&
                          topicIds.length > 0 &&
                          topicIds.map((option) => {
                            const data = topicsList.find((el) => {
                              return el._id === option.topicId;
                            });
                            return data;
                          })
                        }
                      />
                      {topicError && (
                        <div
                          name="mobileImage"
                          className="input-feedback error-msg"
                        >
                          {topicError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {!isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>CTA for Mobile </label>
                    <div className="f-in">
                      <select
                        disabled={!currentEditionId || isCardSaved}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            ctaText: e.target.value,
                          });
                        }}
                      >
                        <option value="READ">Read</option>
                        <option value="VIEW">View</option>
                        <option value="LISTEN">Listen</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {!isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Redirection URL ( Max 1000 Char) </label>
                    <div className="f-in">
                      <input
                        disabled={!currentEditionId || isCardSaved}
                        maxLength={1000}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            redirectUrl: e.target.value,
                          });
                          setValidUrl(false);
                        }}
                        type="text"
                        value={contentData?.redirectUrl}
                      />
                      <a
                        className="icon-right"
                        onClick={() => {
                          if (contentData?.redirectUrl) {
                            window.open(contentData?.redirectUrl, "_blank");
                            const checkValidUrl = !isValidUrl(
                              contentData.redirectUrl
                            );
                            if (checkValidUrl) {
                              setValidUrl(true);
                            }
                          }
                        }}
                      >
                        <p className="text-blue">TEST</p>
                      </a>
                    </div>
                    {isUrlValid && (
                      <div
                        name="mobileImage"
                        className="input-feedback error-msg"
                      >
                        Please Enter Valid URL
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="btns-lower">
          <div>
            <button
              className="btn long"
              disabled={getDisabledStatus() || isCardSaved || topicError}
              onClick={() => {
                handleAddContentSubmit();
              }}
            >
              Save Content Piece
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddCard;
