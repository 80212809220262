import React from "react";
import ModalComp from "src/components/common/modal";
import { DELETE_CONTENT_TEXT } from "src/constant/modals";

const DeleteContentCard = ({ isOpen, setOpen, onDelete }) => {
  return (
    <ModalComp
      open={isOpen}
      title="Confirmation"
      setOpen={() => setOpen(false)}
      content={DELETE_CONTENT_TEXT}
      onClickYes={onDelete}
    />
  );
};

export default DeleteContentCard;
