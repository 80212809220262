import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//getMembership Data
export const getComplimentaryMemberListFilter = createAsyncThunk(
  "admin/getComplimentaryMembershipListFilter",
  // Callback Function
  async ({ pageNo = 1, limit = 10, ...rest }, thunkAPI) => {
    try {
      const data = { ...rest, limit, page: pageNo };
      Object.keys(data).forEach((key) => {
        if (data[key] === "") {
          delete data[key];
        }
      });
      const response = await axios.post(`admin/complimentary-membership/list`, {
        ...data,
      });
      return await response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getComplimentaryMemberList = createAsyncThunk(
  "admin/getComplimentaryMembershipList",
  // Callback Function
  async ({ url, data, callback }, thunkAPI) => {
    try {
      const response = await axios.post(url, data);
      callback(await response.data);
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong";
      errorAlert(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
