import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";
import { PieChart, Pie, Cell } from "recharts";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";

const EffectiveConversions = () => {
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    getEffectiveConvsersionsData();
  }, [rangeStart, rangeEnd]);

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  const getEffectiveConvsersionsData = () => {
    setLoading(true);
    let url = "admin/dashboard/graph-data-with-filter";
    if (rangeStart !== null && rangeEnd !== null) {
      url = url + `?startDate=${rangeStart}&endDate=${rangeEnd}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          setData(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const graphData1 = [
    {
      name: "freeTrailExpiresInPeriod",
      value:
        data &&
        data?.effectiveConversion &&
        data.effectiveConversion?.freeTrailExpiresInPeriod,
      color: "#0a6afa",
    },
  ];

  const graphData2 = [
    {
      name: "freeTrailExpiresInPeriod",
      value:
        data &&
        data?.effectiveConversion &&
        data.effectiveConversion?.freeTrailExpiresInPeriod,
      color: "#eeeeee",
    },
    {
      name: "freeTrailConversionsOfExpires",
      value:
        data &&
        data.effectiveConversion &&
        data.effectiveConversion?.freeTrailConversionsOfExpires,
      color: "#46a8b3",
    },
  ];

  const graphData3 = [
    {
      name: "membershipExpiresInPeriod",
      value:
        data &&
        data?.effectiveMembershipRenewal &&
        data.effectiveMembershipRenewal?.membershipExpiresInPeriod,
      color: "#0a6afa",
    },
  ];

  const graphData4 = [
    {
      name: "membershipExpiresInPeriod",
      value:
        data &&
        data?.effectiveMembershipRenewal &&
        data.effectiveMembershipRenewal?.membershipExpiresInPeriod,
      color: "#eeeeee",
    },
    {
      name: "renewalOfExpires",
      value:
        data &&
        data.effectiveMembershipRenewal &&
        data.effectiveMembershipRenewal?.renewalOfExpires,
      color: "#46a8b3",
    },
  ];

  return (
    <>
      {loading && <Loader />}
      <div className="main-div h-auto" style={{ marginTop: "10px" }}>
        <div className="heading">
          <div className="pr12 filter-inputs ml-auto">
            <div className="form-in date-pick d-flex">
              <DateRangePicker
                // setCurrPage={setCurrPage}
                selectStartDate={selectStartDate}
                selectEndDate={selectEndDate}
                startDate={rangeStart}
                endDate={rangeEnd}
                minDate={rangeStart}
                disableEndDate={!rangeStart}
              />
              <button
                className="btn-white ml12"
                onClick={() => {
                  clearDates();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div className="effective-div">
          <div>
            <h5 className="mb0">Effective Conversion</h5>

            <div className="eff-inner">
              <div>
                <Chart graphData={graphData1} />

                <p>
                  Total Free Trial <br />
                  Expires in Period
                </p>
              </div>

              <p className="num">
                {data &&
                  data.effectiveConversion &&
                  data.effectiveConversion?.freeTrailExpiresInPeriod}
              </p>
            </div>

            <div className="eff-inner">
              <div>
                <Chart graphData={graphData2} />
                <p>
                  Total Conversions
                  <br /> of these Expires
                </p>
              </div>
              <p className="num">
                {data &&
                  data.effectiveConversion &&
                  data.effectiveConversion.freeTrailConversionsOfExpires}
              </p>
            </div>
          </div>

          <div>
            <h5 className="mb0">Effective Membership Renewal</h5>

            <div className="eff-inner">
              <div>
                <Chart graphData={graphData3} />
                <p>
                  Total Memberships
                  <br /> Expired in Period
                </p>
              </div>
              <p className="num">
                {data &&
                  data.effectiveMembershipRenewal &&
                  data.effectiveMembershipRenewal.membershipExpiresInPeriod}
              </p>
            </div>

            <div className="eff-inner">
              <div>
                <Chart graphData={graphData4} />
                <p>
                  Total Renewals
                  <br />
                  of these Expires
                </p>
              </div>
              <p className="num">
                {data &&
                  data.effectiveMembershipRenewal &&
                  data.effectiveMembershipRenewal.renewalOfExpires}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EffectiveConversions;

function Chart({ graphData }) {
  return (
    <PieChart width={40} height={40}>
      <Pie
        data={graphData}
        cx={15}
        cy={15}
        innerRadius={15}
        outerRadius={20}
        fill={graphData?.color}
        paddingAngle={5}
        dataKey="value"
      >
        {graphData &&
          graphData.length > 0 &&
          graphData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
      </Pie>
    </PieChart>
  );
}
