const _nav = [
  {
    name: "Dashboard",
    to: "/dashboard",
  },
  {
    name: "User Management",
    to: "/user-management",
    id: "USER_MANAGEMENT",
  },
  {
    name: "Weekday Content Bank",
    to: "/weekday-content-bank",
    id: "CONTENT_BANK_MANAGEMENT",
  },
  {
    name: "Content & Edition Management",
    to: "/content-management",
    id: "EDITION_MANAGEMENT",
  },

  {
    name: "Category Management",
    to: "/category-management",
    id: "CATEGORY_MANAGEMENT",
  },
  {
    name: "Topic Management",
    to: "/topic-management",
  },

  {
    name: "Membership Rates",
    to: "/membership-rates",
    id: "TOPICS_MANAGEMENT",
  },
  {
    name: "Editor’s Pick Emailer",
    to: "/editor_pick_emailer",
    id: "EDITOR_PICK_EMAILER",
  },
  {
    name: "Referral Partner Management",
    to: "/referral-partner-management",
    id: "REFERRAL_PARTNER_MANAGEMENT",
  },
  {
    name: "Cancellation & Refund",
    to: "/cancellation-and-refund",
    id: "CANCELLATION_REFUND",
  },
  {
    name: "Complimentary Membership",
    to: "/complimentary-membership",
    id: "COMPLIMENTARY_MEMBERSHIPS",
  },
  {
    name: "Billing & Payments",
    to: "/billing-payments",
    id: "BILLING_PAYMENTS",
  },
  {
    name: "Coupon Management",
    to: "/coupon-management",
    id: "COUPON_MANAGEMENT",
  },
  {
    name: "Sub Admin Management",
    to: "/sub-admin-management",
    id: "SUB_ADMIN_MANAGEMENT",
  },
  {
    name: "Reports & Analytics",
    to: "/reports-analytics",
  },
  {
    name: "Error Logs",
    to: "/error-logs",
  },

  // {
  //   name: "Membership Rates",
  //   to: "/membership-rates",
  //   id: "TOPICS_MANAGEMENT",
  //   // icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   name: "Referral Partner Management",
  //   to: "/referral-partner-management",
  //   id: "REFERRAL_PARTNER_MANAGEMENT",
  //   // icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   name: "Sub Admin Management",
  //   to: "/sub-admin-management",
  //   id: "SUB_ADMIN_MANAGEMENT",
  //   // icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   name: "Preferences",
  //   to: "/preferences",
  // },
  {
    name: "Sales Management",
    to: "/sales-management",
  },
  // {
  //   name: "Subscription Management",
  //   to: "/subscription-management",
  // },
  // {
  //   name: "Notifications",
  //   to: "/notifications",
  // },
  // {
  //   name: "Static Content Management",
  //   to: "/static-content-management",
  // },

  // {
  //   name: "Reports & Analytics",
  //   to: "/reports-analytics",
  // },
];

export default _nav;
