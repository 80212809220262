import React, { useState } from "react";
import DateRangePicker from "src/components/DateRangePicker";

import FreeTrialTab from "./components/FreeTrialTab";
import PaidMembersTab from "./components/PaidMembersTab";
import Select from "react-select";

const signUpsMembershipStatusOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "RENEWAL",
    value: "RENEWAL",
  },
];

const Sales = ({
  salesData,
  isFree,
  setFree,
  getSalesAgentDetail,
  signupsStartDate,
  signupsEndDate,
  setSignupsEndDate,
  setSignupsStartDate,
  listStartDate,
  listEndDate,
}) => {
  const [curPage, setCurrPage] = useState(1);

  const selectStartDate = (d) => {
    let check = d !== null && signupsEndDate !== null;

    if (d > signupsEndDate) {
      setSignupsEndDate(null);
      check = false;
    }
    setSignupsStartDate(d);
    if (check) {
      const type = isFree ? "FREE_TRAIL" : "PAID";
      getSalesAgentDetail({
        type,
        signupsStartDate: d,
        signupsEndDate: signupsEndDate,
        listStartDate,
        listEndDate,
      });
    }
  };

  const selectEndDate = (d) => {
    setSignupsEndDate(d);
    if (signupsStartDate !== null && d !== null) {
      const type = isFree ? "FREE_TRAIL" : "PAID";

      getSalesAgentDetail({
        type,
        signupsStartDate: signupsStartDate,
        signupsEndDate: d,
        listStartDate,
        listEndDate,
      });
    }
  };

  const clearDates = () => {
    setSignupsEndDate(null);
    setSignupsStartDate(null);
    if (signupsStartDate !== null && signupsEndDate !== null) {
      const type = isFree ? "FREE_TRAIL" : "PAID";
      getSalesAgentDetail({ type, listStartDate, listEndDate });
    }
  };

  const handleStatusChange = (data) => {
    const type = isFree ? "FREE_TRAIL" : "PAID";
    getSalesAgentDetail({
      type,
      listStartDate,
      listEndDate,
      signUpsMembershipStatus: data.value,
      signupsStartDate,
      signupsEndDate,
    });
  };

  return (
    <>
      <div className="status-refferal">
        <div className="heading">
          <div className="btn-grp bg-grey">
            <button
              className={`${!isFree ? "btn-blank" : "btn"}`}
              onClick={() => {
                setFree(true);
                setSignupsEndDate(null);
                setSignupsStartDate(null);
              }}
            >
              Free Trial Sign-Ups
            </button>
            <button
              className={`${isFree ? "btn-blank" : "btn"}`}
              onClick={() => {
                setFree(false);
                setSignupsEndDate(null);
                setSignupsStartDate(null);
              }}
            >
              Paid Memberships
            </button>
          </div>

          {!isFree && (
            <div className="select-option">
              <Select
                options={signUpsMembershipStatusOptions}
                defaultValue={signUpsMembershipStatusOptions[0]}
                onChange={handleStatusChange}
              />
            </div>
          )}

          <div className="form-in ml-auto">
            <div className="f-in date-filter">
              <DateRangePicker
                setCurrPage={setCurrPage}
                selectStartDate={selectStartDate}
                selectEndDate={selectEndDate}
                startDate={signupsStartDate}
                endDate={signupsEndDate}
                // minDate={signupsStartDate}
                disableEndDate={!signupsStartDate}
              />
              <button
                className="btn-white ml12"
                onClick={() => {
                  clearDates();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      {isFree && <FreeTrialTab salesData={salesData} />}
      {!isFree && <PaidMembersTab salesData={salesData} />}
    </>
  );
};

export default Sales;
