import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { SourceOptions } from "src/constant/global";
import Select from "react-select";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { firstLetterUpper } from "src/helpers";

const Revenue_By_Month = ({
  setLoading,
  startDate,
  endDate,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [revenue, setRevenue] = useState();
  const [selectedSource, setSource] = useState(SourceOptions[0].value);
  const [monthYear, setMonthYear] = useState(new Date());

  useEffect(() => {
    getRevenueData();
  }, [monthYear, selectedSource]);

  const getRevenueData = (isSendEmailReport = false) => {
    setLoading(true);
    const month = moment(monthYear).month();
    const year = moment(monthYear).year();

    let url = `admin/reports-analytics/revenue-by-month?bySource=${selectedSource}&month=${month}&year=${year}&isSendEmailReport=${isSendEmailReport}`;
    // if (startDate !== null && endDate !== null) {
    //   url =
    //     url +
    //     `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    // }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setRevenue(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getRevenueData(true);
  };

  const tableData =
    revenue &&
    Object.keys(revenue).length > 0 &&
    Object.keys(revenue).map((el) => {
      const field = revenue[el];
      const key = el
        .replace("grossAmount", "gross_Amount")
        .replace("netRevenue", "net_Revenue")
        .replace("partnerCommission", "partner_Commission")
        .replace("stripeCharges", "stripe_Charges");
      return {
        title: firstLetterUpper(key),
        count: field,
      };
    });

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs18 mb0">Revenue By Month</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option">
        <DatePicker
          selected={monthYear}
          onChange={(date) => {
            setMonthYear(date);
            const month = moment(date).month();
            const year = moment(date).year();
            setDataForSendReport({
              ...dataForSendReport,
              revenueByMonth: month,
              revenueByYear: year,
            });
          }}
          dateFormat="MMM yyyy"
          showMonthYearPicker
        />
        <Select
          options={SourceOptions}
          defaultValue={SourceOptions[0]}
          onChange={(data) => {
            setSource(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              revenuebySource: data.value,
            });
          }}
        />
      </div>
      <div className="light-table full table-sec">
        <Table>
          <TableRow>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">
              {monthYear && moment(monthYear).format("MMM YYYY")}
            </TableCell>
          </TableRow>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.title}</TableCell>
                    <TableCell>{item?.count}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Revenue_By_Month;
