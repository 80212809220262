import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import customAxios from "src/utils/axios";
import moment from "moment-timezone";
import { errorAlert, successAlert } from "src/utils/alerts";
import { isEditAccess } from "src/helpers";
const heading = ["Title", "Current Month", "Previous Month", "% Change"];
const DataTable = ({ tableData, getFreeData }) => {
  const [currentMarketingSpend, setCurrentMarketingSpend] = useState("");
  const [preMonthMerketingSpend, setPrevMonthMarketingSpend] = useState("");

  useEffect(() => {
    if (tableData) {
      const val = tableData.find((el) => {
        return el.title === "Marketing Spend";
      });
      if (val) {
        setCurrentMarketingSpend(val.currentMonth);
        setPrevMonthMarketingSpend(val.prevMonth);
      }
    }
  }, [tableData]);

  const handleAddMarketingSpends = ({ price, month, year }) => {
    customAxios
      .post(`admin/dashboard/add-marketing-spends`, {
        month,
        year,
        price,
      })
      .then((res) => {
        if (res?.data?.statusCode) {
          successAlert(res?.data?.message);
          if (getFreeData) {
            getFreeData();
          }
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const isEdit = isEditAccess("DASHBOARD");

  return (
    <>
      <div className="light-table full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>
                      {item?.title === "Overall Total" ? (
                        <b>{item?.title.toUpperCase()}</b>
                      ) : (
                        item?.title
                      )}
                    </TableCell>
                    {item.title === "Marketing Spend" ? (
                      <>
                        <TableCell>
                          <input
                            disabled={!isEdit}
                            value={currentMarketingSpend}
                            type="number"
                            className="sm-input"
                            onChange={(e) => {
                              setCurrentMarketingSpend(e.target.value);
                            }}
                            onBlur={() => {
                              const check = moment(new Date(), "YYYY/MM/DD");
                              const month = check.format("M");
                              const year = check.format("YYYY");
                              handleAddMarketingSpends({
                                month: month - 1,
                                year,
                                price: currentMarketingSpend,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            value={preMonthMerketingSpend}
                            type="number"
                            className="sm-input"
                            onChange={(e) => {
                              setPrevMonthMarketingSpend(e.target.value);
                            }}
                            onBlur={() => {
                              const check = moment(new Date(), "YYYY/MM/DD");
                              const month = check.format("M");
                              const year = check.format("YYYY");
                              handleAddMarketingSpends({
                                month: month == 1 ? 11 : month - 2,
                                year: month == 1 ? year - 1 : year,
                                price: preMonthMerketingSpend,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TableCell>{item?.changePercentage || "-"}</TableCell>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{item?.currentMonth || "-"}</TableCell>
                        <TableCell>{item?.prevMonth || "-"}</TableCell>
                        <TableCell>
                          <span
                            className={
                              item?.changePercentage > 0
                                ? "text-green"
                                : "text-red"
                            }
                          >
                            {item?.changePercentage || "-"}
                          </span>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}

            {tableData && tableData.length === 0 && (
              <div className="no-content">
                <p>Data Not Found</p>
              </div>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default DataTable;
