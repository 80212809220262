import React from "react";
import { Dialog, DialogContentText } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DraggbleComp from "../../../components/common/draggble";
import CancelButton from "../../../components/common/modal-cancel-btn";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please Enter Valid Email.")
    .required("Please Enter Email."),
});

const AddUser = ({ open, setOpen, handleAddUser }) => {
  return (
    <>
      <Dialog
        open={open}
        // className={classFilter}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={DraggbleComp}
        aria-labelledby="draggable-dialog-title"
      >
        <div className="modal-text basic-modal">
          <div className="close-btn">
            <CancelButton
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className="head">
            <h5 className="fw700 fs20 mb24 text-center">ADD USER</h5>
          </div>

          <div className="body">
            <DialogContentText className="text-center fs18 fw400 text-black">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                validateOnChange
                validateOnBlur
                onSubmit={handleAddUser}
              >
                {() => {
                  return (
                    <Form>
                      <div className="form-in">
                        <label>Email</label>
                        <div className="f-in">
                          <Field
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                      <div className="btn-grp center">
                        <button className="btn" type="submit">
                          REGISTER
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </DialogContentText>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddUser;
