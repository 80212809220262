import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validNameRegex, ValidPhoneNumber } from "src/helpers";

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("Please Enter First Name.")
    .matches(
      validNameRegex,
      "Please Enter a Valid First Name. A First Name Should Not Contain Special Characters Or Alphanumeric Characters."
    ),
  lastName: Yup.string()
    .trim()
    .required("Please Enter Last Name.")
    .matches(
      validNameRegex,
      "Please Enter a Valid Last Name. A Last Name Should Not Contain Special Characters Or Alphanumeric Characters."
    ),
  email: Yup.string()
    .trim()
    .email("Please Enter Valid Email.")
    .required("Please Enter Email."),
  contactNo: Yup.string()
    .trim()
    .required("Please Enter Mobile No.")
    .matches(
      ValidPhoneNumber,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    )
    .min(
      5,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    )
    .max(
      15,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    ),
  startDate: Yup.string().trim().required("Please Select Start Date."),
  endDate: Yup.string().trim().required("Please Select End Date."),
  countryCode: Yup.string().trim().required("Please Select Country Code."),
});

const AddNewMembership = ({
  //   setIsEdit,
  //   editMembershipValues,
  handleMembershipSubmit,
}) => {
  return (
    <>
      <div className="blue-heading">
        <div className="title">
          <h6 className="fs16">Add New Complimentary Member</h6>
        </div>
      </div>
      <div className="heading ">
        <div className="title">
          <h6 className="fs16 fw600">Add Complimentary Member Details below</h6>
        </div>
      </div>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          startDate: "",
          endDate: "",
          contactNo: "",
          countryCode: "91",
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleMembershipSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="row col-form">
                <div className="col-md-4">
                  <div className="form-in">
                    <label>First Name</label>
                    <div className="f-in">
                      <Field
                        maxLength={12}
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                      />
                    </div>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-in">
                    <label>Last Name</label>
                    <div className="f-in">
                      <Field
                        maxLength={12}
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                      />
                    </div>
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-in">
                    <label>Email</label>
                    <div className="f-in">
                      <Field
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-in phone">
                    <label>Mobile no.</label>
                    <div className="f-in">
                      <div>
                        <PhoneInput
                          inputStyle={{ width: "100px" }}
                          country={"in"}
                          inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          value={values.countryCode}
                          onChange={(code) => {
                            if (code) {
                              setFieldValue("countryCode", code);
                            }
                          }}
                          // onBlur={() => handleBlurInput(member._id, name)}
                          // onFocus={() => handleFocusInput(member._id, name)}
                        />
                      </div>
                      <Field
                        type="text"
                        placeholder="Enter Mobile No."
                        name="contactNo"
                        maxLength={15}
                      />{" "}
                    </div>
                    <div>
                      <ErrorMessage
                        name="countryCode"
                        component="div"
                        className="input-feedback error-msg"
                      />
                      <ErrorMessage
                        name="contactNo"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-in date-input">
                    <label>Start Date</label>
                    <div className="f-in">
                      <DatePicker
                        selectsStart
                        placeholderText="Start-Date"
                        selected={values.startDate}
                        minDate={new Date()}
                        onChange={(date) => {
                          setFieldValue("startDate", date);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-in date-input">
                    <label>End Date</label>
                    <div className="f-in">
                      <DatePicker
                        selectsEnd
                        placeholderText="End-Date"
                        selected={values.endDate}
                        minDate={values.startDate}
                        onChange={(date) => {
                          setFieldValue("endDate", date);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>
              </div>
              <div className="btns-lower">
                <button className="btn" type="submit">
                  SAVE & SEND EMAIL
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNewMembership;
