import API from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postStaticContent = createAsyncThunk(
  "/save-file/upload",
  async ({ formData, isVideoType }, { rejectWithValue }) => {
    try {
      const res = await API.post(`/static-content/upload`, formData);
      return { res, videoType };
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message;
      if (message) {
        return rejectWithValue(message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateStaticContent = createAsyncThunk(
  "/static-content/update",
  async (payload, { rejectWithValue }) => {
    const { handleApiSucessCallback, ...rest } = payload;
    try {
      const response = await API.put(`/static-content/update`, ...rest);
      if (response && response?.data?.data.status === 200) {
        handleApiSucessCallback();
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message;
      if (message) {
        return rejectWithValue(message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStaticContent = createAsyncThunk(
  "/static-content/details",
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/static-content/details/${type}`);
      if (response?.status === 200) {
        return { data: response?.data?.data };
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message;
      if (message) {
        return rejectWithValue(message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
