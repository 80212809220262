import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//getCategory Data
export const getGroupsList = createAsyncThunk(
  "admin/groupList",
  async ({}, thunkAPI) => {
    try {
      const response = await axios.get(`admin/groupList`);
      return await response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      return thunkAPI.rejectWithValue(message);
    }
  }
);
