import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from "@mui/material";

import { getUploadResponse, errorMessage, isValidImage, isValidFileUploaded } from "src/helpers";
import axios from "src/utils/axios";
import AddCard from "./AddCard";
import PreviewDesktopMobile from "../view-content-edition/PreviewDesktopMobile";
import DatePicker from "react-datepicker";
import moment from "moment";
import CancelButton from "../../../../components/common/modal-cancel-btn";
import "react-datepicker/dist/react-datepicker.css";
import { errorAlert, successAlert } from "src/utils/alerts";
const weekday = "WEEKDAY";
const weekend = "WEEKEND";

const AddContent = ({
  setEditContentId,
  isEdit,
  setAddNew,
  categoriesData,
  groupData,
}) => {
  const [editionData, setEditionData] = useState({ editionType: weekday, time: "04:00" });
  const [currentEditionId, setCurrentEditionId] = useState(null);

  const [previewMobile, setPreviewMobile] = useState(false);
  const [previewDesktop, setPreviewDesktop] = useState(false);
  const [isContentSaved, setIsContentSaved] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [errors, setErrors] = useState({});
  const [imgLoader, setImageLoader] = useState({});
  const [allCardsArr, setAllCardsArr] = useState([{}]);
  const [isPreviewDisplay, setIsPreviewDisplay] = useState(false);
  const [enableAddCard, setEnableAddCard] = useState(false);

  useEffect(() => {
    if (isEdit) {
      axios
        .get(`admin/editionDetails?editionId=${isEdit}`)
        .then((res) => {
          if (res?.data?.data) {
            // set data in state
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }

          errorAlert(message || API_MESSAGES.getError);
        });
    }
  }, [isEdit]);
 
  const handleUploadImage = async (e, type) => {
    if (e.target.files && e.target.files[0]) {
      const isValidExtension = isValidFileUploaded(e.target.files[0]);

      if (isValidExtension) {
        let img = e.target.files[0];
        const height = type === "mobile" ? 1920 : 430;
        const width = type === "mobile" ? 1080 : 1440;
        setImageLoader({
          [type]: true,
        });
        const isValid = await isValidImage({ file: img, height, width });
        if (isValid) {
          const data = await getUploadResponse(img);
          if (data) {
            if (type === "mobile") {
              setEditionData({ ...editionData, mobileImage: data });
            } else {
              setEditionData({ ...editionData, desktopImage: data });
            }
          }
          let errorData = { ...errors };
          errorData = {
            ...errorData,
            [type]: "",
          };
          setErrors(errorData);
        } else {
          const message = `Image Height And Width Must Be ${width}px * ${height}px And Size Should Be Less Than 3 MB.`;
          let errorData = { ...errors };
          errorData = {
            ...errorData,
            [type]: message,
          };
          setErrors(errorData);
        }
        setImageLoader({
          [type]: false,
        });
      } else {
        const message = `File Format Not Supported`;
        let errorData = { ...errors };
        errorData = {
          ...errorData,
          [type]: message,
        };
        setErrors(errorData);
      }
    }
  };

  const handleSaveHighlightDetails = () => {
    if (isContentSaved) {
      return;
    }

    const publishDate = moment(editionData.date).format("L");
    const date = moment.utc(`${publishDate} ${editionData.time}`).toISOString();
    const data = {
      mobileImage: editionData.mobileImage,
      desktopImage: editionData.desktopImage,
      editionType: editionData.editionType,
      publishDate: date,
    };
    axios
      .post(`admin/add-edition`, data)
      .then((res) => {
        if (res?.data?.statusCode) {
          successAlert(res?.data?.message || "");
          setIsContentSaved(true);
          localStorage.setItem("editionId", res?.data?.data?._id);
          setCurrentEditionId(res?.data?.data?._id);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }

        errorAlert(message || API_MESSAGES.getError);
      });
  };

  const handlePublishEdition = () => {
    axios
      .patch(`admin/publish-edition`, { editionId: currentEditionId })
      .then((res) => {
        if (res?.data?.statusCode) {
          successAlert(res?.data?.message || "");
          setAddNew(false);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }

        errorAlert(message || API_MESSAGES.getError);
      });
  };

  useEffect(() => {
    if (previewDesktop || previewMobile) {
      axios
        .get(`admin/editionDetails?editionId=${currentEditionId}`)
        .then((res) => {
          if (res?.data?.data) {
            setFetchedData(res?.data?.data);
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }

          errorAlert(message || API_MESSAGES.getError);
        });
    }
  }, [previewDesktop, previewMobile]);

  const isWeekday = (date) => {
    const day = date.getDay();
    if (editionData.editionType === weekday) {
      return day !== 0 && day !== 6;
    } else {
      return day === 6;
    }
  };

  return (
    <div>
      <section className="main-div">
        <div className="heading bb">
          <div className="title">
            <h6>Add content</h6>
          </div>
          <div className="ml-auto">
            <button
              className="btn"
              onClick={() => {
                setEditContentId(null);
                setAddNew(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>

        <div className="content-type">
          <p className="text-black fw600">Content Type</p>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={weekday}
              value={editionData.editionType}
              name="radio-buttons-group"
              className="text"
            >
              <FormControlLabel
                value={weekday}
                onChange={(event) => {
                  if (!isContentSaved) {
                    delete editionData["date"];
                    setEditionData({
                      ...editionData,
                      editionType: event.target.value,
                    });
                  }
                }}
                control={<Radio />}
                label=" Weekday Edition"
              />
              <FormControlLabel
                onChange={(event) => {
                  if (!isContentSaved) {
                    delete editionData["date"];
                    setEditionData({
                      ...editionData,
                      editionType: event.target.value,
                    });
                  }
                }}
                value={weekend}
                control={<Radio />}
                label=" Weekend Edition"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="publish-date">
          <p className="m12 text-black fw500">Schedule Publishing</p>

          <div className="row">
            <div className="col-lg-6 col-md-4 col-5">
              <div className="form-in date-input">
                <label>Publishing Date of Edition</label>
                <DatePicker
                  disabled={isContentSaved}
                  minDate={moment().toDate()}
                  className="date-picker"
                  value={editionData?.date}
                  selected={editionData?.date}
                  onChange={(date) => {
                    if (!isContentSaved) {
                      setEditionData({ ...editionData, date });
                    }
                  }}
                  filterDate={isWeekday}
                  placeholderText="mm/dd/yyyy"
                />
                {/* <TextField
                  id="date"
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e, '***8e')
                    setEditionData({ ... editionData, date: e.target.value})

                  }}
                /> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-4 col-5">
              <div className="form-in">
                <label>Time of Publishing*</label>
                <TextField
                  disabled={isContentSaved}
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                  value={editionData?.time}
                  sx={{ width: 150 }}
                  onChange={(e) => {
                    if (!isContentSaved) {
                      setEditionData({ ...editionData, time: e.target.value });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content-row">
          <div className="cols">
            <div className="desk-banner">
              <p className="mb16 fw500">
                {editionData.editionType === weekday ? "Weekday" : "Weekend"}{" "}
                Edition Highlight Banner (1440x430)
              </p>
              <div className="img-upload">
                {imgLoader.desktop && (
                  <div className="spinner">
                    <span></span>
                  </div>
                )}
                {editionData?.desktopImage && (
                  <span className="delete-img">
                    <CancelButton
                      onClose={() => {
                        if (!isContentSaved) {
                          const newObj = { ...editionData };
                          delete newObj["desktopImage"];
                          setEditionData(newObj);
                        }
                      }}
                    />
                  </span>
                )}

                {editionData?.desktopImage ? (
                  <img src={editionData?.desktopImage} />
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleUploadImage(e, "desktop")}
                    />
                    <div className="text">
                      <span className="icon icon-upload">
                        <i className="upload-icon"></i>
                      </span>
                      <p>Upload from gallery</p>
                      <p>(1440x430)</p>
                      <p className="accept-img">
                        (accepts .png .jpeg .jpg images only)
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div name="mobileImage" className="input-feedback error-msg">
                {errors?.desktop}
              </div>
            </div>
          </div>

          <div className="">
            <div className="mobile-banner">
              <p className="m12 Edition Mobile Highlight Banner">
                {editionData.editionType === weekday ? "Weekday" : "Weekend"}{" "}
                Edition Mobile Highlight Banner (1080x1920)
              </p>
              <div className="img-upload">
                {imgLoader.mobile && (
                  <div className="spinner">
                    <span></span>
                  </div>
                )}
                {editionData?.mobileImage && (
                  <span className="delete-img">
                    <CancelButton
                      onClose={() => {
                        if (!isContentSaved) {
                          const newObj = { ...editionData };
                          delete newObj["mobileImage"];
                          setEditionData(newObj);
                        }
                      }}
                    />
                  </span>
                )}
                {editionData?.mobileImage ? (
                  <img src={editionData?.mobileImage} />
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        handleUploadImage(e, "mobile");
                      }}
                    />
                    <div className="text">
                      <span className="icon icon-upload">
                        <i className="upload-icon"></i>
                      </span>
                      <p>Upload from gallery</p>
                      <p>(1080x1920)</p>
                      <p className="accept-img">
                        (accepts .png .jpeg .jpg images only)
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div name="mobileImage" className="input-feedback error-msg">
                {errors?.mobile}
              </div>
            </div>
          </div>
        </div>

        <div className="btns-lower">
          <div>
            <button
              className="btn long"
              disabled={
                isContentSaved ||
                !editionData.mobileImage ||
                !editionData.desktopImage ||
                !editionData.editionType ||
                !editionData.date ||
                !editionData.time
              }
              onClick={() => handleSaveHighlightDetails()}
            >
              Save Details
            </button>
          </div>
        </div>
      </section>

      {allCardsArr &&
        allCardsArr.length > 0 &&
        allCardsArr.map((item, i) => {
          return (
            <React.Fragment key={`key_${i + 1}`}>
              <AddCard
                editionType={editionData?.editionType}
                currentEditionId={currentEditionId}
                cardCount={i + 1}
                hideCard={() => {
                  setIsPreviewDisplay(true);
                  setPreviewDesktop(false);
                  setPreviewMobile(false);
                }}
                categoriesData={categoriesData}
                groupData={groupData}
                hidePreview={() => {
                  setPreviewDesktop(false);
                  setPreviewMobile(false);
                }}
                enableAddCardButton={() => {
                  setEnableAddCard(true);
                }}
              />
            </React.Fragment>
          );
        })}

      {isPreviewDisplay && allCardsArr && allCardsArr.length > 0 && (
        <section className="main-div content-btns mt24">
          <div className="btns-lower">
            <div>
              <button
                className={`btn-white long ${previewDesktop ? "active" : ""}`}
                onClick={() => {
                  setPreviewDesktop(true);
                  setPreviewMobile(false);
                }}
              >
                Preview edition on Desktop
              </button>
            </div>
            <div>
              <button
                className={`btn-white long ${previewMobile ? "active" : ""}`}
                onClick={() => {
                  setPreviewMobile(true);
                  setPreviewDesktop(false);
                }}
              >
                Preview edition on Mobile
              </button>
            </div>
            <div className="ml-auto">
              <button
                disabled={!enableAddCard}
                className="btn long"
                onClick={() => {
                  if (enableAddCard) {
                    const newArr = allCardsArr.slice();
                    newArr.push({});
                    setAllCardsArr(newArr);
                    setEnableAddCard(false);
                  }
                }}
              >
                + Add Content Piece
              </button>
            </div>
          </div>
        </section>
      )}

      {(previewDesktop || previewMobile) &&
        Object.keys(fetchedData)?.length > 0 && (
          <section
            className={
              previewMobile
                ? "main-div preview-div mobile "
                : "main-div preview-div"
            }
          >
            <div className="heading">
              <div className="title">
                <h6>
                  Preview Edition On {previewDesktop ? "Desktop" : "Mobile"}
                </h6>
              </div>
              <div className="ml-auto">
                <button
                  className="btn"
                  onClick={() => {
                    setPreviewDesktop(false);
                    setPreviewMobile(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
            <PreviewDesktopMobile
              editionTab={previewDesktop ? "web" : "mobile"}
              fetchedData={fetchedData}
            />
          </section>
        )}

      {(previewDesktop || previewMobile) && (
        <div className="main-div content-btns mt24">
          <div className="btns-lower">
            <div>
              <button
                className="btn-white long"
                onClick={() => setAddNew(false)}
              >
                SAVE CONTENT PIECE
              </button>
            </div>
            <div>
              <button
                className="btn long"
                onClick={() => {
                  handlePublishEdition();
                }}
              >
                PUBLISH
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddContent;
