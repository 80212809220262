import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import DraggbleComp from "src/components/common/draggble";
import CancelButton from "src/components/common/modal-cancel-btn";
import { firstLetterUpper } from "src/helpers";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";
const ViewProfile = ({ data, open, setOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        // className={classFilter}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={DraggbleComp}
        aria-labelledby="draggable-dialog-title"
      >
        <div className="modal-text refferal-modal">
          <div className="close-btn">
            <CancelButton
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
          <DialogContent>
            <div>
              <div className="membership-div">
                <div className="heading">
                  <h6 className="fs16 mb0">{`Referral Partner Details`}</h6>
                  <p
                    className={`ml-auto ${
                      data?.profileStatus === "PENDING"
                        ? "text-copper"
                        : "text-blue"
                    } fw600`}
                  >
                    {data?.profileStatus
                      ? firstLetterUpper(data?.profileStatus)
                      : ""}
                  </p>
                </div>

                <div className="">
                  <ul className="user-info">
                    <li>
                      <small>First Name</small>
                      <p>{data?.firstName || "-"}</p>
                    </li>

                    <li>
                      <small>Last Name</small>
                      <p>{data?.lastName || "-"}</p>
                    </li>

                    <li>
                      <small>Mobile Number</small>
                      <p>{`${data?.countryCode || ""} - ${
                        data?.contactNumber
                      } `}</p>
                    </li>

                    <li>
                      <small>Email ID</small>
                      <p>{data?.email || "-"}</p>
                    </li>
                    <li>
                      <small>Country</small>
                      <p>{data?.country || "-"}</p>
                    </li>
                    <li>
                      <small>Status</small>
                      <p>
                        {data?.status ? firstLetterUpper(data?.status) : "-"}
                      </p>
                    </li>
                    <li>
                      <small>Sieve Member</small>
                      <p>{data?.memberOfSieve ? "Yes" : "No"}</p>
                    </li>
                    <li>
                      <small>Commission</small>
                      <p>{data?.commission ? `${data?.commission}%` : "-"}</p>
                    </li>
                    <li>
                      <small>Coupon Code</small>
                      <p>{data?.couponDetails?.code || "-"}</p>
                    </li>
                    <li>
                      <small>Referral Link</small>
                      <p>{data?.referralLink || "-"}</p>
                    </li>
                  </ul>
                </div>
              </div>
              {
                <div className="membership-div">
                  <div className="heading">
                    <h6 className="fs16 mb0">{`Bank Details`}</h6>
                  </div>
                  <div className="">
                    <ul className="user-info">
                      <li>
                        <small>Account Holder’s Name</small>
                        <p>{data?.bankDetails?.accountHolderName || "-"}</p>
                      </li>

                      <li>
                        <small>Account Number</small>
                        <p>{data?.bankDetails?.accountNumber || "-"}</p>
                      </li>

                      <li>
                        <small>IFSC Code / IBAN / Swift Code</small>
                        <p>{`${data?.bankDetails?.ifsc || "-"}`}</p>
                      </li>

                      <li>
                        <small>Bank Name</small>
                        <p>{data?.bankDetails?.bankName || "-"}</p>
                      </li>
                      <li>
                        <small>Branch Address - Line 1</small>
                        <p>{data?.bankDetails?.branchAddressLane1 || "-"}</p>
                      </li>
                      <li>
                        <small>Branch Address - Line 2</small>
                        <p>{data?.bankDetails?.branchAddressLane2 || "-"}</p>
                      </li>
                      <li>
                        <small>City</small>
                        <p>{data?.bankDetails?.city || "-"}</p>
                      </li>
                      <li>
                        <small>Country</small>
                        <p>{data?.bankDetails?.country || "-"}</p>
                      </li>
                      <li>
                        <small>ZIP Code</small>
                        <p>{data?.bankDetails?.zipCode || "-"}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </div>
            {data?.profileStatus === "PENDING" && (
              <div className="text-center">
                <button
                  className="btn-white"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  SEND EMAIL
                </button>
              </div>
            )}
          </DialogContent>
        </div>
        <SEND_PERSONALIZED_EMAIL_MODAL
          isSalesAgent={true}
          open={isOpen}
          setOpen={setIsOpen}
          email={data?.email}
        />
      </Dialog>
    </>
  );
};

export default ViewProfile;
