import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import { resendOtp, verifyOtp } from "src/redux/services/auth.service";
import { setOtp } from "src/redux/features/authSlice";
import SieveLogo from "src/assets/images/Sieve-logo-black.svg";

const VerifyOTP = () => {
  const [otpData, setOtpData] = useState("");
  const [error, setError] = useState("");

  const { currentUserEmail: email, otp } = useSelector((state) => {
    return state.auth;
  });

  // DISPATCH AN ACTION
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleVerifyOtp = () => {
    if (otpData.length === 4) {
      setError("");
      dispatch(
        verifyOtp({
          email,
          otp: otpData,
          callback: (res) => {
            if (res) {
              if (res?.statusCode === 200) {
                navigate("/reset-password");
              }
            }
          },
        })
      );
    } else {
      setError("Please Enter The OTP Recieved.");
    }
  };

  const handleResendOTP = () => {
    dispatch(
      resendOtp({
        email,
        otp,
      })
    );
  };

  return (
    <div>
      <section className="login">
        <button
          className="null-btn back-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <span className="icon">
            <i className="back-icon"></i>{" "}
          </span>
          Back
        </button>
        <div className="wrapper">
          <div className="text">
            <div className="head">
              <figure className="logo">
                <img src={SieveLogo} alt="SieveLogo" />
              </figure>

              <p className="txt">
                Expertly curated insights <br /> for the next generation of
                business
              </p>
            </div>

            <div className="body">
              <h6 className="font-old title fs20">OTP Verification</h6>
              <p className="text-center mb24">
                Enter the 4 digits OTP code sent to your authorized email.
              </p>
              <div className="form-in icon mb12">
                <div className="f-in otp">
                  <OtpInput
                    value={otpData}
                    onChange={(otp) => {
                      setOtpData(otp);
                      dispatch(setOtp(otp));
                    }}
                    containerStyle="otpContainer"
                    isInputNum={true}
                    inputStyle="otpInput"
                    focusStyle="otpInputFocus"
                    numInputs={4}
                    separator={false}
                    className="otp-input"
                  />
                </div>
                {error && <p className="error-msg">{error}</p>}
              </div>
            </div>

            <div className="foot">
              <div>
                <button
                  type="button"
                  className="btn single"
                  onClick={handleVerifyOtp}
                >
                  DONE
                </button>
              </div>

              <div>
                <p className="mt18">
                  Didn’t Receive OTP?{" "}
                  <a className="link" onClick={handleResendOTP}>
                    Resend otp
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyOTP;
