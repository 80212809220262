import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import DraggbleComp from "../draggble";
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
} from "react-rte";
import CancelButton from "../modal-cancel-btn";
import { useDispatch } from "react-redux";
import { sendPersonalizedEmail } from "src/redux/services/user.service";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import { height } from "@mui/system";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

const SEND_PERSONALIZED_EMAIL_MODAL = ({
  open,
  setOpen,
  email,
  type,
  isSalesAgent = false,
}) => {
  const [value, setValue] = useState(
    RichTextEditor.createEmptyValue("Bonjour, Lemonde!")
  );
  const [subject, setSubject] = useState("");
  const dispatch = useDispatch();

  const handleSendPersonalisedEmail = () => {
    const content = value.toString("html", {
      blockStyleFn: getTextAlignStyles,
    });
    if (isSalesAgent) {
      customAxios
        .post(`admin/send-personalised-email-to-sales-agent`, {
          content,
          subject,
          email,
        })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            successAlert(response.data?.message);
            setOpen(false);
            setValue(RichTextEditor.createEmptyValue("Bonjour, Lemonde!"));
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(message);
        });
    } else {
      dispatch(sendPersonalizedEmail({ content, subject, email, type })).then(
        ({ payload, error }) => {
          if (!error) {
            setOpen(false);
            setValue(RichTextEditor.createEmptyValue("Bonjour, Lemonde!"));
          }
        }
      );
    }
  };

  const getDisabledStatus = () => {
    const content = value.getEditorState().getCurrentContent().getPlainText();
    return !subject || !content;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setValue(RichTextEditor.createEmptyValue("Bonjour, Lemonde!"));
      }}
      PaperComponent={DraggbleComp}
      aria-labelledby="draggable-dialog-title"
    >
      <div className="modal-text email-modal">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
              setValue(RichTextEditor.createEmptyValue("Bonjour, Lemonde!"));
            }}
          />
        </div>
        <DialogContent>
          <div>
            <div className="heading">
              <h6 className="fs18 mb0">Send Personalized Email</h6>
            </div>
            <div>
              <div className="form-in">
                <label>Subject</label>
                <div className="f-in">
                  <input
                    type="text"
                    placeholder="Enter Email Subject"
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    maxLength={100}
                  />
                </div>
              </div>

              <div className="form-in">
                <label>Description</label>
                <RichTextEditor
                  maxTextLength={10}
                  editorClassName="demo-editor"
                  // editorStyle = {
                  //   height = "200px",
                  // }
                  blockStyleFn={getTextAlignClassName}
                  toolbarConfig={toolbarConfig}
                  value={value}
                  onChange={(data) => {
                    setValue(data);
                  }}
                />
              </div>
            </div>
            <div className="text-center pt24">
              <button
                className="btn"
                onClick={handleSendPersonalisedEmail}
                disabled={getDisabledStatus()}
              >
                SEND
              </button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SEND_PERSONALIZED_EMAIL_MODAL;
