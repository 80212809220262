import React from "react";

function DesktopPreview({ setPreview, allContentValues }) {
  return (
    <section className="main-div preview-div">
      <div className="heading">
        <div className="title">
          <h6>Preview Content Piece On Desktop</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              setPreview("");
            }}
          >
            CLOSE
          </button>
        </div>
      </div>

      <div className="pt24">
        <div className="cards-row">
          <div>
            <article className="article-card">
              <header>
                <p>
                  {allContentValues?.categoryName
                    ? allContentValues.categoryName.toUpperCase()
                    : ""}
                </p>
              </header>
              <figure>
                {<img src={allContentValues?.mobileImage} alt={"Front"} />}
              </figure>
              <div className="text">
                <div className="labels">
                  <mark>{allContentValues?.attribute || ""}</mark>
                  <small>IMAGE: {allContentValues?.imageSource || ""}</small>
                </div>

                <p>{allContentValues?.title || ""}</p>
              </div>
              <footer>
                <button className="blank-btn">
                  <i className="bookmark-icon"></i>
                </button>
                <button className="blank-btn">
                  <i className="share-icon"></i>
                </button>
              </footer>
              <a href="javascript:;" title="Title"></a>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DesktopPreview;
