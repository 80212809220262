import React, { useEffect, useState } from "react";
import ByType from "./ByType";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import BySource from "./BySource";
import ByGeography from "./ByGeography";
import ByCoupon from "./ByCoupon";
import ByStatus from "./ByStatus";

const PaidMemberships = ({ setLoading, startDate = null, endDate = null }) => {
  const [paidMemberships, SetPaidMemberships] = useState();
  const [byType, setType] = useState("");
  const [byStatusWithType, setByStatusWithType] = useState("");
  const [byStatusWithSource, setByStatusWithSource] = useState("");

  useEffect(() => {
    getMembershipsByType();
  }, []);

  useEffect(() => {
    if (byStatusWithType !== "" || byType !== "") {
      getMembershipsByType();
    }
  }, [byType, byStatusWithType]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getMembershipsByType();
    }
  }, [startDate, endDate]);

  const getMembershipsByType = () => {
    setLoading(true);
    let url = `admin/sales-management-global/paid-memberships-by-type-source?`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&`;
    }
    if (byType) {
      url = url + `byType=${byType}&`;
    }
    if (byStatusWithType) {
      url = url + `byStatusWithType=${byStatusWithType}&`;
    }
    if (byStatusWithSource) {
      url = url + `byStatusWithSource=${byStatusWithSource}&`;
    }
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          SetPaidMemberships(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <ByType
        data={paidMemberships?.byType}
        setType={setType}
        setByStatusWithType={setByStatusWithType}
      />
      <BySource
        data={paidMemberships?.bySource}
        setByStatusWithSource={setByStatusWithSource}
      />
      <ByGeography
        setLoading={setLoading}
        startDate={startDate}
        endDate={endDate}
      />
      <ByCoupon
        setLoading={setLoading}
        startDate={startDate}
        endDate={endDate}
      />
      <ByStatus setLoading={setLoading} />
    </>
  );
};

export default PaidMemberships;
