import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorMessage } from "src/helpers";

//getCategory Data
export const getCategory = createAsyncThunk(
  "admin/getCategory",
  // Callback Function
  async ({ callback }, { rejectWithValue }) => {
    try {
      return await axios.get("admin/categoriesList");
    } catch (error) {
      let message = "";
      if (error?.response && error?.response?.data) {
        message = errorMessage(error) || "";
        if(callback){
        callback(message);
        }
        return rejectWithValue(error?.response?.data);
      } else {
        message = error?.message || "";
        if(callback){
        callback(message);
        }
        return rejectWithValue(error?.message);
      }
    }
  }
);

//Create New Category
export const createCategory = createAsyncThunk(
  "/category/add",
  // Callback Function

  async ({ name, status, cb }, { rejectWithValue }) => {
    try {
      const res = await axios.post("/category/add", { name, status });
      if (res?.data?.data) {
        cb(true, res?.data?.data?.message);
      }
      return res;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
