import React from "react";
import { CButton } from "@coreui/react";

export default function Button({ onHandleClick, disabled, text, ...rest }) {
  return (
    <>
      <CButton onClick={onHandleClick} disabled={disabled} {...rest}>
        {text}
      </CButton>
    </>
  );
}
