import React, { memo, useMemo } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { firstLetterUpper } from "src/helpers";

const BasicDetails = ({ info, count }) => {
  const personalInfoData = useMemo(() => {
    return {
      status: info?.status,
      email: info?.email || "-",
      classification: "Personal Membership",
      customerId: info?.customerId || "-",
      firstName: info?.firstName || "-",
      lastName: info?.lastName || "-",
      contactNumber:
        info?.contactNumber && info?.countryCode
          ? `${info?.countryCode} - ${info?.contactNumber}`
          : "-",

      free_trial: {
        status: info?.isFreeTrial
          ? firstLetterUpper(info?.freeTrialStatus)
          : "Expired",

        start_date: info?.freeTrialStartDate || "",
        end_date: info?.freeTrialEndDate || "",
      },
      referral_source: info?.userSalesAgentDetails || {},
    };
  }, [info]);

 
  return (
    <div className="membership-div">
      {/* <h6 className="fs16 mb24">
        <span className="text-text">Details found for: </span>
        {personalInfoData?.email}
      </h6> */}
      <div className="d-flex">
        <h6 className="fs18 mb12">
          {`Customer Information ${count ? "#" + count : ""}`}
        </h6>
        <div className="ml-auto">
          {/* <i className={` ${personalInfoData.status === "ACTIVE"?"green-flag-icon" : "red-flag-icon"}`}></i> */}
          {/* <p className={`fw600 ${personalInfoData.status === "ACTIVE"?"text-green" : "text-red"}`}>{personalInfoData.status === "ACTIVE" ? "Active" : "In-Active"}</p> */}
        </div>
      </div>

      <div className="">
        <ul className="user-info">
          <li>
            <small>Classification</small>
            <p>{personalInfoData?.classification}</p>
          </li>

          <li>
            <small>Email ID</small>
            <p>{personalInfoData?.email}</p>
          </li>

          <li>
            <small>Customer ID</small>
            <p>{personalInfoData?.customerId}</p>
          </li>

          <li>
            <small>First Name</small>
            <p>{personalInfoData?.firstName}</p>
          </li>

          <li>
            <small>Last Name</small>
            <p>{personalInfoData?.lastName}</p>
          </li>

          <li>
            <small>Mobile Number</small>
            <p>{personalInfoData?.contactNumber}</p>
          </li>

          <li>
            <small>Status</small>
            <p
              className={`fw600 ${
                personalInfoData.status === "ACTIVE" ? "text-green" : "text-red"
              }`}
            >
              {personalInfoData.status}
            </p>
          </li>
        </ul>

        <div className="status-info">
          {personalInfoData?.free_trial && (
            <div>
              <h6
                className={`${
                  personalInfoData?.free_trial?.status === "Expired"
                    ? "text-red"
                    : "text-green"
                }`}
              >
                Free Trial Status - {personalInfoData?.free_trial?.status}
              </h6>

              <div className="text">
                <div>
                  <small>Start Date</small>
                  <p>
                    {personalInfoData?.free_trial?.start_date
                      ? format(
                          utcToZonedTime(
                            new Date(personalInfoData?.free_trial?.start_date),
                            "UTC"
                          ),
                          "MMM dd, yyyy"
                        )
                      : "-"}
                  </p>
                </div>
                <div>
                  <small>End Date</small>
                  <p>
                    {personalInfoData?.free_trial?.end_date
                      ? format(
                          utcToZonedTime(
                            new Date(personalInfoData?.free_trial?.end_date),
                            "UTC"
                          ),
                          "MMM dd, yyyy"
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          )}
          {personalInfoData.referral_source && (
            <div>
              <h6>Source - Referral</h6>

              <div className="text">
                <div>
                  <small>Referral Partner ID</small>
                  <p>
                    {personalInfoData?.referral_source?.partnerId ||
                      "-"}
                  </p>
                </div>
                <div>
                  <small>Referral Partner Name</small>
                  <p>{personalInfoData?.referral_source?.firstName || "-"}{" "} {personalInfoData?.referral_source?.lastName || "-"}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(BasicDetails);
