import { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";
import customAxios from "src/utils/axios";
import All_Free_Trial_SignUps from "./All_Free_Trial_SignUps";
import All_Paid_Memberships from "./All_Paid_Memberships";
import ComplimentaryMemberships from "./ComplimentaryMemberships";
import ContentClicksByEdition from "./ContentClicksByEdition";
import InactiveUsers from "./InactiveUsers";
import ReferralRevenueAndComission from "./ReferralRevenueAndComission";
import Referral_Free_Trial_SignUps from "./Referral_Free_Trial_SignUps";
import Referral_Paid_Memberships from "./Referral_Paid_Memberships";
import Referral_Partner_Profile_Detail from "./Referral_Partner_Profile_Detail";
import Revenue_By_Month from "./Revenue_By_Month";
import DatePicker from "react-datepicker";
import { errorAlert, successAlert } from "src/utils/alerts";
import {
  editionFilter,
  GeographyOptions,
  MAX_PAGE_LIMIT,
  SourceOptions,
  StatusOptions,
} from "src/constant/global";
import moment from "moment";
const date = new Date();
const monthValue = moment(date).month();
const year = moment(date).year();

const InitialVluesForReport = {
  allFreeTrialByGeography: GeographyOptions[0].value,
  referralFreeTrialByGeography: GeographyOptions[0].value,
  allPaidMembershipsByGeography: GeographyOptions[0].value,
  referralPaidMembershipsByGeography: GeographyOptions[0].value,
  revenueByMonth: monthValue,
  revenueByYear: year,
  revenuebySource: SourceOptions[0].value,
  contentClicksFilterBy: editionFilter[0].value,
  transactionTypeForReferralRevenueCommission: StatusOptions[0].value,
};

const Reports_And_Analytics = () => {
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeSalesAgent, setSalesAgent] = useState([]);
  const [nonFilterSection, setNonFilterSections] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [dataForSendReport, setDataForSendReport] = useState(
    InitialVluesForReport
  );

  useEffect(() => {
    getActiveSalesAgent();
    getReferralComplementaryAndInActiveUserInfo();
  }, []);

  useEffect(() => {
    getReferralComplementaryAndInActiveUserInfo();
  }, [curPage]);

  const getActiveSalesAgent = () => {
    setLoading(true);
    customAxios
      .get(`admin/reports-analytics/active-referral-partners`)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          setSalesAgent(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getReferralComplementaryAndInActiveUserInfo = (
    sendEmailReport = ""
  ) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-inactive-users-info?`;
    if (sendEmailReport) {
      url = url + `sendEmailReport=${sendEmailReport}&`;
    } else {
      url = url + `limit=${MAX_PAGE_LIMIT}&pageNo=${curPage}&`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!sendEmailReport) {
            setNonFilterSections(res?.data?.data);
            const inActiveUsers = res?.data?.data?.inActiveUsersList;
            setTotalCount(inActiveUsers?.totalPage);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportToEmail = () => {
    setLoading(true);
    let dataToSent = {
      ...dataForSendReport,
    };

    if (rangeStart !== null && rangeEnd !== null) {
      dataToSent = {
        ...dataToSent,
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString(),
      };
    }
    if (selectedDate !== null) {
      dataToSent = {
        ...dataToSent,
        selectedDate,
      };
    }
    customAxios
      .post(`admin/reports-analytics/send-report-to-email`, {
        ...dataToSent,
      })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          successAlert(res?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  return (
    <>
      <div className="main-div h-auto mt24">
        <div className="heading mb0">
          <div className="title">
            <h6 className="fw600">Reports & Analytics</h6>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <div className="main-div h-auto mt24">
        <div className="heading">
          <h6 className="fw600 ml-auto pr24">Filter By Date Range</h6>
          <div className="">
            <div className="form-in date-pick d-flex">
              <DateRangePicker
                setCurrPage={setCurrPage}
                selectStartDate={selectStartDate}
                selectEndDate={selectEndDate}
                startDate={rangeStart}
                endDate={rangeEnd}
                minDate={rangeStart}
                disableEndDate={!rangeStart}
              />
              <button
                className="btn-white ml12"
                onClick={() => {
                  clearDates();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <All_Free_Trial_SignUps
              startDate={rangeStart}
              endDate={rangeEnd}
              setLoading={setLoading}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
            <Referral_Free_Trial_SignUps
              startDate={rangeStart}
              endDate={rangeEnd}
              setLoading={setLoading}
              activeSalesAgent={activeSalesAgent}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>

          <div className="col-md-6">
            <Revenue_By_Month
              startDate={rangeStart}
              endDate={rangeEnd}
              setLoading={setLoading}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>
        </div>
      </div>
      <div className="main-div h-auto mt24">
        <div className="heading">
          <h6 className="fw600 ml-auto pr30">Filter By Specific Date</h6>
          <div className="pr24">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              placeholderText="Select Date"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <All_Paid_Memberships
              selectedDate={selectedDate}
              setLoading={setLoading}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />

            <ComplimentaryMemberships
              selectedDate={selectedDate}
              setLoading={setLoading}
            />
          </div>

          <div className="col-md-6">
            <Referral_Paid_Memberships
              selectedDate={selectedDate}
              setLoading={setLoading}
              activeSalesAgent={activeSalesAgent}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt24">
          <ContentClicksByEdition
            setLoading={setLoading}
            dataForSendReport={dataForSendReport}
            setDataForSendReport={setDataForSendReport}
          />

          <ReferralRevenueAndComission
            setLoading={setLoading}
            dataForSendReport={dataForSendReport}
            setDataForSendReport={setDataForSendReport}
            activeSalesAgent={activeSalesAgent}
          />
        </div>

        <div className="col-md-6 ">
          <Referral_Partner_Profile_Detail
            getReferralComplementaryAndInActiveUserInfo={
              getReferralComplementaryAndInActiveUserInfo
            }
            data={
              nonFilterSection &&
              nonFilterSection?.referralPartnersProfileDetails
            }
          />
        </div>
      </div>
      <div className="main-div h-auto  mt24">
        <InactiveUsers
          setCurrPage={setCurrPage}
          totalCount={totalCount}
          curPage={curPage}
          getReferralComplementaryAndInActiveUserInfo={
            getReferralComplementaryAndInActiveUserInfo
          }
          data={nonFilterSection && nonFilterSection?.inActiveUsersList.data}
        />
      </div>
      <div className="text-center mt24">
        <button className="btn" onClick={sendReportToEmail}>
          SEND REPORT TO EMAIL
        </button>
      </div>
    </>
  );
};

export default Reports_And_Analytics;
