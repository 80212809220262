import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "src/utils/axios";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import TabButton from "./components/TabButton";
import ModalComp from "src/components/common/modal";

import {
  MAX_PAGE_LIMIT,
  API_MESSAGES,
  NOT_AUTHORIZED_TEXT,
} from "src/constant/global";

import {
  errorMessage,
  dateFormat,
  isAddAccess,
  isDeleteAccess,
  isEditAccess,
} from "src/helpers";
import { getCategory } from "src/redux/services/category.service";
import { getGroupsList } from "src/redux/services/group.service";

import Action from "src/components/common/Action";
import AddContent from "./add-content&edition/AddContent";
import ViewContent from "./view-content-edition/";
import ViewEditContent from "./edit-content&edition/ViewContent";
import { errorAlert, successAlert } from "src/utils/alerts";

const ContentEditionManagement = () => {
  const dispatch = useDispatch();

  const [editionTab, setEditionTab] = useState("Weekday");
  const [curPage, setCurrPage] = useState(1);
  const [contentList, setContentList] = useState({});
  const [totalCount, setTotalCount] = useState(1);

  const [addNew, setAddNew] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const [isViewEditionCard, setViewEditionCard] = useState(false);
  const [id, setId] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [editContentId, setEditContentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [rangeStart, setRangeStart] = React.useState(null);
  // const defaultEndDate = new Date();
  // defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  const [rangeEnd, setRangeEnd] = React.useState(null);
  const [isDataFetched, setIsDataFetched] = React.useState(false);

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  const { categories = [], groups = [] } = useSelector((state) => {
    return {
      categories: state?.category?.category,
      groups: state?.groups?.groupsList,
    };
  });

  useEffect(() => {
    if (categories && categories.length > 0) {
      setCategoriesData(categories);
    } else {
      setCategoriesData([]);
    }
  }, [categories]);

  useEffect(() => {
    dispatch(getCategory({}));
    dispatch(getGroupsList({}));
  }, []);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const filterData = groups?.filter((item) => item?.topics?.length > 0);

      setGroupData(filterData);
    } else {
      setGroupData([]);
    }
  }, [groups]);

  const getEditionListApi = () => {
    let url = `admin/editionsList?limit=${MAX_PAGE_LIMIT}&page=${curPage}&editionType=${editionTab.toUpperCase()}`;
    if (rangeStart !== null && rangeEnd !== null) {
      url = url + `&startDate=${rangeStart}&endDate=${rangeEnd}`;
    }
    axios
      .get(url)
      .then((res) => {
        setLoading(false);

        if (
          res &&
          res.data &&
          Object.keys(res.data).length > 0 &&
          res.data.data
        ) {
          setIsDataFetched(true);
          setContentList(res.data.data);
          const totalPagesCount =
            res.data.data.total && res.data.data.total > 0
              ? Math.ceil(res.data.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        }
      })
      .catch((err) => {
        setLoading(false);
        let message = "";
        setIsDataFetched(true);

        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }
        setContentList({});
        setTotalCount(1);

        errorAlert(message || API_MESSAGES.getError);
      });
  };

  useEffect(() => {
    if ((rangeStart && rangeEnd) || (!rangeStart && !rangeEnd)) {
      getEditionListApi();
      setLoading(true);
    }
  }, [curPage, editionTab, rangeStart, rangeEnd]);

  const heading = [
    "Sr. No.",
    "Publishing Date",
    "No. of Cards",
    "Status",
    "Action",
  ];

  const handleTabSwitch = (type) => {
    if (type === "Weekday" && editionTab === "Weekend") {
      setCurrPage(1);
      setTotalCount(1);
      setContentList({});
      setEditionTab("Weekday");
    } else if (type === "Weekend" && editionTab === "Weekday") {
      setCurrPage(1);
      setTotalCount(1);
      setContentList({});
      setEditionTab("Weekend");
    }
  };

  const handleViewClick = (id) => {
    setViewEditionCard(true);
    setId(id);
  };

  const handleDelete = () => {
    axios
      .delete(`admin/delete-edition/${deleteId}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setIsDelete(false);
          getEditionListApi();
          successAlert(API_MESSAGES.deleteEditionSuccess);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }

        errorAlert(message || API_MESSAGES.getError);
      });
  };

  useEffect(() => {
    if (addNew) {
      getEditionListApi();
    } else if (!editContentId) {
      getEditionListApi();
    }
  }, [addNew, editContentId]);
  return (
    <>
      {!addNew && !editContentId && !isViewEditionCard && (
        <div className="main-div">
          <div className="heading">
            <div className="title">
              <h6>Content & Edition Management</h6>
            </div>
            <TabButton
              editionTab={editionTab}
              handleTabSwitch={handleTabSwitch}
            />

    

            <div className="btn-grp ml-auto">
              <button
                className="btn"
                onClick={() => {
                  const isAdd = isAddAccess("EDITION_MANAGEMENT");
                  if (isAdd) {
                    setAddNew(true);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              >
                Add New
              </button>
            </div>
          </div>

          <div className="heading">
            <div className="ml-auto">
              <div className="form-in date-pick d-flex">
                <DateRangePicker
                  setCurrPage={setCurrPage}
                  className="mr-10"
                  selectStartDate={selectStartDate}
                  selectEndDate={selectEndDate}
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  minDate={rangeStart}
                  disableEndDate={!rangeStart}
                />
                <button className="btn-white ml12" onClick={() => clearDates()}>
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className="full content-table table-sec">
            <Table>
              <TableHead>
                <TableRow>
                  {heading &&
                    heading.length > 0 &&
                    heading.map((h, index) => (
                      <TableCell key={index} align="left">
                        {h}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              {loading && <Loader />}

              <TableBody>
                {contentList &&
                  contentList.data &&
                  contentList.data.length > 0 &&
                  contentList.data.map((content, index) => {
                    return (
                      <TableRow key={content._id}>
                        <TableCell>
                          {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                        </TableCell>
                        <TableCell>
                          {dateFormat(content.publishDate || "")}
                        </TableCell>
                        <TableCell>{content.cardsCount || "0"}</TableCell>
                        <TableCell>
                          <span
                            className={`chip-label ${
                              content &&
                              content.status &&
                              content.status.toLowerCase() === "draft"
                                ? "blue"
                                : ""
                            }`}
                          >
                            {content &&
                            content.status &&
                            content.status.toLowerCase() === "draft"
                              ? "Draft"
                              : "Published"}
                          </span>
                        </TableCell>
                        <TableCell className="center-btn">
                          <Action
                            setEditContentId={(id) => {
                              const isEdit = isEditAccess("EDITION_MANAGEMENT");
                              if (isEdit) {
                                setEditContentId(id);
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                            setDeleteId={(id) => {
                              const isDelete =
                                isDeleteAccess("EDITION_MANAGEMENT");
                              if (isDelete) {
                                setDeleteId(id);
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                            status={
                              content.status && content.status.toLowerCase()
                            }
                            isEdit={content.status.toLowerCase() === "draft"}
                            isDelete={content.status.toLowerCase() === "draft"}
                            setIsDelete={() => {
                              const isDelete =
                                isDeleteAccess("EDITION_MANAGEMENT");
                              if (isDelete) {
                                setIsDelete(true);
                              }
                            }}
                            handleViewClick={handleViewClick}
                            id={content._id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {totalCount > 1 && (
              <Pagination
                page={curPage}
                count={totalCount}
                variant="outlined"
                shape="rounded"
                defaultPage={1}
                onChange={(type, page) => {
                  setCurrPage(page);
                }}
              />
            )}

            {((contentList && Object.keys(contentList).length === 0) ||
              (contentList.data && contentList.data.length === 0)) &&
              isDataFetched && (
                <div className="no-content">
                  <h6>No Content Added Yet</h6>
                  <p>
                    You haven’t added anything yet. Click on Add content button
                    to add new content for edition management
                  </p>
                </div>
              )}
          </div>
        </div>
      )}
      {addNew && (
        <AddContent
          isEdit={editContentId}
          setAddNew={setAddNew}
          setEditContentId={setEditContentId}
          categoriesData={categoriesData}
          groupData={groupData}
        />
      )}

      {editContentId && (
        <ViewEditContent
          editId={editContentId}
          setAddNew={setAddNew}
          setEditContentId={setEditContentId}
          categoriesData={categoriesData}
          groupData={groupData}
        />
      )}
      {isViewEditionCard && (
        <ViewContent setViewEditionCard={setViewEditionCard} id={id} />
      )}

      <ModalComp
        open={isDelete}
        title="Confirmation"
        setOpen={() => setIsDelete(false)}
        content="Are you sure, you want to Delete this Edition?"
        actions={true}
        onClickYes={handleDelete}
      />
    </>
  );
};

export default ContentEditionManagement;
