import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isNullEmptyOrUndefined } from "src/helpers";
import appConfig from "src/appConfig";
import { errorAlert, successAlert } from "src/utils/alerts";
import moment from "moment-timezone";

//getUSers Data
export const getUsers = createAsyncThunk(
  "/users/lists",
  // Callback Function
  async (
    { pageNo = 1, limit = 10, callback, ...rest },
    { rejectWithValue }
  ) => {
    const data = { ...rest };

    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        delete data[key];
      }
    });

    try {
      const response = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/user-list`,
        {
          ...data,
          pageNo,
          limit,
        }
      );
      if (response && response.data?.statusCode === 200) {
        callback(response.data);
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);

//update membership
export const updateMembership = createAsyncThunk(
  "/users/updateMembership",
  // Callback Function
  async ({ subscriptionId, endDate, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `admin/update-membership/${subscriptionId}`,
        {
          // endDate: endDate,
          endDate: moment(endDate).utc(),
        }
      );

      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
        callback(response);
      }

      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);

//send personalized email to user
export const sendPersonalizedEmail = createAsyncThunk(
  "/users/sendPersonalizedEmail",
  // Callback Function
  async ({ email = "", subject, content, type = "" }, { rejectWithValue }) => {
    let dataToSent = {};
    if (email) {
      dataToSent = {
        email,
      };
    } else {
      dataToSent = {
        type,
      };
    }
    try {
      const response = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/send-personalised-email`,
        {
          ...dataToSent,
          subject: subject || "Expiry date change",
          content: content || new Date(),
        }
      );
      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);

//Change Password As Admin
export const createUser = createAsyncThunk(
  "/user/add",
  // Callback Function
  async (
    {
      firstName,
      lastName,
      email,
      password,
      countryCode,
      contact,
      termsAndConditionsAccepted,
      cb,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post("/user-management", {
        firstName,
        lastName,
        email,
        password,
        contactCountryCode: !isNullEmptyOrUndefined(countryCode)
          ? Number(countryCode)
          : 0,
        contactNumber: !isNullEmptyOrUndefined(contact) ? Number(contact) : 0,
        termsAndConditionsAccepted,
      });
      if (res?.data?.data) {
        cb(true);
      }

      return res;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong While Adding Category";
        cb(false, message);
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

//Change Password As Admin
export const editUser = createAsyncThunk(
  "/user/edit",
  // Callback Function
  async (
    { userId, firstName, lastName, contactCountryCode, contactNumber, cb },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.patch(`/user-management/${userId}`, {
        firstName,
        lastName,
        contactCountryCode,
        contactNumber,
      });
      if (res?.data?.data) {
        cb(true);
      }

      return res;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
