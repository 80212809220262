import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { firstLetterUpper } from "src/helpers";

// Validation Schema
const validationSchema = Yup.object().shape({
  price: Yup.number()
    .min(1, "Amount Should Be Greater Than  Or Equal To $1.")
    .max(999, "Amount Should Be Less Than $999.")
    .required("Please Enter An Amount."),
});

const EditMembership = ({
  setIsEdit,
  editMembershipValues,
  handleEditMembershipSumbit,
}) => {
  const [discountPercentage, setDiscountPercentage] = useState(
    editMembershipValues?.savingPercentage
  );

  const [error, setError] = useState("");

  const calculateBaseVal = () => {
    let baseVal = {};
    if (
      editMembershipValues.baseValue.type === "MONTHLY" &&
      editMembershipValues.baseValue.status === "ACTIVE"
    ) {
      baseVal.price = editMembershipValues.baseValue.price;
      baseVal.type = "MONTHLY";
    } else if (editMembershipValues.baseValue.type === "QUARTERLY") {
      baseVal.price = editMembershipValues.baseValue.price;
      baseVal.type = "QUARTERLY";
    }
    return baseVal;
  };

  const handleBaseValError = (baseVales, validCal) => {
    setError(
      `Entered Amount Cannot Be Greater Than $${baseVales.price * validCal}.`
    );
    setDiscountPercentage(0);
  };

  // const calculateOffPercentage = (price, baseVales) => {
  //   let planPercentage;
  //   let avgVal;
  //   let validCal;
  //   // Monthly conditions
  //   if (
  //     baseVales.type === "MONTHLY" &&
  //     editMembershipValues.type === "ANNUAL"
  //   ) {
  //     avgVal = baseVales.price * 12;
  //     planPercentage = ((avgVal - price) * 100) / avgVal;
  //     validCal = 12;
  //   } else if (
  //     baseVales.type === "MONTHLY" &&
  //     editMembershipValues.type === "SEMI_ANNUAL"
  //   ) {
  //     avgVal = baseVales.price * 6;
  //     planPercentage = ((avgVal - price) * 100) / avgVal;
  //     validCal = 6;
  //   } else if (
  //     baseVales.type === "MONTHLY" &&
  //     editMembershipValues.type === "QUARTERLY"
  //   ) {
  //     avgVal = baseVales.price * 3;
  //     planPercentage = ((avgVal - price) * 100) / avgVal;
  //     validCal = 3;
  //   }

  //   // quarter conditions
  //   if (
  //     baseVales.type === "QUARTERLY" &&
  //     editMembershipValues.type === "ANNUAL"
  //   ) {
  //     avgVal = baseVales.price * 4;
  //     planPercentage = ((avgVal - price) * 100) / avgVal;
  //     validCal = 4;
  //   } else if (
  //     baseVales.type === "QUARTERLY" &&
  //     editMembershipValues.type === "SEMI_ANNUAL"
  //   ) {
  //     avgVal = baseVales.price * 2;
  //     planPercentage = ((avgVal - price) * 100) / avgVal;
  //     validCal = 2;
  //   }
  //   if (planPercentage < 0) {
  //     return { planPercentage: planPercentage, validCal: validCal };
  //   } else {
  //     return { planPercentage: Math.round(planPercentage), validCal: validCal };
  //   }
  // };

  const calculateOffPercentage = (price, baseVales) => {
    let planPercentage;
    let avgVal;
    let validCal;

    switch (baseVales.type) {
      case "MONTHLY":
        switch (editMembershipValues.type) {
          case "ANNUAL":
            avgVal = baseVales.price * 12;
            validCal = 12;
            break;
          case "SEMI_ANNUAL":
            avgVal = baseVales.price * 6;
            validCal = 6;
            break;
          case "QUARTERLY":
            avgVal = baseVales.price * 3;
            validCal = 3;
            break;
        }
        break;
      case "QUARTERLY":
        switch (editMembershipValues.type) {
          case "ANNUAL":
            avgVal = baseVales.price * 4;
            validCal = 4;
            break;
          case "SEMI_ANNUAL":
            avgVal = baseVales.price * 2;
            validCal = 2;
            break;
        }
        break;
    }
    planPercentage = ((avgVal - price) * 100) / avgVal;
    return { planPercentage: Math.round(planPercentage), validCal: validCal };
  };

  function handleChange(e) {
    const value = e.target.value;
    if (/^(\d*\.{0,1}\d{0,2}$)/.test(value)) {
      let baseVales = calculateBaseVal();
      let savingPercentage = calculateOffPercentage(value, baseVales);
      if (savingPercentage.planPercentage < 0) {
        handleBaseValError(baseVales, savingPercentage.validCal);
      } else if (
        savingPercentage.planPercentage >= 0 ||
        editMembershipValues?.baseValue?._id ===
          editMembershipValues?.membershipId
      ) {
        setDiscountPercentage(savingPercentage.planPercentage);
        setError("");
      } else {
        handleBaseValError(baseVales, savingPercentage.validCal);
      }
    } else {
      setError("Amount Can't Be More Than 2 Decimal Places.");
    }
  }

  return (
    <div className="main-div">
      <div className="heading">
        <div className="title">
          <h6>{`Edit ${
            editMembershipValues?.type
              ? firstLetterUpper(editMembershipValues?.type)
              : ""
          } Membership Plan`}</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button className="btn" onClick={() => setIsEdit(false)}>
            Back
          </button>
        </div>
      </div>

      <Formik
        initialValues={{
          price: editMembershipValues?.price,
          savingPercentage: discountPercentage,
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleEditMembershipSumbit}
      >
        {({ values, setFieldValue }) => {
          useEffect(() => {
            if (discountPercentage) {
              setFieldValue("savingPercentage", discountPercentage);
            } else {
              setFieldValue("savingPercentage", 0);
            }
          }, [discountPercentage || values?.price]);

          return (
            <Form>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-in icon">
                    <label>Amount</label>
                    <div className="f-in">
                      <span className="icon-left">
                        <i className="dollar-icon"></i>
                      </span>
                      <Field
                        type="number"
                        placeholder="Enter Amount"
                        name="price"
                        onKeyUp={handleChange}
                      />
                    </div>
                    {error && (
                      <p className="input-feedback error-msg">{error}</p>
                    )}
                    {!error && (
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-in ">
                    <label>Total Savings</label>
                    <div className="f-in">
                      <span className="icon-left"></span>
                      <Field
                        type="number"
                        placeholder="Enter savings percentage"
                        name="savingPercentage"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="foot pt24">
                <button
                  className="btn"
                  text="Add"
                  type="submit"
                  disabled={!!error}
                >
                  SAVE CHANGES
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditMembership;
