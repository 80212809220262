import React from "react";
import { firstLetterUpper } from "src/helpers";
import RateInfo from "./rateInfo";

const MembershipPlans = ({
  membershipData,
  handleEditMembership,
  handleMembershipStatus,
  key,
}) => {
  const handleName = (text) => {
    let name = "";
    if (text === "GIFTED") {
      name = "Gift";
    } else {
      name = firstLetterUpper(text);
    }
    return name;
  };
  return (
    <div key={key}>
      <h6 className="rate-title">
        <span>
          {membershipData?._id?.name
            ? handleName(membershipData?._id?.name)
            : ""}
          {membershipData?._id?.subType ? (
            <b>- {firstLetterUpper(membershipData?._id?.subType)} </b>
          ) : (
            ""
          )}
          {" Plan"}
        </span>
      </h6>

      <RateInfo
        membershipData={membershipData}
        handleEditMembership={handleEditMembership}
        handleMembershipStatus={handleMembershipStatus}
      />
    </div>
  );
};

export default MembershipPlans;
