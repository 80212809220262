import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { firstLetterUpper } from "src/helpers";
const heading = [
  "By Type",
  "Live",
  "Expired",
  "Lapsed",
  "Cancelled",
  "Lapsed Cancelled",
];

const PaidMembersTab = ({ salesData }) => {
  const { membershipUsersCounts } = salesData;
  const rawMembershipData = { ...membershipUsersCounts } || {};
  delete rawMembershipData.averageCommissionPerUser;
  delete rawMembershipData.totalCommissionAccrued;
  delete rawMembershipData.totalCancelledUsersCount;
  delete rawMembershipData.totalExpiredUsersCount;
  delete rawMembershipData.totalLapsedUsersCount;
  delete rawMembershipData.totalLiveUsersCount;
  delete rawMembershipData.totalLapsedCancelledUsersCount;

  const infoData =
    rawMembershipData &&
    Object.keys(rawMembershipData).length > 0 &&
    Object.keys(rawMembershipData).map((el) => {
      const field = rawMembershipData[el];
      const key = el.includes("gift") ? el.replace("gift", "gift_") : el;
      return {
        ...field,
        name: firstLetterUpper(key),
      };
    });

  return (
    <div className="full light-table yellow-line">
      <Table>
        <TableHead>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {infoData &&
            infoData.length > 0 &&
            infoData.map((item, index) => {
              return (
                <TableRow key={`key_${index + 1}`}>
                  <TableCell>{item?.name || "-"}</TableCell>
                  <TableCell>{item?.liveUsersCount}</TableCell>
                  <TableCell>{item?.expiredUsersCount}</TableCell>
                  <TableCell>{item?.lapsedUsersCount}</TableCell>
                  <TableCell>{item?.cancelledUsersCount}</TableCell>
                  <TableCell>{item?.lapsedCancelledUsersCount}</TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell>TOTAL </TableCell>
            <TableCell>
              <b>{membershipUsersCounts?.totalLiveUsersCount || "0"}</b>
            </TableCell>
            <TableCell>
              <b>{membershipUsersCounts?.totalExpiredUsersCount || "0"}</b>
            </TableCell>
            <TableCell>
              <b>{membershipUsersCounts?.totalLapsedUsersCount || "0"}</b>
            </TableCell>
            <TableCell>
              <b>{membershipUsersCounts?.totalCancelledUsersCount || "0"}</b>
            </TableCell>
            <TableCell>
              <b>
                {membershipUsersCounts?.totalLapsedCancelledUsersCount || "0"}
              </b>
            </TableCell>
          </TableRow>
          {/* put out side the table */}
          <TableRow>
            <TableCell>TOTAL COMMISSION ACCRUED ($) </TableCell>
            <TableCell>
              {membershipUsersCounts?.totalCommissionAccrued || "0"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AVERAGE COMMISSION/USER </TableCell>
            <TableCell>
              {membershipUsersCounts?.averageCommissionPerUser || "-"}
            </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default PaidMembersTab;
