import React, { useMemo, useState } from "react";
import { RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { CommissionPercentage } from "src/constant/global";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import * as Yup from "yup";
import { validNameRegex } from "src/helpers";
import countryList from "react-select-country-list";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  // status: Yup.string().required("Please Enter ImageSource."),
  // commission: Yup.string().required("Please Enter Attribute."),
  accountHolderName: Yup.string()
    .trim()
    .required("Please Enter Account Holder’s Name.")
    .matches(
      validNameRegex,
      "Please Enter a Valid Name. A Name Should Not Contain Special Characters Or Alphanumeric Characters."
    ),
  bankName: Yup.string().trim().required("Please Enter Bank Name."),
  city: Yup.string().trim().required("Please Enter City."),
  accountNumber: Yup.string().trim().required("Please Enter Account Number."),
  branchAddressLane1: Yup.string()
    .trim()
    .required("Please Enter BranchAddressLane1."),
  branchAddressLane2: Yup.string()
    .trim()
    .required("Please Enter BranchAddressLane2."),
  country: Yup.string().trim().required("Please Enter Country."),
  ifsc: Yup.string()
    .trim()
    .required("Please Enter IFSC Code / IBAN / Swift Code."),
  zipCode: Yup.string().trim().required("Please Enter Zip Code."),
});

const EditProfile = ({ setProfileToEdit, profile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSave = (values) => {
    const dataToSend = {
      status: values.status,
      salesAgentId: profile._id,
      commission: values?.commission,
      // bankDetails: {
      //   accountHolderName: values?.accountHolderName || "",
      //   bankName: values?.bankName || "",
      //   city: values?.city || "",
      //   accountNumber: values?.accountNumber || "",
      //   branchAddressLane1: values?.branchAddressLane1 || "",
      //   branchAddressLane2: values?.branchAddressLane2 || "",
      //   country: values?.country || "",
      //   ifsc: values?.ifsc || "",
      //   zipCode: values?.zipCode || "",
      // },
    };

    customAxios
      .patch("admin/sales-agent/edit-profile", {
        ...dataToSend,
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          successAlert(response?.data?.message);
          setProfileToEdit(-1);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const options = useMemo(() => countryList().getData(), []);

  return (
    <div className="main-div">
      <div className="heading bb">
        <h6 className="title">Edit Referral Partner Profile</h6>
        <div className="btn-grp ml-auto">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setProfileToEdit(-1);
            }}
          >
            BACK
          </button>
        </div>
      </div>
      <div className="content-type">
        <div>
          <Formik
            initialValues={
              profile
                ? {
                    status: profile.status,
                    commission: profile?.commission,
                    accountHolderName:
                      profile?.bankDetails?.accountHolderName || "",
                    bankName: profile?.bankDetails?.bankName || "",
                    city: profile?.bankDetails?.city || "",
                    accountNumber: profile?.bankDetails?.accountNumber || "",
                    branchAddressLane1:
                      profile?.bankDetails?.branchAddressLane1 || "",
                    branchAddressLane2:
                      profile?.bankDetails?.branchAddressLane2 || "",
                    country: profile?.bankDetails?.country || "",
                    ifsc: profile?.bankDetails?.ifsc || "",
                    zipCode: profile?.bankDetails?.zipCode || "",
                  }
                : {
                    status: "",
                    commission: "",
                    accountHolderName: "",
                    bankName: "",
                    city: "",
                    accountNumber: "",
                    branchAddressLane1: "string",
                    branchAddressLane2: "string",
                    country: "string",
                    ifsc: "string",
                    zipCode: "string",
                  }
            }
            // validationSchema={validationSchema}
            validateOnChange
            validateOnBlur
            onSubmit={onSave}
          >
            {(props) => {
              const { values, setFieldValue } = props;

              const getDisabledStatus = () => {
                return !!(
                  values &&
                  (values.status === "" || values.commission === "")
                );
              };

              return (
                <Form>
                  <div className="row mb32">
                    <div className="col-md-4">
                      <p className="text-black fw600 fs16">
                        Referral Partner Status
                      </p>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          className="text"
                        >
                          <FormControlLabel
                            control={
                              <Field
                                type="radio"
                                name="status"
                                value="ACTIVE"
                              />
                            }
                            label="Active"
                          />

                          <FormControlLabel
                            control={
                              <Field
                                type="radio"
                                name="status"
                                value="IN_ACTIVE"
                              />
                            }
                            label="Inactive"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <div className="form-in">
                        <p className="text-black fw600 fs16">Commission</p>
                        <div className="f-in">
                          <Select
                            name="commission"
                            id="demo-simple-select"
                            options={CommissionPercentage}
                            defaultValue={CommissionPercentage.find((el) => {
                              return values.commission === el.value;
                            })}
                            onChange={(data) => {
                              setFieldValue("commission", data.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-black fs16 fw600">Bank Details</p>
                  <div className="row">
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Account Holder’s Name </label>
                        <div className="f-in">
                          <Field
                            type="text"
                            name="accountHolderName"
                            disabled
                          />
                          <ErrorMessage
                            name="accountHolderName"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Account Number </label>
                        <div className="f-in">
                          <Field type="text" name="accountNumber" disabled />
                          <ErrorMessage
                            name="accountNumber"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>IFSC Code / IBAN / Swift Code </label>
                        <div className="f-in">
                          <Field type="text" name="ifsc" disabled />
                          <ErrorMessage
                            name="ifsc"
                            component="div"
                            className="input-feedback error-msg"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Bank Name </label>
                        <div className="f-in">
                          <Field type="text" name="bankName" disabled />
                          <ErrorMessage
                            name="bankName"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Branch Address - Line 1 </label>
                        <div className="f-in">
                          <Field
                            type="text"
                            name="branchAddressLane1"
                            disabled
                          />
                          <ErrorMessage
                            name="branchAddressLane1"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Branch Address - Line 2 </label>
                        <div className="f-in">
                          <Field
                            type="text"
                            name="branchAddressLane2"
                            disabled
                          />
                          <ErrorMessage
                            name="branchAddressLane2"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>City </label>
                        <div className="f-in">
                          <Field type="text" name="city" disabled />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Country </label>
                        <div className="f-in">
                          {/* <Field type="text" name="country" /> */}
                          <Select
                            isDisabled
                            options={options}
                            value={
                              values &&
                              values.country &&
                              options.find((el) => {
                                return el.label === values?.country;
                              })
                            }
                            onChange={(data) => {
                              setFieldValue("country", data?.label);
                            }}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb18">
                      <div className="form-in">
                        <label>Zip Code </label>
                        <div className="f-in">
                          <Field type="text" name="zipCode" disabled />
                          <ErrorMessage
                            name="zipCode"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btns-lower">
                    <div>
                      <button
                        className="btn"
                        type="submit"
                        disabled={getDisabledStatus()}
                      >
                        SAVE CHANGES
                      </button>
                    </div>

                    <div>
                      <button
                        className="btn-white"
                        type="button"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        disabled={getDisabledStatus()}
                      >
                        SEND PERSONALIZED EMAIL
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <SEND_PERSONALIZED_EMAIL_MODAL
        isSalesAgent={true}
        open={isOpen}
        setOpen={setIsOpen}
        email={profile?.email}
      />
    </div>
  );
};

export default EditProfile;
