import React from "react";
import { RadioGroup, FormControlLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import FormControl from "@mui/material/FormControl";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  CommissionPercentage as percentage,
  freeMonths,
} from "../../../constant/global";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import moment from "moment-timezone";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  status: Yup.string().trim().required("Please Select Status."),
  type: Yup.string().trim().required("Please Select Type."),
  name: Yup.string().trim().required("Please Enter Name."),
  // discount: Yup.string().trim().required("Please Select Discount."),
  // freeMonths: Yup.string().trim().required("Please Select Discount."),
  code: Yup.string().trim().required("Please Enter Code."),
  startDate: Yup.string().trim().required("Please Select Start Date."),
  endDate: Yup.string().trim().required("Please Select End Date."),
});

const CouponForm = ({ isEdit = false, setAddNew, couponToEdit, setEdit }) => {
  const onSave = (values) => {
    let dataToSent = { ...values };
    dataToSent = {
      ...dataToSent,
      startDate: moment(values?.startDate).startOf("day").format("YYYY-MM-DD"),
      endDate: moment(values?.endDate).endOf("day").format("YYYY-MM-DD"),
    };
    if (dataToSent.type === "PERCENTAGE_DISCOUNT") {
      delete dataToSent.freeMonths;
    } else {
      delete dataToSent.discount;
    }
    if (!isEdit) {
      customAxios
        .post(`admin/add-coupon`, {
          ...dataToSent,
        })
        .then((res) => {
          if (res?.data?.statusCode) {
            successAlert(res?.data?.message || "");
            setAddNew(false);
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(message);
        });
    } else {
      dataToSent = {
        ...dataToSent,
        couponId: dataToSent._id,
      };
      delete dataToSent._id;
      delete dataToSent.stripeId;
      delete dataToSent.createdAt;
      delete dataToSent.updatedAt;
      delete dataToSent.__v;

      console.log("dataToSentdataToSent", dataToSent);

      customAxios
        .put(`admin/update-coupon`, { ...dataToSent })
        .then((res) => {
          if (res?.data?.statusCode) {
            successAlert(res?.data?.message || "");
            setEdit(false);
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(message);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={
          couponToEdit
            ? {
                ...couponToEdit,
                startDate: new Date(couponToEdit.startDate),
                endDate: new Date(couponToEdit.endDate),
              }
            : {
                type: "PERCENTAGE_DISCOUNT",
                name: "",
                code: "",
                status: "",
                discount: "",
                startDate: new Date(),
                endDate: new Date(),
                freeMonths: "",
              }
        }
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSave}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          const getDisabledStatus = () => {
            if (values.stripeId === "") {
              delete values.stripeId;
            }
            return !!(
              values &&
              Object.values(values).some((el) => {
                return el === "";
              })
            );
          };

          return (
            <>
              <Form>
                <div className="content-type">
                  <p className="text-black fw600">Content Type</p>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      className="text"
                    >
                      <FormControlLabel
                        disabled={isEdit && values.type === "EXTENDED_DURATION"}
                        control={
                          <Field
                            type="radio"
                            name="type"
                            value="PERCENTAGE_DISCOUNT"
                          />
                        }
                        label="Percentage Discount"
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="input-feedback error-msg"
                      />
                      <FormControlLabel
                        disabled={
                          isEdit && values.type === "PERCENTAGE_DISCOUNT"
                        }
                        control={
                          <Field
                            type="radio"
                            name="type"
                            value="EXTENDED_DURATION"
                          />
                        }
                        label="Free Months"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="row col-form">
                  <div className="col-md-4">
                    <div className="form-in">
                      <label>Name</label>
                      <div className="f-in">
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          maxLength={20}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-in">
                      <label>Code</label>
                      <div className="f-in">
                        <Field
                          type="text"
                          name="code"
                          placeholder="Enter Code"
                          maxLength={10}
                        />
                      </div>
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    {values && values.type === "PERCENTAGE_DISCOUNT" ? (
                      <>
                        <div className="form-in icon">
                          <label>Discount Value</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="percentage-icon"></i>
                            </span>
                            <Select
                              onChange={(data) => {
                                setFieldValue("discount", data.value);
                                setFieldValue("freeMonths", 1);
                              }}
                              options={percentage}
                              value={
                                percentage.find((option) => {
                                  return option.value === values.discount;
                                }) || null
                              }
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="discount"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </>
                    ) : (
                      <>
                        <div className="form-in">
                          <label>Number of Months to Add On</label>
                          <div className="f-in">
                            <Select
                              onChange={(data) => {
                                setFieldValue("freeMonths", data.value);
                                setFieldValue("discount", 1);
                              }}
                              options={freeMonths}
                              value={
                                freeMonths.find((option) => {
                                  return option.value === values.freeMonths;
                                }) || null
                              }
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="freeMonths"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="form-in date-input">
                      <label>Start Date</label>
                      <div className="f-in">
                        <DatePicker
                          className="date-picker"
                          placeholderText="mm/dd/yyyy"
                          maxDate={(values && values.endDate) || null}
                          // selected={values && values.startDate}
                          selected={
                            values &&
                            new Date(moment(values.startDate).format())
                          }
                          onChange={(date) => {
                            setFieldValue("startDate", date);
                          }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="form-in date-input">
                      <label>End Date</label>
                      <div className="f-in">
                        <DatePicker
                          className="date-picker"
                          selected={
                            (values &&
                              new Date(
                                moment(values.endDate)
                                  .utcOffset(0)
                                  .set({
                                    hour: 0,
                                    minute: 0,
                                    second: 0,
                                    millisecond: 0,
                                  })
                                  .format()
                              )) ||
                            null
                          }
                          // selected={(values && values.endDate) || null}
                          placeholderText="mm/dd/yyyy"
                          minDate={new Date() || null}
                          onChange={(date) => {
                            setFieldValue("endDate", date);
                          }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="form-in content-type">
                      <label>Status</label>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          className="text"
                        >
                          <FormControlLabel
                            control={
                              <Field
                                type="radio"
                                name="status"
                                value="ACTIVE"
                              />
                            }
                            label=" Active"
                          />

                          <FormControlLabel
                            control={
                              <Field
                                type="radio"
                                name="status"
                                value="INACTIVE"
                              />
                            }
                            label=" Inactive"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>
                </div>
                <div className="btns-lower">
                  <button className="btn" disabled={getDisabledStatus()}>
                    {isEdit ? "SAVE CHANGES" : "ADD NEW"}
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CouponForm;
