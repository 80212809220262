import React from "react";
import DataTable from "../Components/DataTable";
import Select from "react-select";
import { StatusOptionsForSales } from "src/constant/global";

const BySource = ({ data, setByStatusWithSource }) => {
  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Source</h6>
        </div>
      </div>
      <div className="select-option">
        <Select
          options={StatusOptionsForSales}
          onChange={(data) => {
            setByStatusWithSource(data.value);
          }}
        />
      </div>
      <DataTable data={data} title="Type" />
    </div>
  );
};

export default BySource;
