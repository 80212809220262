import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorAlert, successAlert } from "src/utils/alerts";

//get profile list

export const getProfileList = createAsyncThunk(
  "admin/sales-agent",
  // Callback Function
  async ({ url, callback }, thunkAPI) => {
    try {
      const response = await axios.get(url);
      callback(response.data);
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong";
      errorAlert(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
