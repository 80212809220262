import React from "react";
import { Dialog, DialogContentText } from "@mui/material";
import DraggbleComp from "../../../../components/common/draggble";
import CancelButton from "../../../../components/common//modal-cancel-btn";
import UpdateCommissionForm from "./UpdateCommissionForm";

const ModalComp = ({ open, setOpen, updateItem, getCommissionPayments }) => {
  return (
    <>
      <Dialog
        open={open}
        // className={classFilter}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={DraggbleComp}
        aria-labelledby="draggable-dialog-title"
      >
        <div className="modal-text basic-modal">
          <div className="close-btn">
            <CancelButton
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>

          <div>
            <DialogContentText className=" fs18 fw400 text-black">
              <UpdateCommissionForm
                item={updateItem}
                setOpen={setOpen}
                getCommissionPayments={getCommissionPayments}
              />
            </DialogContentText>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ModalComp;
