import { createSlice } from "@reduxjs/toolkit";
import { getEditorPickList } from "src/redux/services/editor-pick.service";

const initialState = {
  loading: false,
  editorPickList: [],
};
const editorsPickSlice = createSlice({
  name: "editorPick",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    ///getEditorPickList

    [getEditorPickList.pending]: (state) => {
      state.loading = true;
    },
    [getEditorPickList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.editorPickList = payload.data.data;
      state.message = payload.message;
    },
    [getEditorPickList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
  },
});
export default editorsPickSlice.reducer;
