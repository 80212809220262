import React, { useEffect, useState } from "react";

import Select from "react-select";

import {
  getUploadResponse,
  errorMessage,
  isValidImage,
  isValidUrl,
  isValidFileUploaded,
} from "src/helpers";
import axios from "src/utils/axios";
import CancelButton from "../../../../components/common/modal-cancel-btn";
import { API_MESSAGES } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";

const ViewCard = ({
  refetchEditionDetails,
  editionType,
  type,
  itemData,
  currentEditionId = "",
  cardCount,
  hideCard,
  keyValue,
  categoriesData,
  groupData,
  hidePreview,
  enableAddContentButton,
}) => {
  const [groupIds, setGroupIds] = useState([]);
  const [topicIds, setTopicIds] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [contentData, setContentData] = useState({ ctaText: "READ" });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [error, setError] = useState("");
  const [imgLoader, setImageLoader] = useState(false);
  const [isUrlValid, setValidUrl] = useState(false);
  const [mobileImage, setMobileImage] = useState(null);
  const [topicError, setTopicErrors] = useState("");

  useEffect(() => {
    if (itemData && Object.keys(itemData).length > 0) {
      const newObj = {
        attribute: itemData.attribute,
        ctaText: itemData.ctaText,
        imageSource: itemData.imageSource,
        redirectUrl: itemData.redirectUrl,
        title: itemData.title,
        categoryId: itemData.category?._id,
      };

      setMobileImage(itemData.mobileImage);

      setContentData(newObj);
      setSelectedCategory(itemData.categoryId);

      // let groupIdsArr = []
      // let topicIdsArr  = [];
      //  itemData?.groups.length > 0 ? itemData?.groups.forEach((item) => {
      //   const itemObj = groupData && groupData.length > 0 && groupData.find((obj) => obj._id === item.groupId)

      //   groupIdsArr.push({ _id: itemObj._id, name: itemObj.name})

      //  if( itemData?.topics.length > 0 ){
      //   itemData?.topics.forEach((itemTopic) => {
      //     const filterObj = itemObj?.topics && itemObj?.topics.length > 0 && itemObj?.topics.find((top) => top._id === itemTopic.topicId )
      //     topicIdsArr.push({_id: filterObj._id, name: filterObj.name})

      //   })
      //   }

      // }) : []
      const groupIdsArr =
        itemData?.groups?.length > 0 &&
        itemData?.groups.map((item) => {
          return { groupId: item._id };
        });

      const topicIdsArr =
        itemData?.topics?.length > 0 &&
        itemData?.topics?.map((item) => {
          return { topicId: item._id };
        });

      setGroupIds(groupIdsArr);
      setTopicIds(topicIdsArr);
      setCardId(itemData._id);

      let topicData = [];
      itemData?.groups?.forEach((el) => {
        const data = groupData.find((item) => {
          return el._id === item._id;
        });
        if (data) {
          topicData.push(
            ...data?.topics.map((topic) => {
              return {
                ...topic,
                groupName: el.name,
              };
            })
          );
        }
      });

      setTopicsList(topicData);
    }
  }, [itemData]);

  const handleAddContentSubmit = () => {
    let data = {
      ...contentData,
      mobileImage,
    };

    if (type === "add") {
      data = {
        ...data,
        editionId: currentEditionId,
      };

      if (topicIds && topicIds.length > 0) {
        data = {
          ...data,
          topics: topicIds,
        };
      }
      if (groupIds && groupIds.length > 0) {
        data = {
          ...data,
          groups: groupIds,
        };
      }

      if (!isWeekday) {
        delete data["topics"];
        delete data["groups"];
        delete data["categoryId"];
      }

      axios
        .post(`admin/edition/add-card`, data)
        .then((res) => {
          if (res?.data?.statusCode) {
            successAlert(res?.data?.message || "");
            hidePreview();
            refetchEditionDetails();
            enableAddContentButton();
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }

          errorAlert(message || API_MESSAGES.getError);
        });
    } else {
      data = {
        ...data,
        cardId,
      };

      if (topicIds && topicIds.length > 0) {
        data = {
          ...data,
          topics: topicIds,
        };
      } else {
        data = {
          ...data,
          topics: [],
        };
      }
      if (groupIds && groupIds.length > 0) {
        data = {
          ...data,
          groups: groupIds,
        };
      } else {
        data = {
          ...data,
          groups: [],
        };
      }

      if (!isWeekday) {
        delete data["topics"];
        delete data["groups"];
        delete data["categoryId"];
      }

      axios
        .patch(`admin/edition/edit-card`, data)
        .then((res) => {
          if (res?.data?.statusCode) {
            successAlert(res?.data?.message || "");
            hidePreview();
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }

          errorAlert(message || API_MESSAGES.getError);
        });
    }
  };

  const isWeekday = editionType === "WEEKDAY";

  const getDisabledStatus = () => {
    if (isWeekday) {
      return (
        !contentData.categoryId ||
        !contentData.attribute ||
        !contentData.ctaText ||
        !contentData.redirectUrl ||
        !contentData.imageSource ||
        !contentData.title ||
        !currentEditionId ||
        !mobileImage ||
        isUrlValid
      );
    } else {
      return (
        !contentData.attribute ||
        !contentData.ctaText ||
        !contentData.redirectUrl ||
        !contentData.imageSource ||
        !contentData.title ||
        !currentEditionId ||
        !mobileImage ||
        isUrlValid
      );
    }
  };

  const handleDeleteCard = () => {
    if (itemData?._id) {
      axios
        .delete(`admin/edition/delete-card/${itemData?._id}`)
        .then((res) => {
          if (res?.data?.statusCode) {
            successAlert(res?.data?.message || "");
            refetchEditionDetails();
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }

          errorAlert(message || API_MESSAGES.getError);
        });
    }
  };

  return (
    <div key={cardCount}>
      <section className="main-div mt24">
        <div className="heading bb">
          <div className="title">
            <h6>Content Piece #{cardCount}</h6>
          </div>

          <div className="ml-div">
            <span className="delete-btn">
              <CancelButton
                onClose={() => {
                  if (type === "add") {
                    hideCard(keyValue);
                  } else {
                    handleDeleteCard();
                  }
                }}
              />
            </span>
          </div>
        </div>

        <div className="content-piece">
          <div className="pr30">
            <div className="content-img">
              <div className={`img-upload ${mobileImage ? "uploaded" : ""}`}>
                {imgLoader && (
                  <div className="spinner">
                    <span></span>
                  </div>
                )}
                {mobileImage && (
                  <span className="delete-img">
                    <CancelButton
                      onClose={() => {
                        setMobileImage(null);
                      }}
                    />
                  </span>
                )}
                {mobileImage ? (
                  <img src={mobileImage} />
                ) : (
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={async (e) => {
                      if (e.target.files && e.target.files[0]) {
                        let img = e.target.files[0];
                        const height = 625;
                        const width = 500;
                        setImageLoader(true);
                        const isValidExtension = isValidFileUploaded(img);

                        if (isValidExtension) {
                          const isValid = await isValidImage({
                            file: img,
                            height,
                            width,
                          });
                          if (isValid) {
                            const res = await getUploadResponse(img);
                            setMobileImage(res);
                            setError("");
                          } else {
                            const message = `Image Height And Width Must Be ${width}px * ${height}px And Size Should Be Less Than 3 MB.`;
                            setError(message);
                          }
                        } else {
                          setError("File Format Not Supported");
                        }
                        setImageLoader(false);
                      }
                    }}
                  />
                )}
                <div className="text">
                  <span className="icon icon-upload">
                    <i className="upload-icon"></i>
                  </span>
                  <p>Upload from gallery</p>
                  <p>(500x625)</p>
                  <p className="accept-img">
                    (accepts .png .jpeg .jpg images only)
                  </p>
                </div>
              </div>
              <div name="mobileImage" className="input-feedback error-msg">
                {error}
              </div>
            </div>
          </div>

          <div className="fb-1">
            <div className="row">
              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Content Category </label>
                    <div className="f-in">
                      <Select
                        value={
                          categoriesData &&
                          categoriesData.length > 0 &&
                          categoriesData.find(
                            (cat) => cat._id === selectedCategory
                          )
                        }
                        onChange={(val) => {
                          setSelectedCategory(val);
                          setContentData({
                            ...contentData,
                            categoryId: val?._id,
                          });
                        }}
                        options={
                          categoriesData && categoriesData.length > 0
                            ? categoriesData
                            : []
                        }
                        getOptionLabel={(val) => {
                          return val?.name;
                        }}
                        getOptionValue={(val) => {
                          return val?._id;
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="form-in">
                  <label>Attribution Text (20 Char) </label>
                  <div className="f-in">
                    <input
                      maxLength={20}
                      value={contentData?.attribute}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          attribute: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Attribution text here"
                    />
                  </div>
                </div>
              </div>

              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>CTA for Mobile </label>
                    <div className="f-in">
                      <select
                        value={contentData?.ctaText}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            ctaText: e.target.value,
                          });
                        }}
                      >
                        <option value="READ">READ</option>
                        <option value="VIEW">VIEW</option>
                        <option value="LISTEN">LISTEN</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Redirection URL ( Max 1000 Char) </label>
                    <div className="f-in">
                      <input
                        maxLength={1000}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            redirectUrl: e.target.value,
                          });
                          setValidUrl(false);
                        }}
                        type="text"
                        value={contentData?.redirectUrl}
                      />
                      <a
                        className="icon-right"
                        onClick={() => {
                          if (contentData?.redirectUrl) {
                            window.open(contentData?.redirectUrl, "_blank");
                            const checkValidUrl = !isValidUrl(
                              contentData.redirectUrl
                            );
                            if (checkValidUrl) {
                              setValidUrl(true);
                            }
                          }
                        }}
                      >
                        <p className="text-blue">TEST</p>
                      </a>
                      {isUrlValid && (
                        <div
                          name="mobileImage"
                          className="input-feedback error-msg"
                        >
                          Please Enter Valid URL
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <div className="form-in">
                  <label>Image Source (15 Char)</label>
                  <div className="f-in">
                    <input
                      value={contentData?.imageSource}
                      maxLength={15}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          imageSource: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Image Source"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-in">
                  <label>Title Text (250 Char)</label>
                  <div className="f-in">
                    <textarea
                      rows="3"
                      maxLength={250}
                      style={{ resize: "none" }}
                      value={contentData?.title}
                      onChange={(e) => {
                        setContentData({
                          ...contentData,
                          title: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>

              {isWeekday && (
                <div className="col-md-12">
                  <div className="form-in multi-select">
                    <label>Group (optional)</label>
                    <div className="f-in">
                      <Select
                        isMulti
                        isClearable
                        onChange={(data) => {
                          let topicData = [];
                          const groupData = data.map((el) => {
                            return {
                              groupId: el._id,
                            };
                          });

                          data.forEach((el) => {
                            topicData.push(
                              ...el.topics.map((data) => {
                                return {
                                  ...data,
                                  groupName: el.name,
                                };
                              })
                            );
                          });
                          setTopicsList(topicData);
                          setGroupIds(groupData);

                          const updatedTopicIds = topicIds.filter((el) => {
                            const filteredTopicIds = topicData.find((data) => {
                              return data._id === el.topicId;
                            });
                            return filteredTopicIds ? true : false;
                          });

                          setTopicIds(updatedTopicIds);
                          if (updatedTopicIds && updatedTopicIds.length > 0) {
                            const errorForTopic =
                              updatedTopicIds.length !== groupData.length;
                            setTopicErrors(
                              errorForTopic
                                ? "Please Select Topics From All Groups"
                                : ""
                            );
                          } else {
                            setTopicErrors("");
                          }
                        }}
                        value={
                          groupIds &&
                          groupIds.length > 0 &&
                          groupIds.map((option) => {
                            const data = groupData.find((el) => {
                              return el._id === option.groupId;
                            });
                            return data;
                          })
                        }
                        options={groupData}
                        getOptionLabel={(value) => {
                          return value.name;
                        }}
                        getOptionValue={(value) => {
                          return value._id;
                        }}
                        isDisabled={!currentEditionId}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isWeekday && (
                <div className="col-md-12">
                  <div className="form-in multi-select">
                    <label>Topics (optional)</label>
                    <Select
                      isMulti
                      isClearable
                      isDisabled={!currentEditionId}
                      // isDisabled={topicList.length === 0 || !isEdit}
                      onChange={(data) => {
                        const topicData = data.map((el) => {
                          return {
                            topicId: el._id,
                          };
                        });
                        setTopicIds(topicData);
                        if (data.length > 0) {
                          const groups = data
                            .map((el) => el.groupId)
                            .filter((item, i, ar) => ar.indexOf(item) === i);
                          const errorTopic = groups.length !== groupIds.length;
                          setTopicErrors(
                            errorTopic
                              ? "Please Select Topics From All Groups"
                              : ""
                          );
                        } else {
                          setTopicErrors("");
                        }
                      }}
                      options={topicsList}
                      getOptionLabel={(value) => {
                        return `${value.name} (${value.groupName})`;
                      }}
                      getOptionValue={(value) => {
                        return value._id;
                      }}
                      value={
                        topicIds &&
                        topicIds.length > 0 &&
                        topicIds.map((option) => {
                          const data = topicsList.find((el) => {
                            return el._id === option.topicId;
                          });
                          return data;
                        })
                      }
                    />
                    {topicError && (
                      <div
                        name="mobileImage"
                        className="input-feedback error-msg"
                      >
                        {topicError}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {!isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>CTA for Mobile </label>
                    <div className="f-in">
                      <select
                        value={contentData?.ctaText}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            ctaText: e.target.value,
                          });
                        }}
                      >
                        <option value="READ">Read</option>
                        <option value="VIEW">View</option>
                        <option value="LISTEN">Listen</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {!isWeekday && (
                <div className="col-md-6">
                  <div className="form-in">
                    <label>Redirection URL ( Max 1000 Char) </label>
                    <div className="f-in">
                      <input
                        maxLength={1000}
                        onChange={(e) => {
                          setContentData({
                            ...contentData,
                            redirectUrl: e.target.value,
                          });
                          setValidUrl(false);
                        }}
                        type="text"
                        value={contentData?.redirectUrl}
                      />
                      <a
                        className="icon-right"
                        onClick={() => {
                          if (contentData?.redirectUrl) {
                            window.open(contentData?.redirectUrl, "_blank");
                            const checkValidUrl = !isValidUrl(
                              contentData.redirectUrl
                            );
                            if (checkValidUrl) {
                              setValidUrl(true);
                            }
                          }
                        }}
                      >
                        <p className="text-blue">TEST</p>
                      </a>
                    </div>
                    {isUrlValid && (
                      <div
                        name="mobileImage"
                        className="input-feedback error-msg"
                      >
                        Please Enter Valid URL
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="btns-lower">
          <div>
            <button
              className="btn long"
              disabled={getDisabledStatus() || topicError}
              onClick={() => {
                handleAddContentSubmit();
              }}
            >
              Save Content Piece
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewCard;
