import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { firstLetterUpper } from "src/helpers";

const heading = [
  {
    id: 1,
    title: "Title",
  },
  {
    id: 2,
    title: "From Start",
  },
  {
    id: 3,
    title: "Count",
  },
];

const DataTable = ({ data, title = "" }) => {
  const tableData =
    (data &&
      data.fromStart &&
      Object.keys(data.fromStart).length > 0 &&
      Object.keys(data.fromStart).map((el) => {
        const field = data.fromStart[el];
        const key = el
          .replace("Users", " ")
          .replace("Program", "_Program")
          .replace("eastJapanAndChina", "east_Japan_And_China")
          .replace("restOfSubcontinent", "rest_Of_Subcontinent")
          .replace("gift", "gift-_")
          .replace("middleEastAndAfrica", "middle_East_And_Africa");

        return {
          name: firstLetterUpper(key),
          fromStartCount: field,
          count: data?.count ? data?.count[el] : "-",
        };
      })) ||
    [];

  return (
    <div className="light-table full table-sec">
      <Table>
        <TableRow>
          {heading &&
            heading.length > 0 &&
            heading.map((h, index) => {
              return (
                <TableCell align="left" key={`key_${index + 1}`}>
                  {title && h?.title === "Title" ? title : h?.title}
                </TableCell>
              );
            })}
        </TableRow>
        <TableBody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item, index) => {
              return (
                <TableRow key={`key_${index + 1}`}>
                  <TableCell>{item.name || "-"}</TableCell>
                  <TableCell>{item.fromStartCount || "0"}</TableCell>
                  <TableCell>{item.count || "0"}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
