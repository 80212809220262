import React, { useRef, useState } from "react";
import axios from "axios";
import moment from "moment-timezone";

import { successAlert, errorAlert } from "src/utils/alerts";
import Loader from "src/components/common/loaders";
import appConfig from "src/appConfig";
import { errorMessage, getSessionStorage } from "src/helpers";
import { useDispatch, useSelector } from "react-redux";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import {
  getComplimentaryMemberList,
  getComplimentaryMemberListFilter,
} from "src/redux/services/complementaryMembership.service";
import CustomCarousel from "src/components/common/Carousel";
import ComplimentaryMemberList from "./components/ComplimentaryMemberList";

import Filters from "./components/ComplimentaryFilters";
import AddNewMembership from "./components/AddNewMembership";
import MembershipSearch from "./components/MembershipSearch";
import ComplimentaryDetail from "./components/ComplimentaryDetail";

const InitialFilterData = {
  name: "",
  email: "",
  customerId: "",
};

const ComplimentaryMembership = () => {
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState({});

  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(InitialFilterData);
  const [isSearch, setIsSearch] = useState(false);

  const addRef = useRef(null);
  const listRef = useRef(null);
  const { data, loading, filteredData } = useSelector(
    (state) => state?.complimentaryMembership
  );
  React.useEffect(() => {
    fetchComplimentaryMemberList();
  }, [curPage, filteredData]);

  const fetchComplimentaryMemberList = () => {
    let url = `admin/complimentary-membership/list`;
    dispatch(
      getComplimentaryMemberList({
        url,
        data: {
          limit: MAX_PAGE_LIMIT,
          page: curPage,
        },
        callback: (res) => {
          const totalPagesCount =
            res?.data?.total && res?.data?.total > 0
              ? Math.ceil(res.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        },
      })
    );
  };

  const handleView = (id, member) => {
    setOpen(true);
    setDetail(member);
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key !== "customerId"
        ? event.target.value.toLowerCase()
        : event.target.value.toUpperCase();

    setFilterData({
      ...filterData,
      [key]: value.trim(),
    });
  };

  const handleSearch = () => {
    const isEmpty = Object.values(filterData).every(
      (x) => x === null || x === ""
    );
    if (!isEmpty) {
      const data = {
        ...filterData,
      };
      setIsSearch(true);
      dispatch(
        getComplimentaryMemberListFilter({
          ...data,
        })
      );
    }
  };

  const handleMembershipSubmit = (values, { resetForm }) => {
    const token = getSessionStorage("authToken") || "";

    let timeZoneData = "";
    try {
      timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (err) {
      console.log(err);
    }


    const options = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token || ""}`,
        deviceid: "abc",
        platform: "3",
        timezone: timeZoneData || "Asia/Dubai"
      },
    };

    axios
      .post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/complimentary-membership/add`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          contactNumber: values.contactNo,
          countryCode: values.countryCode,
          startDate:  moment(values.startDate).startOf("day").format(),
          endDate:  moment(values.endDate).startOf("day").format(),
        },
        options
      )
      .then((res) => {
        if (res && res.data && res?.data?.data) {
          fetchComplimentaryMemberList();
          resetForm();
          handleScroll(listRef);
          successAlert(res?.data?.message);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data?.message) {
          message = errorMessage(err);
        }
        errorAlert(message);
      });
  };

  

  const handleScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const membershipCount = (count) => {
    let countMessage;
    if (count > 1) {
      countMessage = `${count} Ongoing Memberships`;
    } else if (count > 0) {
      countMessage = `${count} Ongoing Membership`;
    } else {
      countMessage = `${count} Ongoing Membership`;
    }
    return countMessage;
  };

  const clearFilterData=()=>{
    if(isSearch){
      setIsSearch(false);
      setFilterData(InitialFilterData);

    }

  }

  return (
    <>
      {open && (
        <ComplimentaryDetail
          memberData={detail}
          open={open}
          setOpen={setOpen}
        />
      )}
      {loading && <Loader />}

      <div className="main-div h-auto slider-over">
        <div className="heading ">
          <div className="title">
            <h6 className="">View Details</h6>
          </div>
        </div>

        <Filters onChange={handleChange} filterData ={filterData} onSearch={handleSearch}  clearFilterData ={clearFilterData}/>

        {/* search result component  */}
        {isSearch && filteredData?.total === 0 && (
          <div className="no-content">
            <h6>No Complimentary Membership Data</h6>
            <p>
              Search complimentary membership by Email, Name,
              <br /> and Customer ID
            </p>
          </div>
        )}
        <>
          {isSearch &&
            filteredData?.total !== 0 &&
            (filteredData?.total > 1 ? (
              <CustomCarousel>
                {filteredData?.data?.length > 1 &&
                  filteredData?.data.map((item) => {
                    return (
                      <MembershipSearch
                        key={item?._id}
                        memberData={item}
                        handleSearch={handleSearch}
                        fetchComplimentaryMemberList={
                          fetchComplimentaryMemberList
                        }
                      />
                    );
                  })}
              </CustomCarousel>
            ) : (
              <MembershipSearch
                memberData={
                  filteredData?.data?.length > 0 && filteredData?.data[0]
                }
                handleSearch={handleSearch}
                fetchComplimentaryMemberList={fetchComplimentaryMemberList}
              />
            ))}
        </>
        {/* )} */}
      </div>

      <div className="main-div h-auto mt24">
        <div ref={listRef}>
          <div className="heading ">
            <div className="title">
              <h6 className="fs16 fw600">Complimentary Membership</h6>
            </div>

            <div>
              <p className="btn-label">
                {membershipCount(data?.total > 0 ? data?.total : "0")}
              </p>
            </div>

            <div className="ml-div">
              <button className="btn" onClick={() => handleScroll(addRef)}>
                ADD NEW
              </button>
            </div>
          </div>

          {/* search component ends here */}

          <ComplimentaryMemberList
            membershipData={data}
            curPage={curPage}
            setCurrPage={setCurrPage}
            totalCount={totalCount}
            fetchComplimentaryMemberList={fetchComplimentaryMemberList}
            handleView={handleView}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className="main-div h-auto mt24">
        <div ref={addRef}>
          <AddNewMembership handleMembershipSubmit={handleMembershipSubmit} />
        </div>
      </div>
    </>
  );
};

export default ComplimentaryMembership;
