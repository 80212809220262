import { createSlice } from "@reduxjs/toolkit";
import {
  getComplimentaryMemberList,
  getComplimentaryMemberListFilter,
} from "../../services/complementaryMembership.service";

const initialState = {
  loading: false,
  message: "",
  data: [],
  filteredData: [],
  isImagesDragged: false,
};

const complimentaryMembershipSlice = createSlice({
  name: "complimentaryMembership",
  initialState,
  extraReducers: {
    // setContent
    [getComplimentaryMemberList.pending]: (state) => {
      state.loading = true;
    },
    [getComplimentaryMemberList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data;
      state.message = payload?.data?.message;
    },
    [getComplimentaryMemberList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.data = null;
    },

    [getComplimentaryMemberListFilter.pending]: (state) => {
      state.loading = true;
    },
    [getComplimentaryMemberListFilter.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.filteredData = payload?.data;
      state.message = payload?.data?.message;
    },
    [getComplimentaryMemberListFilter.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.filteredData = null;
    },
  },
});

export default complimentaryMembershipSlice.reducer;
