import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";
import { splitNameAndCapitalize } from "../../../helpers";

const Totals = ({
  isPayments,
  monthYear,
  setMonthYear,
  data = {},
  setPaymentData,
}) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setPaymentData({
      ...data,
      difference: data.systemBilling - value,
      stripeCharges: value,
    });
  };

  return (
    <div className="col-md-6">
      <div className="main-div h-auto">
        <div className="heading">
          <div className="title">
            <h6 className="fs16">{`Total ${
              isPayments ? "Payments" : "Billings"
            } `}</h6>
          </div>

          <div className="ml-auto date-input">
            <div className="ml-auto date-filter">
              <DatePicker
                selected={monthYear}
                onChange={(date) => setMonthYear(date)}
                dateFormat="MMM yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
        </div>

        <div
          className={`light-table full table-sec ${
            isPayments ? "yellow-line" : ""
          }`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>{`${
                  isPayments
                    ? "USD"
                    : `${monthYear && moment(monthYear).format("MMM YYYY")}`
                } `}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(data).map((key, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {isPayments && key === "difference" ? (
                      <b>{splitNameAndCapitalize(key)}</b>
                    ) : (
                      splitNameAndCapitalize(key)
                    )}
                  </TableCell>
                  <TableCell>
                    {isPayments && key === "stripeCharges" ? (
                      <input
                        min={0}
                        type="number"
                        className="sm-input"
                        value={data[key].toString()}
                        onChange={handleChange}
                      />
                    ) : (
                      data[key]
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Totals;
