import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// LOCAL IMPORTS

import Alert from "src/components/common/Alerts";
import { isNullEmptyOrUndefined } from "src/helpers";
import { forgotPassword } from "src/redux/services/auth.service";
import { setUserEmail } from "src/redux/features/authSlice";
import SieveLogo from "src/assets/images/Sieve-logo-black.svg";

// VALIDATION REQUIRES
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please Enter Valid Email")
    .required("Please Enter Your Email."),
});

const ForgotPassword = () => {
  const [showAlert, setShowAlert] = useState(null);
  // DISPATCH AN ACTION
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { message, status } = useSelector((state) => state.auth);

  //  HANDLE SUBMIT
  const handleSubmit = (values, { setSubmitting }) => {
    const { email } = values;
    if (!isNullEmptyOrUndefined(email)) {
      dispatch(setUserEmail(email));
      dispatch(
        forgotPassword({
          email,
          callback: (res) => {
            if (res) {
              if (res?.statusCode === 200) {
                navigate("/verify-otp");
              }
            }
          },
        })
      );
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Form>
              <section className="login">
                <button
                  className="null-btn back-btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <span className="icon">
                    <i className="back-icon"></i>{" "}
                  </span>
                  Back
                </button>
                <div className="wrapper">
                  <div className="text">
                    <div className="head">
                      <figure className="logo">
                        <img src={SieveLogo} alt="sieveLogo" />
                      </figure>

                      <p className="txt">
                        Expertly curated insights <br /> for the next generation
                        of business
                      </p>
                    </div>

                    <div className="body">
                      <h6 className="font-old title fs20">Forgot Password?</h6>
                      <p className="text-center mb24">
                        Enter the email associated with your account and <br />{" "}
                        we’ll send an OTP to reset your password.
                      </p>

                      <form>
                        <div className="form-in icon">
                          <label>Email</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="mail-icon"></i>
                            </span>
                            <Field
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </form>
                    </div>

                    <div className="foot">
                      <div>
                        <button
                          type="submit"
                          className="btn single"
                          onClick={() => {}}
                        >
                          SEND
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          </>
        )}
      </Formik>
      {showAlert && (
        <Alert
          message={message}
          type={status === 500 ? "error" : "success"}
          variant="filled"
          onClose={() => setShowAlert(null)}
          open={showAlert ? true : false}
          vertical="top"
          horizontal="right"
        />
      )}
    </div>
  );
};
export default ForgotPassword;
