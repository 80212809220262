import React from "react";
import DataTable from "../Components/DataTable";

const BySource = ({ data }) => {
  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Source</h6>
        </div>
      </div>
      <DataTable data={data} />
    </div>
  );
};

export default BySource;
