import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GeographyOptions } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ReportFreeTable from "../components/ReportFreeTable";
const heading = ["Registered", "Converted", "Expired"];

const All_Free_Trial_SignUps = ({
  setLoading,
  startDate = null,
  endDate = null,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [freeTrialSignUps, setFreeTrialSignUps] = useState();
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);

  useEffect(() => {
    getFreeTrialSignUps();
  }, [byGeography]);

  useEffect(() => {
    getFreeTrialSignUps();
  }, [endDate, startDate]);

  const getFreeTrialSignUps = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/all-free-trial-signups?byGeography=${byGeography}&isSendEmailReport=${isSendEmailReport}`;

    if (startDate !== null && endDate !== null) {
      url =
        url +
        `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setFreeTrialSignUps(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getFreeTrialSignUps(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">All Free Trial Sign-Ups</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          options={GeographyOptions}
          defaultValue={GeographyOptions[0]}
          onChange={(data) => {
            setGeography(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              allFreeTrialByGeography: data.value,
            });
          }}
        />
      </div>
      <div>
        <ReportFreeTable heading={heading} data={freeTrialSignUps} />
      </div>
    </div>
  );
};

export default All_Free_Trial_SignUps;
