import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { firstLetterUpper } from "src/helpers";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";
import Pagination from "@mui/material/Pagination";
import { MAX_PAGE_LIMIT } from "src/constant/global";

const heading = ["Sr. No.", "Customer ID", "Name", "Email ID", "Status"];

const InactiveUsers = ({
  totalCount,
  curPage,
  data,
  setCurrPage,
  getReferralComplementaryAndInActiveUserInfo,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const sendReportOnMail = () => {
    getReferralComplementaryAndInActiveUserInfo("INACTIVE_USERS");
  };

  return (
    <>
      <div className="heading">
        <div className="title">
          <h6 className="fw600">Inactive Users</h6>
        </div>
        <div className="ml-auto btn-grp">
          <button
            className="btn"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Send Email
          </button>
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="light-table full table-sec">
        <Table>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>{item?.customerId}</TableCell>
                    <TableCell>{item?.firstName}</TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>
                      <span
                        className={`status-label fill-grey  ${
                          item.status === "ACTIVE" ? "fill-green" : ""
                        }`}
                      >
                        {item.status ? firstLetterUpper(item.status) : "-"}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      {data && data?.length === 0 && (
        <div className="no-content">
          <h6>No In-Active Uers</h6>
        </div>
      )}
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        type={"INACTIVE_USERS"}
      />
    </>
  );
};

export default InactiveUsers;
