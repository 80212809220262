import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import { Dialog, Chip } from "@mui/material";
import Select from 'react-select';

import axios from "src/utils/axios";
import CancelButton from "src/components/common/modal-cancel-btn";
import { errorMessage } from "src/helpers";
import { API_MESSAGES, VALIDATION_MESSAGES } from "src/constant/global";

export default function AddTopicGroup(props) {
  const { open, setOpen, refetchData, groupData } = props;
  const [topic, setTopic] = useState("");
  const [topicData, setTopicData] = useState([]);
  const [error, setError] = useState(false);
  const [groupId, setGroupId] = useState("");

  const handleDelete = (name) => {
    const newTopic =
      topicData &&
      topicData.length > 0 &&
      topicData.filter((topic) => topic.name !== name);
    setTopicData(newTopic || []);
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handleAddTopic = () => {
    if (topicData && topicData.length > 0 && groupId) {
      axios
        .post("admin/addTopics", {
          topics: [...topicData],
          groupId: groupId,
        })
        .then(() => {
          setOpen(false);
          refetchData(true, API_MESSAGES.addTopicSuccess);
        })
        .catch((error) => {
          setOpen(false);
          let message = "";

          if (error?.response && error?.response?.data) {
            message = errorMessage(error);
          }

          refetchData(false, message || API_MESSAGES.addTopic);
        });
    }
  };

  const handleAdd = () => {
   
    const newTopicData = [...topicData];
    if (topic && topic.trim()) {
      const data = { name: topic.trim() };
      newTopicData.push(data);
      setTopicData(newTopicData);
      setError(false)
      setTopic("");
    }
  };

  return (
    <Dialog
      open={open}
      style={{ padding: "50px" }}
      onClose={() => setOpen(false)}
    >
      <div className="modal-text">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="heading flex-center">
          <h6>Add New Topic</h6>
        </div>
        <div className="text pb24">
          <div className="form-in">
            <label> Select Group </label>
            <Select
              onChange={(val)=>{
                setGroupId(val?._id)
              }}
              options={groupData &&
                groupData.length > 0 &&
                groupData}
              getOptionLabel={(val) => { return val?.name }}
              getOptionValue={(val) => { return val?._id }}

            />
          </div>
        </div>

        <div className="text pb24">
          <div className="form-in topic-add">
            <label>Add Topic (Max 25 Char)</label>
            <div className="f-in ">
              
                <input
                  maxLength={25}
                  type="text"
                  onBlur={() => {
                    if (!topic && topicData.length === 0) {
                      setError(true);
                    }
                  }}
                  onFocus={() => {
                    if (error) {
                      setError(false);
                    }
                  }}
                  placeholder="Add New Topic Name Here"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}

                />
        
              <button className="btn" text="+" onClick={() => handleAdd()}>
                +
              </button>
            </div>
            {error && (
              <span className="error-msg">
                {VALIDATION_MESSAGES.topicInput}
              </span>
            )}
          </div>
        </div>


        <div className="topic-chips pb24">
          <div className="form-in">
            {topicData && topicData.length>0 &&<label> Topic</label>}
            <div className="chip-list">
              {topicData && topicData.length>0 && topicData.map((data) => {
                let icon;

                return (
                  <ListItem key={data.name}>
                    <Chip
                      icon={icon}
                      label={data.name}
                      onDelete={() => {
                        handleDelete(data.name);
                      }}
                    />
                  </ListItem>
                );
              })}
            </div>
          </div>
        </div>

        <div className="foot">
          <button
            disabled={groupId && topicData && topicData.length>0 ? false:  true}
            className="btn"
            text="Save Group"
            onClick={() => handleAddTopic()}
          >
            SAVE TOPICS
          </button>
        </div>
      </div>
    </Dialog>
  );
}
