import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment-timezone";
import Select from "react-select";

const listMembershipStatusOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "RENEWAL",
    value: "RENEWAL",
  },
];

const listMembershipTypeOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "STUDENT",
    value: "STUDENT",
  },
  {
    label: "PROFESSIONAL",
    value: "PROFESSIONAL",
  },
  {
    label: "GIDTED_STUDENT",
    value: "GIDTED_STUDENT",
  },
  {
    label: "GIFTED_PROFESSIONAL",
    value: "GIFTED_PROFESSIONAL",
  },
];

import DateRangePicker from "src/components/DateRangePicker";
const heading = [
  "By Referral Partner",
  "Enroll DT",
  "Live",
  "Expired",
  "Lapsed",
  "Cancelled",
  "Lapsed Cancelled",
];
const SalesAgentsCountsListForMembershipUsers = ({
  data,
  getSalesAgentDetail,
  listStartDate,
  setListStartDate,
  listEndDate,
  setListEndDate,
  signupsEndDate,
  signupsStartDate,
}) => {
  const { sales } = data || {};
  const [curPage, setCurrPage] = useState(1);
  const [selectedType, setSelectedType] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const selectStartDate = (d) => {
    let check = d !== null && listEndDate !== null;
    if (d > listEndDate) {
      setListEndDate(null);
      check = false;
    }
    setListStartDate(d);
    if (check) {
      getSalesAgentDetail({
        type: "PAID",
        listStartDate: d,
        listEndDate: listEndDate,
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  const selectEndDate = (d) => {
    setListEndDate(d);
    if (listStartDate !== null && d !== null) {
      getSalesAgentDetail({
        type: "PAID",
        listStartDate: listStartDate,
        listEndDate: d,
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  const clearDates = () => {
    setListEndDate(null);
    setListStartDate(null);
    if (listStartDate !== null && listEndDate !== null) {
      getSalesAgentDetail({
        type: "PAID",
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  const handleStatusChange = (data) => {
    const type = "PAID";
    setSelectedStatus(data.value);
    getSalesAgentDetail({
      type,
      listStartDate,
      listEndDate,
      listMembershipStatus: data.value,
      signupsStartDate,
      signupsEndDate,
      listMembershipType: selectedType ? selectedType : null,
    });
  };

  const handleTypeChange = (data) => {
    setSelectedType(data.value);
    const type = "PAID";
    getSalesAgentDetail({
      type,
      listStartDate,
      listEndDate,
      listMembershipType: data.value,
      listMembershipStatus: selectedStatus ? selectedStatus : null,
      signupsStartDate,
      signupsEndDate,
    });
  };

  return (
    <div
      className="main-div h-auto"
      style={{
        marginTop: "50px",
      }}
    >
      <div className="heading status-refferal">
        <div className="select-option lg">
          <Select
            options={listMembershipTypeOptions}
            defaultValue={listMembershipTypeOptions[0]}
            onChange={handleTypeChange}
          />
          <Select
            options={listMembershipStatusOptions}
            defaultValue={listMembershipStatusOptions[0]}
            onChange={handleStatusChange}
          />
        </div>

        <div className="form-in date-filter ml-auto">
          <DateRangePicker
            setCurrPage={setCurrPage}
            selectStartDate={selectStartDate}
            selectEndDate={selectEndDate}
            startDate={listStartDate}
            endDate={listEndDate}
            disableEndDate={!listStartDate}
          />
          <button
            className="btn-white ml12"
            onClick={() => {
              clearDates();
            }}
          >
            Clear
          </button>
        </div>
      </div>

      <div className="full light-table yellow-line">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sales &&
              sales.length > 0 &&
              sales.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.firstName}</TableCell>
                    <TableCell>
                      {item?.approvedAt
                        ? moment(item?.approvedAt).format("MMM DD, YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>{item?.liveMembershipsCount}</TableCell>
                    <TableCell>{item?.expiredMembershipsCount}</TableCell>
                    <TableCell>{item?.lapsedMembershipsCount}</TableCell>
                    <TableCell>{item?.cancelledMembershipsCount}</TableCell>
                    <TableCell>
                      {item?.lapsedCancelledMembershipsCount}
                    </TableCell>
                  </TableRow>
                );
              })}
            {sales && sales.length > 0 && (
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>{data?.totalLiveMembershipsCount}</b>
                </TableCell>
                <TableCell>
                  <b>{data?.totalExpiredMembershipsCount}</b>
                </TableCell>
                <TableCell>
                  <b>{data?.totalLapsedMembershipsCount}</b>
                </TableCell>
                <TableCell>
                  <b>{data?.totalCancelledMembershipsCount}</b>
                </TableCell>
                <TableCell>
                  <b>{data?.totalLapsedCancelledMembershipsCount}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {sales && sales.length === 0 && (
          <div className="no-content">
            <h6>No Content</h6>
            <p>No data available right now.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesAgentsCountsListForMembershipUsers;
