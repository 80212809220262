import React, { useState } from "react";
import { Dialog } from "@mui/material";

import axios from "src/utils/axios";
import CancelButton from "src/components/common/modal-cancel-btn";
import { errorMessage } from "src/helpers";
import { API_MESSAGES , VALIDATION_MESSAGES} from "src/constant/global";

export default function AddGroup(props) {
  const { open, setOpen, refetchData } = props;
  const [groupName, setGroupName] = useState("");
  const [error, setError] = useState(false);

  
  const handleAddGroup = () => {
   if (groupName) {
      axios
        .post("admin/addGroup", { name: groupName })
        .then(() => {
          setOpen(false);
          refetchData(true, API_MESSAGES.addGroupSuccess);
        })
        .catch((error) => {
          setOpen(false);
          let message = "";

          if (error?.response && error?.response?.data) {
            message =
            errorMessage(error);
          }
          
          refetchData(false, message||API_MESSAGES.addGroup);
        });
    }
  };

  return (
    <Dialog
      open={open}
      style={{ padding: "50px" }}
      onClose={() => setOpen(false)}
    >
      <div className="modal-text">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="heading flex-center">
          <h6>Add New Group</h6>
        </div>

        <div className="text pb24">
          <div className="form-in">
            <label>Group Name (Max 20 Char)</label>

            <input
              maxLength={20}
              type="text"
              className={error? 'error': ''}
              onBlur={() => {
                if (!groupName ) {
                  setError(true);
                }
              }}
              onFocus={() => {
                if (error) {
                  setError(false);
                }
              }}
              placeholder="Add New Group Name Here"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <br />
            {error && <span className="error-msg">{VALIDATION_MESSAGES.groupInput}</span>}
          </div>
        </div>

        <div className="foot">
          <button
            disabled={error|| !groupName}
            className="btn"
            text="Save Group"
            onClick={() => handleAddGroup()}
          >
            Save Group
          </button>
        </div>
      </div>
    </Dialog>
  );
}
