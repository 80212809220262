import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "src/components/common/loaders";
import { errorAlert, successAlert } from "src/utils/alerts";
import MembershipPlans from "./components/membershipPlans";
import { getMembershipList } from "src/redux/services/membershipRates.service";
import EditMembership from "./components/editMembership";
import axios from "src/utils/axios";

const MembershipRates = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const [editMembershipValues, setEditMembershipValues] = useState({});

  useEffect(() => {
    dispatch(getMembershipList({}));
  }, []);

  const { data, loading } = useSelector((state) => state?.membershipRates);

  const handleEditMembershipValues = ({
    membershipId,
    price,
    savingPercentage,
    type,
    status,
    baseValue,
  }) => {
    if (membershipId && price) {
      setEditMembershipValues({
        price,
        savingPercentage,
        type,
        membershipId,
        status,
        baseValue,
      });
      setIsEdit(true);
    } else {
      setEditMembershipValues({
        savingPercentage,
        membershipId,
        status,
      });
    }
  };

  const handleMembershipStatus = () => {
    const { membershipId, status } = editMembershipValues;
    const editStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    axios
      .put(`/admin/membership-plan-status`, {
        membershipId,
        status: editStatus,
      })
      .then((res) => {
        dispatch(getMembershipList({}));
        successAlert(res?.data?.message);
        dispatch(getMembershipList({}));
        setIsEdit(false);
      })
      .catch((error) => {
        let err =
          error && error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(err);
      });
  };

  const handleEditMembershipSumbit = (values) => {
    const { membershipId } = editMembershipValues;
    const { price, savingPercentage } = values;

    axios
      .put(`/admin/membership-plan-edit`, {
        membershipId,
        price,
        savingPercentage,
      })
      .then((res) => {
        dispatch(getMembershipList({}));
        successAlert(res?.data?.message);
        setIsEdit(false);
      })
      .catch((error) => {
        let err =
          error && error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(err);
      });
  };

  return (
    <div className="main-div">
      {!isEdit && (
        <div className="heading">
          <div className="title">
            <h6>Membership Rates</h6>
          </div>
        </div>
      )}

      {loading && <Loader />}
      {isEdit ? (
        <EditMembership
          setIsEdit={setIsEdit}
          editMembershipValues={editMembershipValues}
          handleEditMembershipSumbit={handleEditMembershipSumbit}
        />
      ) : (
        <>
          {data &&
            data?.length > 0 &&
            data.map((membership) => {
              return (
                <MembershipPlans
                  key={membership?._id}
                  membershipData={membership}
                  handleEditMembership={handleEditMembershipValues}
                  handleMembershipStatus={handleMembershipStatus}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

export default MembershipRates;
