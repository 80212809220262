import React, { useState } from "react";
import { AccessModules } from "src/constant/global";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isValidEmail, isValidName } from "src/helpers";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import Loader from "src/components/common/loaders";
const heading = [
  "Sr. No.",
  "Module",
  "Add Access",
  "Edit Access",
  // "View Access",
  "Delete Access",
];
const AddNewSubAdmin = ({ setAddNew }) => {
  const [accessModules, setAccessModules] = useState(AccessModules);
  const [fields, setFields] = useState({ name: "", email: "" });
  const [errors, setErrors] = useState({ name: "", email: "" });
  const [loading, setLoading] = useState(false);
  const handleChange = ({ key, index }) => {
    const copyAccessModules = [...accessModules];
    let moduleObject = copyAccessModules[index];
    moduleObject = {
      ...moduleObject,
      [key]: !moduleObject[key],
    };
    copyAccessModules[index] = moduleObject;

    setAccessModules(copyAccessModules);
  };

  const handleInputChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    let copyFields = { ...fields };
    copyFields = {
      ...copyFields,
      [key]: value,
    };
    setFields(copyFields);

    if (key === "email") {
      setErrors({
        ...errors,
        [key]: "",
      });
    } else {
      const isValid = isValidName(value);
      if (!isValid) {
        setErrors({
          ...errors,
          [key]:
            "Please Enter Valid Name. A Name Should Not Contain Special Characters Or Numeric Characters",
        });
      } else {
        setErrors({
          ...errors,
          [key]: "",
        });
      }
    }
  };

  const getDisabledStatus = () => {
    return !fields.email || !fields.name || errors.email.length > 0;
  };

  const handleSave = () => {
    setLoading(true);
    const newArray = [...accessModules];
    const updatedValues = newArray.map((el) => {
      return {
        name: el.name,
        add: el.add,
        edit: el.edit,
        // view: el.edit || el.add || el.delete,
        delete: el.delete,
      };
    });

    const dataToSend = {
      ...fields,
      module: updatedValues,
    };

    customAxios
      .post("admin/sub-admin-add", { ...dataToSend })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          successAlert(response?.data?.message);
          setAddNew(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const handleBlur = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    let copyFields = { ...fields };
    copyFields = {
      ...copyFields,
      [key]: value,
    };
    setFields(copyFields);
    const isValid = isValidEmail(value);
    if (!isValid) {
      setErrors({
        ...errors,
        [key]: "Please Enter Valid Email",
      });
    } else {
      setErrors({
        ...errors,
        [key]: "",
      });
    }
  };

  return (
    <div className="main-div">
      <div className="heading bb">
        <div className="title">
          <h6>Add Sub Admin</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setAddNew(false);
            }}
          >
            BACK
          </button>
        </div>
      </div>
      {loading && <Loader />}
      <div className="mb24">
        <div className="row">
          <div className="col-md-4">
            <div className="form-in">
              <label>Name </label>
              <div className="f-in">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={fields.name}
                  onChange={handleInputChange}
                />
              </div>
              {errors.name && (
                <div name="mobileImage" className="input-feedback error-msg">
                  {errors.name}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-in">
              <label>Email </label>
              <div className="f-in">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={fields.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && (
                <div name="mobileImage" className="input-feedback error-msg">
                  {errors.email}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 className="fs16">Sub-Admin Access</h6>
        <div className="lite-table table-sec">
          <Table>
            <TableHead>
              <TableRow>
                {heading &&
                  heading.length > 0 &&
                  heading.map((h, index) => {
                    return (
                      <TableCell key={`key_${index + 1}`} align="left">
                        {h}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {accessModules &&
                accessModules.length > 0 &&
                accessModules.map((item, index) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.label}</TableCell>
                      <TableCell>
                        {item.name !== "MEMBERSHIP_RATE_MANAGEMENT" &&
                          item.name !== "CANCELLATION_REFUND" &&
                          item.name !== "DASHBOARD" &&
                          item.name !== "REPORTS_ANALYTICS" &&
                          item.name !== "REFERRAL_PARTNER_MANAGEMENT" && (
                            <Checkbox
                              color="primary"
                              checked={item.add}
                              onChange={(e) => {
                                handleChange({ key: "add", index });
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell>
                        {item.name !== "REPORTS_ANALYTICS" && (
                          <Checkbox
                            color="primary"
                            checked={item.edit}
                            onChange={(e) => {
                              handleChange({ key: "edit", index });
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {item.name !== "USER_MANAGEMENT" &&
                          item.name !== "CATEGORY_MANAGEMENT" &&
                          item.name !== "TOPICS_MANAGEMENT" &&
                          item.name !== "CANCELLATION_REFUND" &&
                          item.name !== "DASHBOARD" &&
                          item.name !== "REPORTS_ANALYTICS" &&
                          item.name !== "COMPLIMENTARY_MEMBERSHIP" &&
                          item.name !== "MEMBERSHIP_RATE_MANAGEMENT" &&
                          item.name !== "REFERRAL_PARTNER_MANAGEMENT" && (
                            <Checkbox
                              color="primary"
                              checked={item.delete}
                              onChange={(e) => {
                                handleChange({ key: "delete", index });
                              }}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="btns-lower">
        <div>
          <button
            type="button"
            className="btn long"
            onClick={handleSave}
            disabled={getDisabledStatus()}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewSubAdmin;
