import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import * as Yup from "yup";
import { passwordRegex } from "src/helpers";
import { resetPassword } from "src/redux/services/auth.service";
import SieveLogo from "../../../assets/images/Sieve-logo-black.svg";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter New Password.")
    .matches(
      passwordRegex,
      "Password Must Be Minimum 6 Characters Long And Must Contain At Least An Upper Case Letter, A Lower Case Letter, A Special Character And A Numeric Number"
    ),
  confirmPassword: Yup.string()
    .required("Please Confirm New Password.")
    .oneOf([Yup.ref("password"), null], "The New Passwords Don't Match"),
});

const ResetPassword = () => {
  const {
    loading: apiLoader,
    otp,
    currentUserEmail,
  } = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const { password } = values;
    dispatch(
      resetPassword({
        email: currentUserEmail,
        password,
        otp,
        callback: (res) => {
          if (res?.statusCode === 200) {
            navigate("/");
          }
        },
      })
    );
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          confirmPassword: "",
          password: "",
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Form>
              <section className="login">
                <button
                  className="null-btn back-btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <span className="icon">
                    <i className="back-icon"></i>{" "}
                  </span>
                  Back
                </button>
                <div className="wrapper">
                  <div className="text">
                    <div className="head">
                      <figure className="logo">
                        <img src={SieveLogo} alt="sieveLogo" />
                      </figure>

                      <p className="txt">
                        Expertly curated insights <br /> for the next generation
                        of business
                      </p>
                    </div>

                    <div className="body">
                      <h6 className="font-old title fs20">
                        Create New Password
                      </h6>
                      <p className="text-center mb24">
                        Your new password must be different from previous
                        passwords used.
                      </p>

                      <form>
                        <div className="form-in icon">
                          <label>New Password</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="password-icon"></i>
                            </span>
                            <Field
                              type={`${showPassword ? "text" : "password"}`}
                              placeholder="New Password"
                              name="password"
                            />
                            <div className="icon-right">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowPassword((prev) => !prev);
                                }}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>

                        <div className="form-in icon">
                          <label>Confirm Password</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="password-icon"></i>
                            </span>
                            <Field
                              type={`${
                                showConfirmPassword ? "text" : "password"
                              }`}
                              placeholder="Confirm Password"
                              name="confirmPassword"
                            />
                            <div className="icon-right">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowConfirmPassword((prev) => !prev);
                                }}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </div>
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </form>
                    </div>

                    <div className="foot">
                      <div>
                        <button
                          type="submit"
                          className="btn single"
                          disabled={apiLoader}
                        >
                          DONE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
