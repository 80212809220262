import React, { memo, useState } from "react";
import SharePopup from "src/components/common/ShareModal";
import { errorAlert } from "src/utils/alerts";
import { handleShortLink } from "../../../../helpers/index";

const PreviewDesktopMobile = ({ editionTab, fetchedData }) => {
  const [openShare, setOpenShare] = useState(false);
  const redirectToPlatform = (shareData, selectedPlatForm) => {
    if (shareData && Object.keys(shareData).length > 0) {
      window.open(
        `https://www.shareaholic.com/api/share/?v=1&apitype=1&apikey=09caf0832de5997df5a805f422cd5b88&service=${selectedPlatForm}&link=${shareData.shortLink}&source=Sieve`,
        "_blank"
      );
    }
  };

  const handleSendReferral = async (platform) => {
    const data = {
      title:
        "Click on the link to start your all access free trial on sieve media.",
      mobileImage: fetchedData?.mobileImage,
    };
    try {
      const res = await handleShortLink(
        data,
        `https://sieveqaweb.appskeeper.in`
      );
      if (res && Object.keys(res).length > 0) {
        redirectToPlatform(res, platform);
      }
    } catch (err) {
      errorAlert("Something Went Wrong While Creating A Link For Share");
    }
  };
  return (
    <>
      <div>
        {editionTab === "web" && (
          <section className="web-preview">
            {fetchedData &&
              Object.keys(fetchedData).length > 0 &&
              fetchedData.desktopImage && (
                <div className="preview-banner">
                  <figure>
                    <img src={fetchedData.desktopImage} alt="desktopImage" />
                  </figure>
                </div>
              )}
            <div className="pt24">
              <div className="cards-row">
                {fetchedData &&
                  fetchedData.cards &&
                  fetchedData.cards.length > 0 &&
                  fetchedData.cards.map((f, index) => {
                    return (
                      <div key={`key_${index + 1}`}>
                        <article className="article-card">
                          <header>
                            <p>
                              {f.category &&
                              Object.keys(f.category).length > 0 &&
                              f.category.name &&
                              fetchedData.editionType === "WEEKDAY"
                                ? f.category.name.toUpperCase()
                                : fetchedData.editionType || ""}
                            </p>
                          </header>
                          <figure>
                            <img src={f.mobileImage} alt={"Front"} />
                          </figure>
                          <div className="text">
                            <div className="labels">
                              <mark>{f.attribute}</mark>
                              <small>Image: {f.imageSource}</small>
                            </div>

                            <p>{f.title} </p>
                          </div>
                          <footer>
                            <button className="blank-btn">
                              <i className="bookmark-icon"></i>
                            </button>
                            <button className="blank-btn">
                              <i className="share-icon"></i>
                            </button>
                          </footer>
                          <a href="javascript:;" title="Title"></a>
                        </article>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        )}

        {editionTab === "mobile" && (
          <section className=" preview-div mobile">
            <div className="text-right">
              <button
                className="btn-white"
                onClick={() => {
                  setOpenShare(true);
                }}
              >
                Share
              </button>
            </div>

            <div className="mobile-div">
              {fetchedData &&
                Object.keys(fetchedData).length > 0 &&
                fetchedData.mobileImage && (
                  <div className="preview-banner">
                    <figure>
                      <img src={fetchedData.mobileImage} />
                    </figure>
                  </div>
                )}

              <div className="pt24">
                <div className="cards-row">
                  {fetchedData &&
                    fetchedData.cards &&
                    fetchedData.cards.length > 0 &&
                    fetchedData.cards.map((f, index) => {
                      return (
                        <div key={`key_${index + 1}`}>
                          <article className="article-card">
                            <figure>
                              {" "}
                              <img src={f.mobileImage} alt={""} />
                            </figure>
                            <div className="text">
                              <div className="labels">
                                <mark>{f.attribute}</mark>
                                <small>Image: {f.imageSource}</small>
                              </div>

                              <p>{f.title} </p>
                            </div>
                            <footer>
                              <div>
                                <button className="blank-btn">
                                  <i className="bookmark-icon"></i>
                                </button>
                                <button className="blank-btn">
                                  <i className="share-icon"></i>
                                </button>
                              </div>
                              <div>
                                <button className="blank-btn listen">
                                  {f.ctaText || ""}{" "}
                                  <i className="right-caret-icon"></i>
                                </button>
                              </div>
                            </footer>
                          </article>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <SharePopup
        redirectToPlatform={handleSendReferral}
        share={openShare}
        closeShare={() => {
          setOpenShare(false);
        }}
      />
    </>
  );
};

export default memo(PreviewDesktopMobile);
