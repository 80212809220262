import React, { useMemo } from "react";
import TextField from "@mui/material/TextField";

const PendingRequestForm = ({ count, request }) => {
  const data = useMemo(() => {
    return {
      isGift: request?.isGiftMembership,
      customerId: request?.userDetails?.customerId || "-",
      firstName: request?.userDetails?.firstName || "-",
      lastName: request?.userDetails?.lastName || "-",
      email: request?.userDetails?.email || "-",
      giftedUserDetails: request?.giftedUserDetails,
      reason: request?.reason,
      message: request?.message,
    };
  }, [request]);

  return (
    <div>
      <h5 className="text-center fs22 fw600 mb18">{`Cancellation & Refund Request ${
        count ? "#" + count : ""
      }`}</h5>

      <div className="refund-sec">
        <form>
          <div className="bb">
            <p className="m20 fs16 fw600">
              I would like to cancel my membership
            </p>
            <div className="d-flexx mb30">
              <div className="checkbox">
                <input type="radio" checked={!data.isGift} />
                <label>Personal</label>
              </div>

              <div className="checkbox ml16">
                <input type="radio" checked={data.isGift} />
                <label>Gift</label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-in grey icon">
                  <span className="icon-left">
                    <i className="user-sm-icon"></i>
                  </span>
                  <div className="f-in">
                    <TextField
                      label="Customer ID"
                      type="text"
                      defaultValue={data?.customerId}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-in grey icon">
                  <span className="icon-left">
                    <i className="user-sm-icon"></i>
                  </span>
                  <div className="f-in">
                    <TextField
                      label="Last Name"
                      type="text"
                      defaultValue={data?.lastName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-in grey icon">
                  <span className="icon-left">
                    <i className="user-sm-icon"></i>
                  </span>
                  <div className="f-in">
                    <TextField
                      label="First Name"
                      type="text"
                      defaultValue={data?.firstName}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-in grey icon">
                  <span className="icon-left">
                    <i className="mail-icon"></i>
                  </span>
                  <div className="f-in">
                    <TextField
                      label="Email address"
                      type="text"
                      defaultValue={data?.email}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data && data?.giftedUserDetails && (
            <div className="bb">
              <h6>Additional Information In Case of Gift Membership</h6>
              <h6 className="mb16">Gift Recipient</h6>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="user-sm-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField
                        label="First Name"
                        type="text"
                        defaultValue={data?.giftedUserDetails?.firstName}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="mail-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField
                        label="Email address"
                        type="text"
                        defaultValue={data?.giftedUserDetails?.email}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="user-sm-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField
                        label="Last Name"
                        type="text"
                        defaultValue={data?.giftedUserDetails?.lastName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bb">
            <h6 className="mb18">Reason For Cancellation</h6>
            <div className="d-flexx">
              <div className="checkbox d-inline-block mb18">
                <input
                  type="radio"
                  checked={data?.reason === "CONTENT_NOT_RELEVENT"}
                />
                <label>Content not relevant</label>
              </div>
              <br />

              <div className="checkbox d-inline-block mb18">
                <input
                  type="radio"
                  checked={data?.reason === "DISSATISFIED_WITH_SERVICE"}
                />
                <label>Dissatisfied with service</label>
              </div>
              <br />
              <div className="checkbox d-inline-block mb12">
                <input type="radio" checked={data?.reason === "OTHER"} />
                <label>Other</label>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="col-12 ">
                <div className="form-in grey icon mt24">
                  <span className="icon-left">
                    <i className="text-icon"></i>
                  </span>
                  <div className="f-in">
                    <TextField
                      label="Message"
                      type="text"
                      multiline
                      rows={4}
                      defaultValue={data?.message}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PendingRequestForm;
