import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorAlert, successAlert } from "src/utils/alerts";

//Add Content bank Data
export const addContentCard = createAsyncThunk(
  "admin/contentBank",
  // Callback Function
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await axios.post(`admin/contentBank/add-card`, {
        ...data,
      });

      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
      }
      callback(response.data);
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContentCardList = createAsyncThunk(
  "admin/contentBankList",
  // Callback Function
  async ({ url, callback }, thunkAPI) => {
    try {
      const response = await axios.get(url);
      callback(response.data);
      return response.data;
    } catch (error) {
      callback(null);
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong";
      errorAlert(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
