import React, { useState } from "react";
import { CFormInput, CForm, CButton, CCol } from "@coreui/react";

const AddSalesAgent = ({ isBack, setNewAdd, items, setItemArr }) => {
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const { firstName, lastName, contact, email, percentage } = form;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      event.preventDefault();
      let fullName = `${firstName} ${lastName}`;
      let newItem = {
        sr: String(items.length + 1),
        name: fullName,
        email,
        commission: String(percentage),
        action: <div />,
      };
      let newArr = [...items, newItem];
      setItemArr(newArr);
      setNewAdd(!isBack);
      return;
    }
    setValidated(true);
  };

  return (
    <div>
      <h5>Add New Sales Agent</h5>
      <CForm
        className="row g-3"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <CCol md={4}>
            <CButton onClick={() => setNewAdd(!isBack)}>Back</CButton>
          </CCol>
          <CCol md={4}>
            <CButton type="submit">Save</CButton>
          </CCol>
        </div>
        <CCol md={4}>
          <CFormInput
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            required
            maxLength={10}
          />
        </CCol>
        <CCol md={4}>
          <CFormInput
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={lastName}
            onChange={handleChange}
            required
            maxLength={10}
            on
          />
        </CCol>
        <CCol md={4}>
          <CFormInput
            placeholder="Contact"
            name="contact"
            value={contact}
            onChange={handleChange}
            required
            maxLength={10}
            type="phone"
          />
        </CCol>
        <CCol md={4}>
          <CFormInput
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleChange}
            required
            type="email"
          />
        </CCol>
        <CCol md={4}>
          <CFormInput
            type="text"
            placeholder="Commission"
            name="percentage"
            value={percentage}
            onChange={handleChange}
            required
            maxLength={2}
          />
        </CCol>
      </CForm>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <CCol md={4}>
          <CButton>APPROVE</CButton>
        </CCol>
        <CCol md={4}>
          <CButton type="submit">REJECT</CButton>
        </CCol>
      </div>
    </div>
  );
};

export default AddSalesAgent;
