import React from "react";

const Counts = ({ data }) => {
  return (
    <div className="dashboard-cards">
      <div>
        <p>Total Active Sales Agents</p>
        <h5>{(data && data.totalActiveSalesAgents) || "-"}</h5>
      </div>

      <div>
        <p>Total Live Memberships</p>
        <h5>{(data && data.totalLiveMemberships) || "0"}</h5>
      </div>

      <div>
        <p>New Cancellations Received</p>
        <h5>{(data && data.newCancellationReceived) || "0"}</h5>
      </div>

      <div>
        <p>Pending Refunds</p>
        <h5>{(data && data.pendingRefunds) || "0"}</h5>
      </div>

      <div>
        <p>Referral Partner Applications</p>
        <h5>{(data && data.newReferralPartnerApplications) || "0"}</h5>
      </div>
    </div>
  );
};

export default Counts;
