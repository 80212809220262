import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelButton from "../../../components/common/modal-cancel-btn";
import DatePicker from "react-datepicker";
import { Switch } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

const index = () => {
  return (
    <div>
      <div className="main-div h-auto mt24">
        <div className="heading ">
          <div className="title">
            <h6 className="fw600">Reports & Analytics</h6>
          </div>

          <div className="ml-div">date filter</div>
        </div>
      </div>

      <div className="row table-reports">
        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">All Free Trial Sign-Ups</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Registered</TableCell>
                    <TableCell>Converted</TableCell>
                    <TableCell>Expired</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>200</TableCell>
                    <TableCell>150</TableCell>
                    <TableCell>50</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">All Paid Memberships</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Live</TableCell>
                    <TableCell>Expired</TableCell>
                    <TableCell>Lapsed</TableCell>
                    <TableCell>Cancelled</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>200</TableCell>
                    <TableCell>150</TableCell>
                    <TableCell>50</TableCell>
                    <TableCell>50</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Referral Free Trial Sign-Ups</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Registered</TableCell>
                    <TableCell>Converted</TableCell>
                    <TableCell>Expired</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>200</TableCell>
                    <TableCell>150</TableCell>
                    <TableCell>50</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">All Paid Memberships</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Live</TableCell>
                    <TableCell>Expired</TableCell>
                    <TableCell>Lapsed</TableCell>
                    <TableCell>Cancelled</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>200</TableCell>
                    <TableCell>150</TableCell>
                    <TableCell>50</TableCell>
                    <TableCell>50</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Referral Partners Profile Details</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Total Referral Partners</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Active Partners</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Inactive Partners</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Completed Profiles</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Incomplete Profiles</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <a className="link ml18">
                <u>Send Email</u>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Revenue By Month</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="light-table full table-sec">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Jan 2022</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Gross Amount</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Coupon Discounts</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Stripe Charges</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Refunds</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Partner Commission</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Net Revenue</TableCell>
                    <TableCell>150</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Content Clicks By Edition Date</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="content-flex">
              <p>
                Total Content Pieces <b>20</b>
              </p>
              <p>
                Total Content Pieces <b>20</b>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Referral Revenue & Commission</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>

            <div className="content-flex">
              <p>
                Total Content Pieces <b>20</b>
              </p>
              <p>
                Total Content Pieces <b>20</b>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="fs16">Complimentary Memberships</h6>
              </div>

              <div className="ml-auto">
                <button className="btn-msg">
                  <i className="msg-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-div h-auto  mt24">
        <div className="heading ">
          <div className="title">
            <h6 className="fw600">Inactive Users</h6>
          </div>

          <div className="ml-auto btn-grp">
            <button className="btn">Send Email</button>
            <button className="btn-msg">
              <i className="msg-icon"></i>
            </button>
          </div>
        </div>

        <div className="light-table full  table-sec">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Discount %</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>C4123432</TableCell>
                <TableCell>
                  <a className="link">Adison Rhiel Madsen</a>
                </TableCell>

                <TableCell>adison123@gmail.com</TableCell>
                <TableCell>
                  <div className="chip-label">Inactive</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      <div className="text-center mt24">
        <button className="btn">SEND REPORT TO EMAIL</button>
      </div>

      <div className="d-none">
        <div className="main-div h-auto mt24">
          <div className="blue-heading">
            <div className="title">
              <h6 className="fs16">Add New Coupon</h6>
            </div>
          </div>
          <div className="heading ">
            <div className="title">
              <h6 className="fs16 fw600">Add coupon details below </h6>
            </div>
          </div>

          <div className="content-type">
            <p className="text-black fw600">Content Type</p>

            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className="text"
              >
                <FormControlLabel
                  control={<Radio />}
                  label=" Percentage Discount"
                />
                <FormControlLabel control={<Radio />} label=" Flat Discount" />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="row col-form">
            <div className="col-md-4">
              <div className="form-in">
                <label>Plese Enter Name</label>
                <div className="f-in">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in">
                <label>Please Enter Code</label>
                <div className="f-in">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in icon">
                <label>Discount Value</label>
                <div className="f-in">
                  <span className="icon-left">
                    <i className="dollar-icon"></i>
                  </span>
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in date-input">
                <label>Start Date</label>
                <div className="f-in">
                  <DatePicker
                    className="date-picker"
                    placeholderText="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in date-input">
                <label>Start Date</label>
                <div className="f-in">
                  <DatePicker
                    className="date-picker"
                    placeholderText="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in content-type">
                <label>Status</label>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="text"
                  >
                    <FormControlLabel control={<Radio />} label=" Active" />
                    <FormControlLabel control={<Radio />} label=" Inactive" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="btns-lower">
            <button className="btn">Add New</button>
          </div>
        </div>

        <div className="main-div  mt24">
          <div className="heading ">
            <div className="title">
              <h6 className="fw600">Coupon Management</h6>
            </div>
          </div>

          <div className="light-table full table-sec">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Discount %</TableCell>
                  <TableCell>Flat Discount</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>ABC</TableCell>
                  <TableCell>IND50NEW</TableCell>
                  <TableCell>
                    <Switch />
                  </TableCell>
                  <TableCell>Flat Discount</TableCell>
                  <TableCell>$ 40</TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>ABC</TableCell>
                  <TableCell>IND50NEW</TableCell>
                  <TableCell>
                    <Switch />
                  </TableCell>
                  <TableCell>Flat Discount</TableCell>
                  <TableCell>$ 40</TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>ABC</TableCell>
                  <TableCell>IND50NEW</TableCell>
                  <TableCell>
                    <Switch />
                  </TableCell>
                  <TableCell>Flat Discount</TableCell>
                  <TableCell>$ 40</TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>ABC</TableCell>
                  <TableCell>IND50NEW</TableCell>
                  <TableCell>
                    <Switch />
                  </TableCell>
                  <TableCell>Flat Discount</TableCell>
                  <TableCell>$ 40</TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="main-div h-auto mt24">
          <div className="heading ">
            <div className="title">
              <h6 className="">View Details</h6>
            </div>
          </div>

          <div className="search-filter bb0 mb0 pb0">
            <div className="cols">
              <div className="form-in icon">
                <span className="icon-left">
                  <i className="search-icon"></i>
                </span>
                <div className="f-in">
                  <input type="email" placeholder="Search by Email" />
                </div>
              </div>
            </div>

            <div className="cols">
              <div className="form-in icon">
                <span className="icon-left">
                  <i className="search-icon"></i>
                </span>
                <div className="f-in">
                  <input
                    type="text"
                    placeholder="Search by First & Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="cols">
              <div className="form-in icon">
                <span className="icon-left">
                  <i className="search-icon"></i>
                </span>
                <div className="f-in">
                  <input type="text" placeholder="Search by Customer ID" />
                </div>
              </div>
            </div>

            <div className="cols">
              <button className="btn fs16">Search</button>
            </div>
          </div>

          <div className="compli-search">
            <div className="head">
              <p>Search Results for Adison Rhiel</p>
              <button className="btn">
                <i className="edit-icon"></i>
              </button>
            </div>

            <div className="compli-listing">
              <ul>
                <li>
                  <p>First Name</p> <small>Adison</small>
                </li>

                <li>
                  <p>Contact</p> <small>Adison</small>
                </li>

                <li>
                  <p>UID</p> <small>Adison</small>
                </li>
              </ul>

              <ul>
                <li>
                  <p>Last Name</p> <small>Rhiel Madsen</small>
                </li>

                <li>
                  <p>Membership Type</p> <small>Professional</small>
                </li>

                <li>
                  <p>Status</p> <small>Active</small>
                </li>
              </ul>

              <ul>
                <li>
                  <p>Email address</p> <small>adison123@appinventiv.com</small>
                </li>

                <li className="flex">
                  <div>
                    <p>Start Date</p> <small>29 Aug, 2022</small>
                  </div>
                  <div>
                    <p>End Date</p> <small>29 Sep, 2022</small>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="main-div h-auto mt24">
          <div className="heading ">
            <div className="title">
              <h6 className="fs16 fw600">Complimentary Membership</h6>
            </div>

            <div>
              <p className="btn-label">30 Ongoing Memberships</p>
            </div>

            <div class="ml-div">
              <button className="btn">ADD NEW</button>
            </div>
          </div>

          <div className="light-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email ID</TableCell>
                  <TableCell>Mobile No.</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>62600008aba167</TableCell>
                  <TableCell>Adison</TableCell>
                  <TableCell>Rhiel Madsen</TableCell>
                  <TableCell>adison.rhiel@gmail.com</TableCell>
                  <TableCell>+001 - 989989390</TableCell>
                  <TableCell>
                    <div className="chip-label blue">Live</div>
                  </TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>62600008aba167</TableCell>
                  <TableCell>Adison</TableCell>
                  <TableCell>Rhiel Madsen</TableCell>
                  <TableCell>adison.rhiel@gmail.com</TableCell>
                  <TableCell>+001 - 989989390</TableCell>
                  <TableCell>
                    <div className="chip-label">Expired</div>
                  </TableCell>
                  <TableCell>29 Aug, 2022</TableCell>
                  <TableCell>29 Sep, 2022</TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="main-div h-auto mt24">
          <div className="blue-heading">
            <div className="title">
              <h6 className="fs16">Add New Complimentary Member</h6>
            </div>
          </div>
          <div className="heading ">
            <div className="title">
              <h6 className="fs16 fw600">
                Add Complimentary Member Details below
              </h6>
            </div>
          </div>

          <div className="row col-form">
            <div className="col-md-4">
              <div className="form-in">
                <label>First Name</label>
                <div className="f-in">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in">
                <label>Last Name</label>
                <div className="f-in">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in">
                <label>Email</label>
                <div className="f-in">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-in phone">
                <label>Email</label>
                <div className="f-in">
                  <div>
                    <select>
                      <option>+91</option>
                    </select>
                  </div>
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-in">
                <label>Start Date</label>
                <div className="f-in">
                  <input type="date" />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-in">
                <label>Start Date</label>
                <div className="f-in">
                  <input type="time" />
                </div>
              </div>
            </div>
          </div>

          <div className="btns-lower">
            <button className="btn-white">SAVE</button>
            <button className="btn">Send Email</button>
          </div>
        </div>

        <div className="main-div h-auto mt24">
          <div className="heading bb">
            <div className="title">
              <h6 className="">Create Editor’s Pick</h6>
            </div>
          </div>

          <div className="emailer-divs">
            <div className="emailer-div">
              <div className="img-upload">
                <span className="delete-img">
                  <CancelButton />
                </span>
                <figure>
                  <img />
                </figure>
                <input type="file" />
                <div className="text">
                  <span className="icon icon-upload">
                    <i className="upload-icon"></i>
                  </span>
                  <p>Upload from gallery</p>
                  <p>(216x250)</p>
                  <p className="accept-img">
                    (accepts .png .jpeg .jpg images only)
                  </p>
                </div>
              </div>

              <div className="text">
                <div className="form-in">
                  <label>Redirection URL*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Attribution Text*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Choose CTA*</label>
                  <div className="f-in">
                    <select>
                      <option>Gift</option>
                      <option>Gift</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb24">
            <button className="btn">Add Content Piece</button>
          </div>

          <div className="publish-date">
            <p className="m12 text-black fw500">Schedule Publishing</p>

            <div className="row">
              <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in date-input">
                  <label>Publishing Date of Edition</label>
                  <DatePicker
                    className="date-picker"
                    placeholderText="mm/dd/yyyy"
                  />
                  {/* <TextField
                    id="date"
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      console.log(e, '***8e')
                      setEditionData({ ... editionData, date: e.target.value})

                    }}
                  /> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in">
                  <label>Time of Publishing</label>
                  <input type="time" />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb24">
            <button className="btn">SAVE</button>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading bb">
            <div className="">
              <h6 className="title">Create Editor’s Pick</h6>
            </div>
          </div>

          <div className="emailer-divs">
            <div className="emailer-div">
              <div className="img-upload">
                <span className="delete-img">
                  <CancelButton />
                </span>
                <figure>
                  <img />
                </figure>
                {/* <input
                    type="file"
                  />
                <div className="text">
                  <span className="icon icon-upload">
                    <i className="upload-icon"></i>
                  </span>
                  <p>Upload from gallery</p>
                  <p>(216x250)</p>
                  <p className="accept-img">
                    (accepts .png .jpeg .jpg images only)
                  </p>
                </div> */}
              </div>

              <div className="text">
                <div className="form-in">
                  <label>Redirection URL*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Attribution Text*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Choose CTA*</label>
                  <div className="f-in">
                    <select>
                      <option>Gift</option>
                      <option>Gift</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="emailer-div">
              <div className="img-upload">
                <span className="delete-img">
                  <CancelButton />
                </span>
                <figure>
                  <img />
                </figure>
                {/* <input
                    type="file"
                  />
                <div className="text">
                  <span className="icon icon-upload">
                    <i className="upload-icon"></i>
                  </span>
                  <p>Upload from gallery</p>
                  <p>(216x250)</p>
                  <p className="accept-img">
                    (accepts .png .jpeg .jpg images only)
                  </p>
                </div> */}
              </div>

              <div className="text">
                <div className="form-in">
                  <label>Redirection URL*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Attribution Text*</label>
                  <div className="f-in">
                    <input type="text" />
                  </div>
                </div>

                <div className="form-in">
                  <label>Choose CTA*</label>
                  <div claasName="f-in">
                    <select>
                      <option>Gift</option>
                      <option>Gift</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="publish-date">
            <p className="m12 text-black fw500">Schedule Publishing</p>

            <div className="row">
              <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in date-input">
                  <label>Publishing Date of Edition</label>
                  <DatePicker
                    className="date-picker"
                    placeholderText="mm/dd/yyyy"
                  />
                  {/* <TextField
                    id="date"
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      console.log(e, '***8e')
                      setEditionData({ ... editionData, date: e.target.value})

                    }}
                  /> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in">
                  <label>Time of Publishing</label>
                  <input type="time" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-div mt24 h-auto">
          <div className="heading bb">
            <div className="title">
              <h6 className="">Preview Editor’s Pick on Desktop</h6>
            </div>
          </div>

          <div className="email-preview">
            {/* <table style="width:100%;max-width: 600px; margin: 0 auto; padding: 0;background-color:#F8F4FA;border: none;border-spacing: 0;">
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td style="padding: 16px 24px 28px;"><p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0;">April 4, 2022</p></td>
                      <td style="padding: 16px 24px 28px;"><p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0;text-align: right;">Customer Id.: <b style="color: #2B2E36;font-weight:500;">S000101</b> </p></td>
          
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td style="padding: 0px 12px 30px;text-align: center;border-radius: 8px;">
                  <img width="179px" style="margin: 0px;" src="logo-text.png"/>

                </td>
              </tr>

              <tr>
                <td style="padding: 0 0px 32px;">
                  <hr style="margin: 0 90px 37px;background-color:#D3D3D3;opacity: 0.3;" />

                  <div class="cards">
                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">LISTEN <img src="caret.png" /></a>
                    </div>
                    
                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">READ <img src="caret.png" /></a>
                    </div>
                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">WATCH <img src="caret.png" /></a>
                    </div>

                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">LISTEN <img src="caret.png" /></a>
                    </div>

                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">READ <img src="caret.png" /></a>
                    </div>

                    <div style="display: inline-block;padding: 0 10px 26px;">
                      <img src="img.png" style="border-radius: 8px;"/>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 10px;font-weight: 400;color: rgb(97, 98, 102);margin: 4px 0 6px;font-style: italic;">Courtesy</p>
                      <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: rgb(97, 98, 102);margin: 0 0 4px;font-weight: 700;
                      font-size: 10px;color: #2B2E36;text-transform: uppercase;">Attribution text</p>
                      <a style="background: #4D61AA;box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);border-radius: 2px;color: white;font-weight: 700;
                      font-size: 7px;padding: 4px 8px;">WATCH <img src="caret.png" /></a>
                    </div>
                  </div>

                  <hr style="margin: 6px 90px 30px;background-color:#D3D3D3;opacity: 0.3;" />

                  <div style="text-align: center;margin: 12px 0 0;">
                    <button style="background-color: #F1DDCC;padding: 8px 22px;min-width:190px;color:white;border:1px solid #F1DDCC;border-radius: 4px;font-weight: 600;color: #2B2E36;font-size: 10px;">VIEW LATEST EDITION</button>
                  </div>
                </td>
              </tr>

              <tr>
                <td style="background-color: #F0F2F1;padding: 22px 10px 32px;border: none;">
                  <p style="font-family: 'Open Sans', sans-serif;font-size: 14px;font-weight: 400;color: #696969;margin: 0 0 16px;text-align: center;">Copyright©2022 The Sieve Media LLC. All rights reserved.</p>

                  <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: #696969;margin: 0 auto 16px;text-align: center;max-width: 380px;line-height: 1.62;">You are receiving this email because you are part of the Sieve’s
                    membership initiative. To unsubscribe, <a style="color:#4D61AA;text-decoration: underline;">Click here </a></p>
                  
                  <p style="font-family: 'Open Sans', sans-serif;font-size: 12px;font-weight: 400;color: #696969;margin: 0 0 ;text-align: center;text-decoration: underline;">Add us to your address book</p>
                </td>
              </tr>
            </table> */}

            <table className="emailer-table">
              <tr>
                <td>
                  <table width="100%">
                    <tr className="top-td">
                      <td>
                        <p>April 4, 2022</p>
                      </td>
                      <td>
                        <p>
                          Customer Id.: <b>S000101</b>{" "}
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td className="logo-td">
                  <img
                    width="179px"
                    src={require("./../../../assets/images/logo-text.png")}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <div className="email-cards">
                    <hr />

                    <div class="emailer-cards">
                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>

                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>

                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>

                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>

                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>

                      <div>
                        <img
                          src={require("./../../../assets/images/img.png")}
                        />
                        <p>Courtesy</p>
                        <small>Attribution text</small>
                        <a>
                          LISTEN <i className="caret-sm-icon"></i>
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div className="emailer-btn">
                      <button>VIEW LATEST EDITION</button>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="footer-td">
                  <h6>
                    Copyright©2022 The Sieve Media LLC. All rights reserved.
                  </h6>

                  <p>
                    You are receiving this email because you are part of the
                    Sieve’s membership initiative. To unsubscribe,{" "}
                    <a>Click here</a>
                  </p>

                  <a>Add us to your address book</a>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="main-div content-btns mt24">
          <div className="btns-lower">
            <div>
              <button className="btn-white long">
                Preview editor’s Pick on Desktop
              </button>
            </div>
            <div>
              <button className="btn-white long">
                Preview editor’s Pick on Mobile
              </button>
            </div>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading bb">
            <div className="title">
              <h6 className=" fs16">Preview Editor’s Pick on Desktop</h6>
            </div>

            <div className="ml-auto">
              <button className="btn">Back</button>
            </div>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading bb">
            <div className="">
              <h6 className="title">Editor’s Pick Management</h6>
            </div>

            <div className="btn-grp ml-auto">
              <button className="btn">ADD NEW</button>
            </div>
          </div>

          <div className="full">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Publishing Date</TableCell>
                  <TableCell>No. of Content Pieces</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>23 Aug, 2022 - 10:00 AM</TableCell>
                  <TableCell>12</TableCell>
                  <TableCell>
                    <div className="chip-label">Published</div>
                  </TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="edit-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading">
            <div className="title">
              <h6 className="">Referral Partner Managment</h6>
            </div>

            <div className="btn-grp bg-grey">
              <button className="btn">Pending Requests (3)</button>
              <button className="btn-blank">Profile</button>
              <button className="btn-blank">Sales</button>
              <button className="btn-blank">Commission & Payments</button>
            </div>
          </div>

          <div className="referral-form">
            <h5 className="text-center">Referral Partner #1</h5>

            <form>
              <div className="row">
                <div className="col-md-6">
                  <p className="m20 fs16 fw600">
                    YES! I would like to Enroll in the Referral Program
                  </p>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="user-sm-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="First Name" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="user-sm-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="Last Name" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="mail-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="Email" />
                    </div>
                  </div>

                  <div className="form-in  icon grey phone">
                    <div className="f-in">
                      <span className="icon-left">
                        <i className="phone-icon sm"></i>
                      </span>
                      <div className="code">
                        <input type="tel" />
                      </div>
                      <input className="pl" type="text" value="9888999880" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="flag-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="Country" />
                    </div>
                  </div>

                  <div className="d-flexx pt16">
                    <span className="fs14">
                      Are you a member of The Sieve ?
                    </span>

                    <div className="checkbox ml24">
                      <input type="radio" />
                      <label>Yes</label>
                    </div>

                    <div className="checkbox ml16">
                      <input type="radio" />
                      <label>No</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <p className="m20 fs16">Your Social Media Profiles</p>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="linkedin-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="@John.Doe" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="twitter-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="@John.Doe" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="insta-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="@John.Doe" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="facebook-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="@John.Doe" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="mail-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="@John.Doe" />
                    </div>
                  </div>

                  <div className="form-in grey icon">
                    <span className="icon-left">
                      <i className="percentage-icon"></i>
                    </span>
                    <div className="f-in">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Add Commission
                        </InputLabel>
                        <Select id="demo-simple-select" label="Age">
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="col-12 ">
                  <div className="form-in grey icon mt24">
                    <span className="icon-left">
                      <i className="text-icon"></i>
                    </span>
                    <div className="f-in">
                      <TextField label="Message" multiline rows={4} />
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="btns-lower pt24">
              <button className="btn-white">
                REJECT PARTNER AND SEND REJECT EMAIL
              </button>
              <button className="btn ">
                APPROVE PARTNER AND SEND WELCOME EMAIL
              </button>
            </div>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading">
            <div className="">
              <h6 className="title">Referral Partner Managment</h6>
            </div>

            <div className="btn-grp bg-grey">
              <button className="btn-blank">Pending Requests (3)</button>
              <button className="btn">Profile</button>
              <button className="btn-blank">Sales</button>
              <button className="btn-blank">Commission & Payments</button>
            </div>
          </div>

          <div className="full">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Partner ID</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Partner Email ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Profile Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>123421314123432</TableCell>
                  <TableCell>
                    <p className="text-blue fw600">Adison Rhiel Madsen</p>
                  </TableCell>
                  <TableCell>adison123@gmail.com</TableCell>
                  <TableCell>
                    <div className="status-label fill-green">Active</div>
                  </TableCell>
                  <TableCell>
                    <p className="text-red fw500">Pending</p>
                  </TableCell>
                  <TableCell>
                    <div className="table-btns">
                      <button>
                        <i className="view-icon"></i>
                      </button>
                      <button>
                        <i className="edit-icon"></i>
                      </button>
                      <button>
                        <i className="delete-icon"></i>
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="main-div mt24">
          <div className="heading">
            <div className="title">
              <h6 className="">Referral Partner Managment</h6>
            </div>

            <div className="btn-grp bg-grey">
              <button className="btn-blank">Pending Requests (3)</button>
              <button className="btn-blank">Profile</button>
              <button className="btn">Sales</button>
              <button className="btn-blank">Commission & Payments</button>
            </div>
          </div>

          <div className="status-refferal">
            <div className="btn-grp bg-grey">
              <button className="btn-blank">Free Trial Sign-Ups</button>
              <button className="btn">Paid Memberships</button>
            </div>

            <div className="form-in mb0">
              <div className="f-in">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status : All
                  </InputLabel>
                  <Select id="demo-simple-select" label="Status : All">
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="form-in ml-auto">
              <div className="f-in">
                <input type="date" />
              </div>
            </div>
          </div>

          <div className="full light-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>By Type</TableCell>
                  <TableCell>Live </TableCell>
                  <TableCell>From Start</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Professional</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Gift - Professional</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Gift - Student</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>

                <TableRow className="total">
                  <TableCell>Total</TableCell>
                  <TableCell>
                    <b>1300</b>
                  </TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>TOTAL COMMISSION ACCRUED ($)</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>500</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>AVERAGE COMMISSION/USER</TableCell>
                  <TableCell>10%</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
