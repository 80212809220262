import moment from "moment";
import axios from "src/utils/axios";
import Compressor from "compressorjs";

export const isNullEmptyOrUndefined = (value) =>
  value === null || value === undefined || value === "";

export const setSessionStorage = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => sessionStorage.getItem(key);

export const delay = (ms, Callback) => setTimeout(() => Callback(null), ms);

export const passwordRegex =
  /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const validNameRegex = /^[a-zA-Z ]*$/;

export const validMobileNumber = /^\d{5,}$/;
export const isValidEmail = (email = "") => {
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!emailRegex.test(email);
};

export const isValidName = (name = "") => {
  return !!validNameRegex.test(name);
};

export const isValidMobile = (number = "") => {
  return !!validMobileNumber.test(number);
};

export const ValidPhoneNumber = /^\d+$/;

export const firstLetterUpper = (string) => {
  let newString = "";
  if (string) {
    const data = string.split("_");
    data &&
      data.length > 0 &&
      data.forEach((el, index) => {
        newString =
          newString +
          `${index !== 0 ? " " : ""}` +
          el.charAt(0).toUpperCase() +
          el.toLowerCase().slice(1);
      });
  }

  return newString;
};

// CHECK FOR FILE TYPE
export const isValidFileUploaded = (file) => {
  const validExtensions = ["png", "jpeg", "jpg"];
  const fileExtension = file.type.split("/")[1];
  return validExtensions.includes(fileExtension);
};

export const errorMessage = (error) => {
  let message = "";
  if (error?.response && error?.response?.data) {
    message = error.response.data.message
      ? error.response.data.message
      : "Something Went Wrong. Please Try Again Later";
  }
  return message;
};

export const generateQueryString = (queryData) => {
  let query = "";
  Object.keys(queryData).forEach((item) => {
    if (queryData[item]) {
      query = `${query}${item}=${queryData[item]}&`;
    }
  });
  return query;
};

export const dateFormat = (date = "") => {
  let newDate = "";
  if (date) {
    newDate = moment(date).utc().format("D MMM, YYYY - HH:mm");
  }
  return newDate;
};

export const dateAndYearFormat = (date = "") => {
  let newDate = "";
  if (date) {
    newDate = moment(date).utc().format("D MMM, YYYY");
  }
  return newDate;
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const isValidImage = async ({ file, height, width }) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (e) => {
        const file_height = e.target.height;
        const file_width = e.target.width;
        const file_size = file?.size / 1000;
        if (
          file_height !== height ||
          file_width !== width ||
          file_size > 3000
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
    };
    reader.readAsDataURL(file);
  });
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}
export const getUploadResponse = (file) => {
  return new Promise((resolve, reject) => {
    try {
      new Compressor(file, {
        quality: 0.7,
        success: (compressedResult) => {
          let newFile = blobToFile(compressedResult, compressedResult?.name);
          const newFileObj = new FormData();
          // The third parameter is required for server
          newFileObj.append("file", newFile);

          // Send the compressed image file to server with XMLHttpRequest.
          axios
            .post("common/media-upload", newFileObj)
            .then((res) => {
              if (res && res?.data?.statusCode === 200 && res?.data?.data) {
                resolve(res?.data?.data?.image);
              }
              reject(null);
            })
            .catch((e) => {
              resolve(false);
            });
        },
        error: reject,
      });
    } catch (e) {
      console.log("error", e);
    }
  });
};

export const getModule = (moduleName) => {
  const module = getSessionStorage("module");
  if (module) {
    const data = JSON.parse(module);
    const moduleData = data.find((el) => {
      return el.name === moduleName;
    });
    return moduleData;
  }
};

export const isAddAccess = (moduleName) => {
  const userType = getSessionStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.add : false;
};

export const isEditAccess = (moduleName) => {
  const userType = getSessionStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.edit : false;
};

export const isDeleteAccess = (moduleName) => {
  const userType = getSessionStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.delete : false;
};

export const isViewAccess = (moduleName) => {
  const userType = getSessionStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.view : false;
};

export const sendPersonalizedEmail = (email) => {
  window.open(` https://mail.google.com/mail/?view=cm&fs=1&to=${email} `);
};

export const handleShortLink = (edition, link) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC4yovY26TAONTOASQ7SNMF7ULPdqSOYdA`,
        {
          dynamicLinkInfo: {
            domainUriPrefix: "https://thesieve.media/share",
            link: link,
            socialMetaTagInfo: {
              socialTitle: edition?.title || "",
              socialDescription:
                "You can copy and paste your text with the characters to count in the text area above, or you can type your characters and words into the text area. The counter will be updated instantly, displaying to",
              socialImageLink: edition?.mobileImage || "",
            },
          },
          suffix: {
            option: "SHORT",
          },
        }
      )
      .then((res) => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject("Something went wrong while creating a Link");
        }
      })
      .catch(() => {
        reject("Something went wrong while creating a Link");
      });
  });
};

export const splitNameAndCapitalize = (str) => {
  const splitString = str.split(/(?=[A-Z])/);

  const capitalizedString = splitString
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedString;
};

export const roundOff = (num) => {
  return num?.toFixed(2);
};
