import React from "react";
import DataTable from "../Components/DataTable";
import Select from "react-select";
import { ByTypeOptionsSales, StatusOptionsForSales } from "src/constant/global";

const ByType = ({ data, setType, setByStatusWithType }) => {
  return (
    <div className="main-div h-auto ">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Type</h6>
        </div>
      </div>
      <div className="select-option">
        <Select
          options={ByTypeOptionsSales}
          placeholder="Select Type"
          onChange={(data) => {
            setType(data.value);
          }}
        />
        <Select
          options={StatusOptionsForSales}
          onChange={(data) => {
            setByStatusWithType(data.value);
          }}
        />
      </div>
      <DataTable data={data} />
    </div>
  );
};

export default ByType;
