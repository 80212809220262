import React, { useState } from "react";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";
import FreeMemberships from "./FreeMemberships";
import PaidMemberships from "./PaidMemberships";

const SalesManagement = () => {
  const [isFree, setFree] = useState(true);
  const [curPage, setCurrPage] = useState(1);
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [loading, setLoading] = useState(false);

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  return (
    <>
      <div className="main-div h-auto">
        <div className="status-refferal">
          <div className="heading mb0">
            <div className="btn-grp bg-grey">
              <button
                className={`${!isFree ? "btn-blank" : "btn"}`}
                onClick={() => {
                  setFree(true);
                }}
              >
                Free Memberships
              </button>
              <button
                className={`${isFree ? "btn-blank" : "btn"}`}
                onClick={() => {
                  setFree(false);
                }}
              >
                Paid Memberships
              </button>
            </div>
            <div className="form-in ml-auto">
              <div className="f-in date-filter">
                <DateRangePicker
                  setCurrPage={setCurrPage}
                  selectStartDate={selectStartDate}
                  selectEndDate={selectEndDate}
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  minDate={rangeStart}
                  disableEndDate={!rangeStart}
                />
                <button
                  className="btn-white ml12"
                  onClick={() => {
                    clearDates();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {isFree && (
        <FreeMemberships
          setLoading={setLoading}
          startDate={rangeStart}
          endDate={rangeEnd}
        />
      )}
      {!isFree && (
        <PaidMemberships
          setLoading={setLoading}
          startDate={rangeStart}
          endDate={rangeEnd}
        />
      )}
    </>
  );
};

export default React.memo(SalesManagement);
