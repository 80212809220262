import { createSlice } from "@reduxjs/toolkit";
import { getGroupsList } from "src/redux/services/group.service";

const initialState = {
  groupsList: [],
  loading: false,
  count: 0,
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    //get Group List

    [getGroupsList.pending]: (state) => {
      state.loading = true;
    },
    [getGroupsList.fulfilled]: (state, { payload }) => {
       
      state.loading = false;
      state.groupsList = payload?.data;
    },
    [getGroupsList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.groupsList = null;
    },
  },
});

export default groupSlice.reducer;
