import React from "react";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

const DraggbleComp = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
};

export default DraggbleComp;
