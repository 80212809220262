import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
  return (
    <CFooter className="d-flex justify-content-center">
      {/* <div>
        <a href="https://coreui.io" target="_blank" rel="noopener noreferrer">
          Sieve
        </a>
        <span className="ms-1"> Copyright Reserve 2022.</span>
      </div> */}
      {/* <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a
          href="https://coreui.io/react"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </div> */}
    </CFooter>
  );
};

export default React.memo(AppFooter);
