import React, { useEffect, useState } from "react";

import { errorMessage } from "src/helpers";
import { API_MESSAGES } from "src/constant/global";
import axios from "src/utils/axios";
import PreviewDesktopMobile from "./PreviewDesktopMobile";
import { errorAlert } from "src/utils/alerts";

const ViewContent = ({ setViewEditionCard, id }) => {
  const [editionTab, setEditionTab] = useState("web");
  const [fetchedData, setFetchedData] = useState([]);

  const handleTabSwitch = (type) => {
    if (type === "web" && editionTab === "mobile") {
      setEditionTab("web");
    } else if (type === "mobile" && editionTab === "web") {
      setEditionTab("mobile");
    }
  };

  useEffect(() => {
    axios
      .get(`admin/editionDetails?editionId=${id}`)
      .then((res) => {
        if (res?.data?.data) {
          setFetchedData(res?.data?.data);
        }
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data) {
          message = errorMessage(err);
        }

        errorAlert(message || API_MESSAGES.getError);
      });
  }, [id]);

  return (
    <div className="main-div">
      <div className="heading">
        <div className="btn-grp bg-grey">
          <button
            className={editionTab === "web" ? "btn" : "btn-blank"}
            onClick={() => handleTabSwitch("web")}
          >
            Preview edition on Desktop
          </button>
          <button
            className={editionTab === "mobile" ? "btn" : "btn-blank"}
            onClick={() => handleTabSwitch("mobile")}
          >
            Preview edition on Mobile
          </button>
        </div>
        <div className="btn-grp ml-auto">
          <button className="btn" onClick={() => setViewEditionCard(false)}>
            Back
          </button>
        </div>
      </div>

      <PreviewDesktopMobile editionTab={editionTab} fetchedData={fetchedData} />
    </div>
  );
};

export default ViewContent;
