import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true,
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    toggle: (state = initialStateValue) => {
      state.sidebarShow = !state.sidebarShow;
    },
    toggleOff: (state = initialStateValue) => {
      state.sidebarShow = false;
    },
  },
});
export const { toggle, toggleOff } = navSlice.actions;
export default navSlice.reducer;
