import { createSlice } from "@reduxjs/toolkit";
import { getProfileList } from "src/redux/services/referral-partner.service";

const initialState = {
  loading: false,
  profileList: [],
};
const referralPartnerSlice = createSlice({
  name: "referralPartner",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    ///getProfileList

    [getProfileList.pending]: (state) => {
      state.loading = true;
    },
    [getProfileList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profileList = payload.data.data;
      state.message = payload.message;
    },
    [getProfileList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
  },
});
export default referralPartnerSlice.reducer;
