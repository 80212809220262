import React from "react";
import { firstLetterUpper } from "src/helpers";
import DataTable from "../components/DataTable";

const FreetrialRegistrations = ({ data, getFreeData }) => {
  const tableData =
    data &&
    Object.keys(data).length > 0 &&
    Object.keys(data).map((el) => {
      const field = data[el];
      const key = el
        .replace("basic", "basic_")
        .replace("overall", "overall_")
        .replace("marketing", "marketing_")
        .replace("acquisitionCostPerUser", "acquisition_Cost_Per_User");
      return {
        title: firstLetterUpper(key),
        ...field,
      };
    });

  return (
    <div className="main-div h-auto" style={{ marginTop: "10px" }}>
      <div className="heading">
        <div className="title">
          <h6 className="fw600">Free Trial Registrations</h6>
        </div>
      </div>
      <DataTable tableData={tableData} getFreeData={getFreeData} />
    </div>
  );
};

export default FreetrialRegistrations;
