import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GeographyOptions, ReferralOptions } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ReportTable from "../components/ReportFreeTable";
const heading = ["Registered", "Converted", "Expired"];

const Referral_Free_Trial_SignUps = ({
  setLoading,
  startDate,
  endDate,
  activeSalesAgent,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [referralFreeTrialSignUps, setReferralFreeTrialSignUps] = useState();
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);
  const [salesAgentId, setSalesAgentId] = useState("");

  useEffect(() => {
    getReferralFreeTrialSignUps();
  }, [byGeography, salesAgentId]);

  useEffect(() => {
    getReferralFreeTrialSignUps();
  }, [endDate, startDate]);

  const getReferralFreeTrialSignUps = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-free-trial-signups?byGeography=${byGeography}&isSendEmailReport=${isSendEmailReport}`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    if (salesAgentId) {
      url = url + `&salesAgentId=${salesAgentId}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setReferralFreeTrialSignUps(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getReferralFreeTrialSignUps(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Referral Free Trial Sign-Ups</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          options={GeographyOptions}
          defaultValue={GeographyOptions[0]}
          onChange={(data) => {
            setGeography(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralFreeTrialByGeography: data.value,
            });
          }}
        />

        <Select
          options={activeSalesAgent}
          defaultValue={activeSalesAgent && activeSalesAgent[0]}
          getOptionLabel={(data) => {
            return data.email;
          }}
          getOptionValue={(data) => {
            return data._id;
          }}
          onChange={(data) => {
            setSalesAgentId(data._id);
            setDataForSendReport({
              ...dataForSendReport,
              salesAgentIdForReferralFreeTrial: data.value,
            });
          }}
        />
      </div>

      <div>
        <ReportTable heading={heading} data={referralFreeTrialSignUps} />
      </div>
    </div>
  );
};

export default Referral_Free_Trial_SignUps;
