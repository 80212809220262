import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Alerts = ({
  type,
  open,
  message,
  onClose,
  vertical,
  horizontal,
  ...rest
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={500}
        onClose={onClose}
      >
        <Alert severity={type} {...rest} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Alerts;
