import React, { useState, useEffect } from "react";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import moment from "moment-timezone";
import Totals from "./Totals";
import TransactionDetails from "./TransactionDetails";
import Loader from "src/components/common/loaders";

const BillingAndPayments = () => {
  const [loading, setLoading] = useState(false);
  const [billingData, setBillingData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [monthYearBill, setMonthYearBill] = useState(new Date());
  const [monthYearPay, setMonthYearPay] = useState(new Date());

  useEffect(() => {
    fetchBillings();
    fetchPayments();
  }, []);

  useEffect(() => {
    fetchBillings();
  }, [monthYearBill]);

  useEffect(() => {
    fetchPayments();
  }, [monthYearPay]);

  const fetchBillings = () => {
    setLoading(true);
    const month = moment(monthYearBill).month();
    const year = moment(monthYearBill).year();
    customAxios
      .get(
        `admin/billing-payments/total-billings-payments?billingMonth=${month}&billingYear=${year}&type=1`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          setBillingData(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const fetchPayments = () => {
    setLoading(true);
    const month = moment(monthYearPay).month();
    const year = moment(monthYearPay).year();
    customAxios
      .get(
        `admin/billing-payments/total-billings-payments?billingMonth=${month}&billingYear=${year}&type=2`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          const data = {
            ...res?.data?.data,
            stripeCharges: 0,
            difference: res?.data?.data?.systemBilling,
          };
          setPaymentData(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error?.response?.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };
  return (
    <div className="row table-reports">
      {loading && <Loader />}
      <Totals
        isPayments={false}
        monthYear={monthYearBill}
        setMonthYear={setMonthYearBill}
        data={billingData}
      />
      <Totals
        isPayments={true}
        monthYear={monthYearPay}
        setMonthYear={setMonthYearPay}
        data={paymentData}
        setPaymentData={setPaymentData}
      />
      <TransactionDetails />
    </div>
  );
};

export default BillingAndPayments;
