import React, { useMemo, useState } from "react";
import { Dialog, DialogContent, TextField } from "@mui/material";
import DraggbleComp from "src/components/common/draggble";
import CancelButton from "src/components/common/modal-cancel-btn";
import { firstLetterUpper } from "src/helpers";
import moment from "moment-timezone";
import Select from "react-select";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

export const statusOptions = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "PROCESSED",
    value: "PROCESSED",
  },
];

const Details = ({
  details,
  setOpen,
  open,
  getPendingCancellationRequest,
  getInReviewList,
}) => {
  const [status, setStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const data = useMemo(() => {
    return {
      status: details?.refundStatus,
      firstName: details?.userDetails?.firstName,
      lastName: details?.userDetails?.lastName,
      customerId: details?.userDetails?.customerId,
      email: details?.userDetails?.email,
      lastPaymentDate: details?.transactionDetails?.createdAt,
      transactionId: details?.transactionDetails?.transactionId || "-",
      refundAmount: details?.transactionDetails?.refundAmount,
      refundStatus: details?.refundStatus,
      planType: details?.subscriptionDetails?.planType || "-",
      planName: details?.subscriptionDetails?.planName || "-",
      amount: details?.transactionDetails?.amount || "-",
      paymentValidTill: details?.subscriptionDetails?.paymentValidTill,
    };
  }, [details]);

  const handleUpdate = () => {
    const dataToSend = {
      requestId: details?._id,
      refundStatus: status,
    };
    customAxios
      .put("admin/cancel-refund/update-status", {
        ...dataToSend,
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          successAlert(response?.data?.message);
          getInReviewList();
          getPendingCancellationRequest();
          setOpen(false);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperComponent={DraggbleComp}
      aria-labelledby="draggable-dialog-title"
    >
      <div className="modal-text refund-modal">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>
        <DialogContent>
          <div>
            <div className="membership-div">
              <div className="heading">
                <h6 className="fs18 mb0">{`Cancellation & Refund Details`}</h6>
                <p
                  className={`ml-auto ${
                    data?.status === "PENDING" ? "red" : "text-blue"
                  } fw600`}
                >
                  {data?.status ? firstLetterUpper(data?.status) : ""}
                </p>
              </div>
              <div className="">
                <ul className="user-info fifty">
                  <li>
                    <small>First Name</small>
                    <p>{data?.firstName || "-"}</p>
                  </li>

                  <li>
                    <small>Last Name</small>
                    <p>{data?.lastName || "-"}</p>
                  </li>
                  <li>
                    <small>Customer ID</small>
                    <p>{data?.customerId || "-"}</p>
                  </li>
                  <li>
                    <small>Email ID</small>
                    <p>{data?.email || "-"}</p>
                  </li>
                </ul>
              </div>
            </div>
            {
              <div className="membership-div">
                <div className="heading">
                  <h6 className="fs18 mb0">{`Cancellation Details`}</h6>
                </div>
                <div className="">
                  <ul className="user-info">
                    <li>
                      <small>Date of Last Payment</small>
                      <p>
                        {data?.lastPaymentDate
                          ? moment(data?.lastPaymentDate).format("DD MMM, YYYY")
                          : "-"}
                      </p>
                    </li>

                    <li>
                      <small>Transaction ID</small>
                      <p>{data?.transactionId || "-"}</p>
                    </li>

                    <li>
                      <small>Amount</small>
                      <p>{`${data?.amount || "-"}`}</p>
                    </li>

                    <li>
                      <small>Membership Type</small>
                      <p>
                        {data?.planName
                          ? firstLetterUpper(data?.planName)
                          : "-"}
                      </p>
                    </li>
                    <li>
                      <small>Payment Plan</small>
                      <p>
                        {data?.planType
                          ? firstLetterUpper(data?.planType)
                          : "-"}
                      </p>
                    </li>
                    <li>
                      <small>Payment Valid Till</small>
                      <p>
                        {data?.paymentValidTill
                          ? moment(data?.paymentValidTill).format(
                              "DD MMM, YYYY"
                            )
                          : "-"}
                      </p>
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="fw600 fs16">Refund Amount</p>
                      <hr />
                      <div className="form-in icon">
                        <label>Refund Amount</label>
                        <div className="f-in">
                          <span className="icon-left">
                            <i className="dollar-icon"></i>
                          </span>
                          <TextField
                            type="text"
                            value={`${data?.refundAmount}`}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    {data?.status === "PENDING" && (
                      <div className="col-md-6">
                        <div className="form-in">
                          <p className="fw600 fs16">Refund On Stripe Status</p>
                          <hr />
                          <label>Select Status</label>
                          <div className="f-in">
                            <Select
                              defaultValue={statusOptions.find((option) => {
                                return option.value === data.status;
                              })}
                              onChange={(data) => {
                                setStatus(data.value);
                              }}
                              options={statusOptions}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {data?.status === "PENDING" && (
                  <div className="btn-grp text-center pt32">
                    <button
                      className="btn-white"
                      onClick={() => {
                        window.open("https://stripe.com");
                      }}
                    >
                      REFUND ON STRIPE
                    </button>
                    <button
                      className="btn-white"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      SEND PERSONALIZED EMAIL
                    </button>
                    <button className="btn ml-auto" onClick={handleUpdate}>
                      UPDATE
                    </button>
                  </div>
                )}
              </div>
            }
          </div>
        </DialogContent>
      </div>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        email={data?.email}
      />
    </Dialog>
  );
};

export default Details;
