import React from "react";

const Action = (props) => {
  const {
    setEditContentId,
    handleViewClick,
    id,
    setIsDelete,
    setDeleteId,
    isEdit = true,
    isDelete = true,
    isView = true,
    isStatus = false,
    isBlock = false,
    handleStatusChange,
  } = props;

  return (
    <div className="table-btns">
      {isView && (
        <button onClick={() => handleViewClick(id)}>
          <i className="view-icon"></i>
        </button>
      )}
      {isEdit && (
        <button onClick={() => setEditContentId(id)}>
          <i className="edit-icon"></i>
        </button>
      )}
      {isDelete && (
        <button
          onClick={() => {
            setDeleteId(id);
            if (setIsDelete) {
              setIsDelete(true);
            }
          }}
        >
          <i className="delete-icon"></i>
        </button>
      )}
      {isStatus && (
        <button
          onClick={() => {
            handleStatusChange(id);
          }}
        >
          <i className={`${isBlock ? "block-icon" : "lock-icon"}`}></i>
        </button>
      )}
    </div>
  );
};

export default Action;
