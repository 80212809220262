import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import DraggbleComp from "src/components/common/draggble";
import CancelButton from "src/components/common/modal-cancel-btn";
import { format } from "date-fns";
import { firstLetterUpper } from "src/helpers";

const ComplimentaryDetail = ({ memberData, open, setOpen }) => {
  return (
    <>
      <Dialog
        open={open}
        // className={classFilter}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={DraggbleComp}
        aria-labelledby="draggable-dialog-title"
      >
        <div className="modal-text basic-modal">
          <div className="close-btn">
            <CancelButton
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
          <DialogContent>
            <div className="membership-div">
              <h6 className="fs18 mb12">{` Complimentary Membership Details`}</h6>
              {/* <p>{data?.profileStatus}</p> */}
              <div className="">
                <ul className="user-info">
                  <li>
                    <small>First Name</small>
                    <p>{memberData?.firstName || "-"}</p>
                  </li>

                  <li>
                    <small>Last Name</small>
                    <p>{memberData?.lastName || "-"}</p>
                  </li>

                  <li>
                    <small>Mobile Number</small>
                    <p>{`+${memberData?.countryCode} - ${memberData?.contactNumber} `}</p>
                  </li>

                  <li>
                    <small>Email ID</small>
                    <p>{memberData?.email || "-"}</p>
                  </li>
                  <li>
                    <small>Membership Type</small>
                    <p>{"Complimentary"}</p>
                  </li>
                  <li>
                    <small>Status</small>
                    <p>
                      {memberData?.complimentaryMembershipStatus
                        ? firstLetterUpper(
                            memberData?.complimentaryMembershipStatus
                          )
                        : "-"}
                    </p>
                  </li>
                  <li>
                    <small>Customer ID </small>
                    <p>{memberData?.customerId}</p>
                  </li>
                  <li>
                    <small>Start Date</small>
                    <p>
                      {" "}
                      {memberData?.complimentaryMembershipStartDate &&
                        format(
                          new Date(
                            memberData?.complimentaryMembershipStartDate
                          ),
                          "dd MMM, yyyy"
                        )}
                    </p>
                  </li>
                  <li>
                    <small>End Date</small>
                    <p>
                      {" "}
                      {memberData?.complimentaryMembershipEndDate &&
                        format(
                          new Date(memberData?.complimentaryMembershipEndDate),
                          "dd MMM, yyyy"
                        )}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default ComplimentaryDetail;
