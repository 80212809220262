import React, { useEffect, useState } from "react";
import Select from "react-select";
import { editionFilter } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";

const ContentClicksByEdition = ({
  setLoading,
  dataForSendReport,
  setDataForSendReport,
}) => {
  const [filterBy, setFilterBy] = useState(editionFilter[0].value);
  const [contentClicks, setContentClicks] = useState();

  useEffect(() => {
    getContentClicks();
  }, [filterBy]);

  const getContentClicks = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/content-clicks-by-edition-date?filterBy=${filterBy}&isSendEmailReport=${isSendEmailReport}`;

    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setContentClicks(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getContentClicks(true);
  };

  return (
    <div className="main-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Content Clicks By Edition Date</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option ">
        <Select
          options={editionFilter}
          defaultValue={editionFilter[0]}
          onChange={(data) => {
            setFilterBy(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              contentClicksFilterBy: data.value,
            });
          }}
        />
      </div>
      <div className="content-flex">
        <p>
          Total Content Pieces
          <b>{contentClicks && contentClicks?.totalContentPieces}</b>
        </p>
        <p>
          Redirection Links
          <b>{contentClicks && contentClicks?.redirectionLink}</b>
        </p>
      </div>
    </div>
  );
};

export default ContentClicksByEdition;
