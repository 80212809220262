import React from "react";
import ModalComp from "src/components/common/modal";
import {
  ENABLE_MEBERSHIP_RATE,
  DISABLE_MEBERSHIP_RATE,
} from "src/constant/modals";

const EnableDisableRatePopup = ({
  isOpen,
  setOpen,
  handleMembershipStatus,
  isMembershipStatus,
}) => {
  return (
    <ModalComp
      open={isOpen}
      title="Confirmation"
      setOpen={() => setOpen(false)}
      content={
        isMembershipStatus ? DISABLE_MEBERSHIP_RATE : ENABLE_MEBERSHIP_RATE
      }
      onClickYes={() => {
        handleMembershipStatus();
        setOpen(false);
      }}
    />
  );
};

export default EnableDisableRatePopup;
