import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT, MAX_PAGE_LIMIT_IN_SLIDER } from "src/constant/global";
import { getProfileList } from "src/redux/services/referral-partner.service";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import PendingRequests from "./pending-requests";
import Profile from "./profile";
import EditProfile from "./profile/components/editprofile";
import Sales from "./sales";
import CommissionPayments from "./commission-payments";
import SalesAgentsCountsList from "./sales/components/SalesAgentsCountsList";
import SalesAgentsCountsListForMembershipUsers from "./sales/components/SalesAgentsCountsListForMembershipUsers";

const ReferralPartnerManagement = () => {
  const [selectedTab, setSelectedTab] = useState("pending");
  const [pendingRequestList, setPendingRequestList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [profileList, setProfileList] = useState([]);
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [profileToEdit, setProfileToEdit] = useState(-1);
  const [coupons, setCoupons] = useState([]);
  const [isFree, setFree] = useState(true);
  const [salesData, setSalesData] = useState();

  const [listStartDate, setListStartDate] = React.useState(null);
  const [listEndDate, setListEndDate] = React.useState(null);

  const [signupsStartDate, setSignupsStartDate] = React.useState(null);
  const [signupsEndDate, setSignupsEndDate] = React.useState(null);

  const dispatch = useDispatch();
  const { loading: isLoading, profileList } = useSelector((state) => {
    return state.referralPartner;
  });

  useEffect(() => {
    if (selectedTab === "sales") {
      const dataToSent = {
        type: isFree ? "FREE_TRAIL" : "PAID",
        signUpsMembershipStatus: "ALL",
        listMembershipStatus: "ALL",
        listMembershipType: "ALL",
      };
      if (isFree) {
        delete dataToSent.signUpsMembershipStatus;
        delete dataToSent.listMembershipStatus;
        delete dataToSent.listMembershipType;
      }
      getSalesAgentDetail(dataToSent);
    }
  }, [isFree, selectedTab]);

  useEffect(() => {
    getReferralRequests();
    getCoupons();
  }, []);

  useEffect(() => {
    getProfile();
  }, [curPage, profileToEdit]);

  const getReferralRequests = ({ pageNo = 1, isAddSliderData } = {}) => {
    let url = `admin/sales-agent/sales-agents-list?limit=${MAX_PAGE_LIMIT_IN_SLIDER}&pageNo=${pageNo}&requestStatus=PENDING`;
    setLoading(true);
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const pendingRequest = !isAddSliderData
            ? response?.data?.data?.data
            : pendingRequestList.concat(response?.data?.data?.data);
          setPendingRequestList(pendingRequest);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getProfile = () => {
    let url = `admin/sales-agent/sales-agents-list?limit=${MAX_PAGE_LIMIT}&pageNo=${curPage}&requestStatus=APPROVED`;

    dispatch(
      getProfileList({
        url,
        callback: (res) => {
          const totalPagesCount =
            res.data.total && res.data.total > 0
              ? Math.ceil(res.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        },
      })
    );
  };

  const getCoupons = () => {
    setLoading(true);
    customAxios
      .get(`admin/list-coupons?status=ACTIVE`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setCoupons(response?.data?.data?.result);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getSalesAgentDetail = ({
    type,
    signupsStartDate = null,
    signupsEndDate = null,
    listStartDate = null,
    listEndDate = null,
    signUpsMembershipStatus,
    listMembershipStatus,
    listMembershipType,
  }) => {
    setLoading(true);
    let url = `admin/sales-agent/sales?type=${type}`;
    if (signupsStartDate !== null && signupsEndDate !== null) {
      url = `${url}&signupsStartDate=${signupsStartDate}&signupsEndDate=${signupsEndDate}`;
    }
    if (listStartDate !== null && listEndDate !== null) {
      url = `${url}&listStartDate=${listStartDate}&listEndDate=${listEndDate}`;
    }
    if (signUpsMembershipStatus) {
      url = `${url}&signUpsMembershipStatus=${signUpsMembershipStatus}`;
    }
    if (listMembershipStatus) {
      url = `${url}&listMembershipStatus=${listMembershipStatus}`;
    }
    if (listMembershipType) {
      url = `${url}&listMembershipType=${listMembershipType}`;
    }
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setSalesData(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      {(loading || isLoading) && <Loader />}
      {profileToEdit === -1 && (
        <>
          <div className="main-div h-auto">
            <div className="heading">
              <div className="title">
                <h6 className="">Referral Partner Management</h6>
              </div>

              <div className="btn-grp bg-grey">
                <button
                  className={` ${
                    selectedTab === "pending" ? "btn" : "btn-blank"
                  }`}
                  onClick={() => setSelectedTab("pending")}
                >
                  Pending Requests
                  {pendingRequestList &&
                    pendingRequestList.length > 0 &&
                    `(${pendingRequestList.length})`}
                </button>
                <button
                  className={` ${
                    selectedTab === "profile" ? "btn" : "btn-blank"
                  }`}
                  onClick={() => setSelectedTab("profile")}
                >
                  Profile
                </button>
                <button
                  className={` ${
                    selectedTab === "sales" ? "btn" : "btn-blank"
                  }`}
                  onClick={() => setSelectedTab("sales")}
                >
                  Sales
                </button>
                <button
                  className={` ${
                    selectedTab === "payments" ? "btn" : "btn-blank"
                  }`}
                  onClick={() => setSelectedTab("payments")}
                >
                  Commission & Payments
                </button>
              </div>
            </div>

            {selectedTab === "pending" && (
              <PendingRequests
                pendingRequestList={pendingRequestList}
                getReferralRequests={getReferralRequests}
                getProfile={getProfile}
                coupons={coupons}
                pageNo={curPage}
                setPage={setCurrPage}
              />
            )}
            {selectedTab === "profile" && (
              <Profile
                profileList={profileList}
                curPage={curPage}
                totalCount={totalCount}
                setCurrPage={setCurrPage}
                setProfileToEdit={setProfileToEdit}
                profileToEdit={profileToEdit}
              />
            )}
            {selectedTab === "sales" && (
              <Sales
                salesData={salesData}
                isFree={isFree}
                setFree={setFree}
                getSalesAgentDetail={getSalesAgentDetail}
                signupsStartDate={signupsStartDate}
                signupsEndDate={signupsEndDate}
                setSignupsEndDate={setSignupsEndDate}
                setSignupsStartDate={setSignupsStartDate}
                listEndDate={listEndDate}
                listStartDate={listStartDate}
              />
            )}
            {selectedTab === "payments" && <CommissionPayments />}
          </div>
          {selectedTab === "sales" && isFree && (
            <SalesAgentsCountsList
              getSalesAgentDetail={getSalesAgentDetail}
              listStartDate={listStartDate}
              setListStartDate={setListStartDate}
              listEndDate={listEndDate}
              setListEndDate={setListEndDate}
              salesAgentsCountsList={
                (salesData && salesData?.salesAgentsCountsList) || []
              }
              signupsEndDate={signupsEndDate}
              signupsStartDate={signupsStartDate}
            />
          )}
          {selectedTab === "sales" && !isFree && (
            <SalesAgentsCountsListForMembershipUsers
              data={
                salesData && salesData?.salesAgentsCountsListForMembershipUsers
              }
              getSalesAgentDetail={getSalesAgentDetail}
              listStartDate={listStartDate}
              setListStartDate={setListStartDate}
              listEndDate={listEndDate}
              setListEndDate={setListEndDate}
              signupsEndDate={signupsEndDate}
              signupsStartDate={signupsStartDate}
            />
          )}
        </>
      )}
      {profileToEdit !== -1 && (
        <EditProfile
          setProfileToEdit={setProfileToEdit}
          profile={
            profileList && profileList.length > 0 && profileList[profileToEdit]
          }
        />
      )}
    </>
  );
};

export default ReferralPartnerManagement;
