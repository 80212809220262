import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import Pagination from "@mui/material/Pagination";
import Action from "src/components/common/Action";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import ViewProfile from "./components/viewProfile";
import { errorAlert } from "src/utils/alerts";

const heading = [
  "Sr. No.",
  "Partner ID",
  "Partner Name",
  "Partner Email ID",
  "Status",
  "Profile Status",
  "Action",
];

const Profile = ({
  profileList,
  curPage,
  totalCount,
  setCurrPage,
  setProfileToEdit,
}) => {
  const [open, setOpen] = useState(false);
  const [profileToView, setProfileToView] = useState(-1);

  return (
    <>
      <div className="full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h) => {
                  return <TableCell align="left">{h}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {profileList &&
              profileList.length > 0 &&
              profileList.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>{item?.partnerId}</TableCell>
                    <TableCell>
                      <p className="text-blue fw600">{item?.firstName}</p>
                    </TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>
                      <div
                        className={`status-label ${
                          item?.status === "ACTIVE" ? "fill-green" : ""
                        } `}
                      >
                        {item?.status && firstLetterUpper(item?.status)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <p
                        className={`fw500 ${
                          item?.profileStatus === "PENDING"
                            ? "text-red"
                            : "text-blue"
                        }`}
                      >
                        {item?.profileStatus &&
                          firstLetterUpper(item?.profileStatus)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <Action
                        id={item._id}
                        isDelete={false}
                        handleViewClick={() => {
                          setOpen(true);
                          setProfileToView(index);
                        }}
                        setEditContentId={(id) => {
                          const isEdit = isEditAccess(
                            "REFERRAL_PARTNER_MANAGEMENT"
                          );
                          if (isEdit) {
                            setProfileToEdit(index);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>

      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {profileList && profileList.length === 0 && (
        <div className="no-content">
          <h6>No Profile Approved Yet</h6>
          {/* <p>
            You haven’t added anything yet. Click on Add New button to add new
            Sub Admin
          </p> */}
        </div>
      )}
      <ViewProfile
        open={open}
        setOpen={setOpen}
        data={
          profileList && profileList.length > 0 && profileList[profileToView]
        }
      />
    </>
  );
};

export default Profile;
