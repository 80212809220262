import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSessionStorage, setSessionStorage } from "src/helpers";
import { logout } from "src/redux/services/auth.service";
import { errorAlert, successAlert } from "src/utils/alerts";
import { SocketContext } from "src/utils/sockets";
import { AppSidebar, AppFooter, AppHeader } from "../header";

const DefaultLayout = ({ component }) => {
  const socket = useContext(SocketContext);
  const token = getSessionStorage("authToken");
  const dispatch = useDispatch();

  const { userType } = useSelector((state) => {
    return state.auth.data;
  });

  useEffect(() => {
    if (socket) {
      socket.connect();
    }
    return () => socket && socket.disconnect();
  }, []);

  const handleLogout = () => {
    dispatch(
      logout({
        token,
        callback: (res) => {
          if (res) {
            if (res?.statusCode === 200) {
              navigate("/");
            }
          }
        },
      })
    );
  };

  useEffect(() => {
    if (socket && userType) {
      const userId = getSessionStorage("userId");
      if (userType !== "ADMIN") {
        socket.emit("sub-admin-listen", userId);
        socket.on("SUB_ADMIN_BLOCKED", (res) => {
          errorAlert("You Have Been Blocked. Please Contact Admin");
          setTimeout(() => {
            handleLogout();
          }, 2000);
        });
        socket.on("SUB_ADMIN_PERMISSIONS_UPDATE", (res) => {
          successAlert("Your Permissions Have Been Changed By Admin");
          setSessionStorage("module", JSON.stringify(res.modules));
        });
      }
    }
  }, [userType]);
  return (
    <div>
      <div>
        <AppHeader />
      </div>
      <div className="All-in dashboardSection">
        <AppSidebar />
        <section className="mainSection">
          <div className="main-in">{component}</div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
};

export default DefaultLayout;
