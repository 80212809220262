import { createSlice } from "@reduxjs/toolkit";
import { getMembershipList } from "../../services/membershipRates.service";

const initialState = {
  loading: false,
  message: "",
  data: [],
  isImagesDragged: false,
};

const membershipRatesSlice = createSlice({
  name: "membershipRates",
  initialState,
  extraReducers: {
    // setContent
    [getMembershipList.pending]: (state) => {
      state.loading = true;
    },
    [getMembershipList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data;
      state.message = payload?.data?.message;
    },
    [getMembershipList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.data = null;
    },
  },
});

export default membershipRatesSlice.reducer;
