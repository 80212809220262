import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders/index.js";
import { errorAlert } from "src/utils/alerts.js";
import customAxios from "src/utils/axios.js";
import ContentClicks from "./ContentClicks/index.js";
import Counts from "./Counts/index.js";
import EffectiveConversions from "./EffectiveConvsersions/index.js";
import FreetrialRegistrations from "./FreetrialRegistrations/index.js";
import NetRevenue from "./NetRevenue/index.js";
import NewPaidMemberships from "./NewPaidMemberships/index.js";
import TotalMemberships from "./TotalMemberships/index.js";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [memberships, setMemberships] = useState();
  useEffect(() => {
    setLoading(true);
    getFreeData();
    getMembershipData();
  }, []);

  const getMembershipData = () => {
    customAxios
      .get(`admin/dashboard/membership-data`)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          setMemberships(res.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getFreeData = () => {
    customAxios
      .get(`admin/dashboard/freetrial-registrations-and-counts`)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          setDashboardData(res.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <div className="main-div h-auto ">
        <div>
          <div className="title">
            <h6 className="">Dashboard</h6>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <Counts data={dashboardData && dashboardData?.topCounts} />
      <FreetrialRegistrations
        getFreeData={getFreeData}
        data={dashboardData && dashboardData?.freeTrailRegistrations}
      />
      <NewPaidMemberships
        data={memberships && memberships?.membershipRegistrations}
        getFreeData={getFreeData}
      />
      <TotalMemberships
        data={memberships && memberships?.totalMembershipRegistrations}
        getFreeData={getFreeData}
      />
      <NetRevenue
        data={dashboardData && dashboardData?.netRevenue}
        getFreeData={getFreeData}
      />
      <ContentClicks
        data={dashboardData && dashboardData?.totalContentClicks}
        getFreeData={getFreeData}
      />
      <EffectiveConversions />
    </>
  );
};

export default Dashboard;
