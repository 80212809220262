import React from "react";
import DataTable from "../Components/DataTable";

const Registration = ({ data }) => {
  return (
    <div className="main-div h-auto">
      <h6>Registrations</h6>
      <DataTable data={data} />
    </div>
  );
};

export default Registration;
