import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { TableFooter } from "@mui/material";
import UpdateCommission from "./UpdateCommission";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import { firstLetterUpper, dateAndYearFormat } from "src/helpers";

const heading = [
  "Sr. No.",
  "Partner Details",
  "Enroll Date",
  "USD from Start",
  "USD",
];
const CommissionTable = ({
  list = [],
  action,
  currPage,
  setCurrPage,
  totalDate,
  totalStart,
  totalCount,
  getCommissionPayments,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [updateItem, setUpdateItem] = useState({});

  return (
    <>
      <div className="full table-sec mb24 comp-table ">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, i) => {
                  return (
                    <>
                      <TableCell align="left" key={i}>
                        {h}
                      </TableCell>
                    </>
                  );
                })}
              {heading && heading.length > 0 && action && (
                <TableCell align="left">Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list?.data &&
              list?.data?.length > 0 &&
              list?.data?.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>
                      {(currPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>

                    <TableCell>
                      <p>{item?.name ? firstLetterUpper(item?.name) : "-"}</p>
                      <span>#{item?.partnerId}</span>
                      <p></p>
                    </TableCell>
                    <TableCell>
                      {" "}
                      {item?.enrollDate
                        ? dateAndYearFormat(item?.enrollDate)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.amountFromStart ? item?.amountFromStart : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.amountWithDateFilter
                        ? item?.amountWithDateFilter
                        : "-"}
                    </TableCell>
                    {action && (
                      <TableCell>
                        <button
                          className="btn sm"
                          onClick={() => {
                            setOpen(true);
                            setUpdateItem(item);
                          }}
                        >
                          UPDATE
                        </button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
          {list && list?.data && list?.data?.length > 0 && (
            <TableFooter>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalStart ? totalStart : ""}</TableCell>
              <TableCell>{totalDate ? totalDate : ""}</TableCell>
            </TableFooter>
          )}
        </Table>
      </div>
      {open && (
        <UpdateCommission
          open={isOpen}
          title="Confirmation"
          setOpen={() => setOpen(false)}
          updateItem={updateItem}
          getCommissionPayments={getCommissionPayments}
        />
      )}
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={currPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {list && list?.data && list?.data?.length === 0 && (
        <div className="no-content">
          <h6>No Records Found Yet</h6>
        </div>
      )}
    </>
  );
};

export default CommissionTable;
