import React from "react";
import axios from "axios";
import { getSessionStorage } from "../helpers";
import appConfig from "../appConfig";
import { setSessionStorage } from "src/helpers";
import { errorAlert } from "./alerts";
const customAxios = axios.create({
  baseURL: `${appConfig.REACT_APP_API_BASE_URL}`,
  // baseURL: `https://sieveapistg.appskeeper.in/api/v1/`,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    platform: "3",
  },
});

const requestHandler = (request) => {
  const token = getSessionStorage("authToken") || "";

  if (token) {
    request.headers[`authorization`] = `Bearer ${token || ""}`;
  }

  return request;
};

function logoutHandler(message) {
  setSessionStorage("authToken", "");
  setSessionStorage("userType", "");
  setSessionStorage("userId", "");
  setSessionStorage("module", "");
  errorAlert(message);
  window.location.href = "/";
}

const responseHandler = (response) => {
  if (response.data?.statusCode === 401) {
    logoutHandler(response?.data?.message);
  }

  return response;
};

const errorHandler = (error) => {
  if (error.response?.status === 401) {
    logoutHandler(error?.response?.statusText);
  }
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;

export const axiosBasicPost = async ({ path, data }) => {
  const response = await axios.post(path, data, {
    headers: {
      "Content-Type": "application/json",
      deviceid: "abc",
      platform: "3",
    },
    auth: {
      username: process.env.REACT_APP_API_BASIC_USERNAME,
      password: process.env.REACT_APP_API_BASIC_PASSWORD,
    },
  });
  return response;
};
