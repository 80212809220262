import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "src/components/common/Button";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import { getSubAdminList } from "src/redux/services/sub-admin.service";
import AddNewSubAdmin from "./add-new-sub-admin";
import EDITSUBADMIN from "./edit-sub-admin";
import SubAdminList from "./sub-admin-list";

const SubAdminManagement = () => {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [curPage, setCurrPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [subAdminToEdit, setSubAdminToEdit] = useState("");

  const dispatch = useDispatch();
  const { loading, subAdminList } = useSelector((state) => {
    return state.subAdmin;
  });

  useEffect(() => {
    fetchSubAdminList();
  }, [curPage, searchKey, addNew, edit]);

  const fetchSubAdminList = () => {
    let url = `admin/sub-admin-list`;

    let data = {
      limit: MAX_PAGE_LIMIT,
      pageNo: curPage,
    };

    if (searchKey) {
      data = {
        ...data,
        searchKey: searchKey.trim(),
      };
      setCurrPage(1);
    }

    dispatch(
      getSubAdminList({
        url,
        data,
        callback: (res) => {
          const totalPagesCount =
            res.data.total && res.data.total > 0
              ? Math.ceil(res.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        },
      })
    );
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <>
      {!addNew && !edit && (
        <div className="main-div">
          <div className="heading">
            <div className="title">
              <h6>Sub Admin Management</h6>
            </div>

            <div className="sub-admin-search">
              <div className="form-in icon">
                <span className="icon-left">
                  <i className="search-icon"></i>
                </span>
                <DebounceInput
                  placeholder="Search By Name, Email Address.."
                  onChange={handleSearch}
                  debounceTimeout={500}
                  minLength={3}
                />
              </div>
            </div>

            <div className="ml-div">
              <div>
                <Button
                  text="+ ADD NEW"
                  onHandleClick={() => {
                    setAddNew(true);
                  }}
                />
              </div>
            </div>
          </div>

          {loading && <Loader />}
          <SubAdminList
            list={subAdminList}
            curPage={curPage}
            totalCount={totalCount}
            setCurrPage={setCurrPage}
            setEdit={setEdit}
            setSubAdminToEdit={setSubAdminToEdit}
            fetchSubAdminList={fetchSubAdminList}
            searchKey={searchKey}
          />
        </div>
      )}
      {addNew && <AddNewSubAdmin setAddNew={setAddNew} />}
      {edit && (
        <EDITSUBADMIN setEdit={setEdit} subAdminToEdit={subAdminToEdit} />
      )}
    </>
  );
};

export default SubAdminManagement;
