import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  forgotPassword,
  updatePassword,
  resetPassword,
  verifyOtp,
  resendOtp,
} from "../../services/auth.service";

const initialState = {
  message: "",
  data: {},
  auth: false,
  loading: false,
  status: 0,
  otp: null,
  currentUserEmail: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOtp(state, action) {
      state.otp = action.payload;
    },
    setUserEmail(state, action) {
      state.currentUserEmail = action.payload;
    },
    reset() {
      return {
        ...initialState,
      };
    },
    logout() {
      // From here we can take action only at this "counter" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
  },
  extraReducers: {
    // login user
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data?.data;
      state.message = payload?.data?.message;
      state.status = payload?.data?.status;
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.status = payload?.status;
      state.data = null;
    },
    // Forgot password
    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
      state.status = payload?.status;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.data = {};
      state.status = payload?.status;
    },

    // Verify OTP
    [verifyOtp.pending]: (state) => {
      state.loading = true;
    },
    [verifyOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
      state.status = payload?.status;
    },
    [verifyOtp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.data = {};
      state.status = payload?.status;
    },

    //Resend OTP
    [resendOtp.pending]: (state) => {
      state.loading = true;
    },
    [resendOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
      state.status = payload?.status;
    },
    [resendOtp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.data = {};
      state.status = payload?.status;
    },

    // Reset password
    [resetPassword.pending]: (state) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
      state.status = payload?.data?.status;
      state.data = payload?.data?.data;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.status = payload?.status;
      state.data = {};
    },
    // Update password
    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
      state.status = payload?.data?.status;
      state.data = payload?.data?.data;
    },
    [updatePassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.message;
      state.data = {};
      state.status = payload?.status;
    },
  },
});

export default authSlice.reducer;
export const { reset, setOtp, setUserEmail, logout } = authSlice.actions;
