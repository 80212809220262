import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { firstLetterUpper } from "src/helpers";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";
const heading = ["Title", "Count"];
const Referral_Partner_Profile_Detail = ({
  data,
  getReferralComplementaryAndInActiveUserInfo,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const tableData =
    data &&
    Object.keys(data).length > 0 &&
    Object.keys(data).map((el) => {
      const field = data[el];
      const key = el
        .replace("activeReferralPartners", "active_Partners")
        .replace("completedReferralPartners", "Completed_Profiles")
        .replace("inActiveReferralPartners", "Inactive_Partners")
        .replace("inCompletedReferralPartners", "Incomplete_Profiles")
        .replace("totalReferralPartners", "Total_Referral_Partners");
      return {
        title: firstLetterUpper(key),
        count: field,
      };
    });

  const sendReportOnMail = () => {
    getReferralComplementaryAndInActiveUserInfo(
      "REFERRAL_PARTNER_PROFILE_DETAILS"
    );
  };

  return (
    <>
      <div className="main-div h-auto mt24">
        <div className="heading">
          <div className="title">
            <h6 className="fs16">Referral Partners Profile Details</h6>
          </div>
          <div className="ml-auto">
            <button className="btn-msg" onClick={() => sendReportOnMail()}>
              <i className="msg-icon"></i>
            </button>
          </div>
        </div>
        <div className="light-table full table-sec">
          <Table>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
            <TableBody>
              {tableData &&
                tableData.length > 0 &&
                tableData.map((item, index) => {
                  return (
                    <TableRow key={`key_${index + 1}`}>
                      <TableCell>{item?.title}</TableCell>
                      <TableCell>{item?.count}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <a className="link ml18" onClick={() => setIsOpen(true)}>
            <u>Send Email</u>
          </a>
        </div>
      </div>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        type={"SALES_AGENT_INCOMPLETE_PROFILE"}
      />
    </>
  );
};

export default Referral_Partner_Profile_Detail;
