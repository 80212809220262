import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import { CommissionPercentage } from "src/constant/global";

const PendingRequestForm = ({
  request,
  count,
  fieldsToUpdate,
  setFieldsToUpdate,
  coupons,
}) => {
  return (
    <div className="reff-in">
      <h5 className="text-center">{`Referral Partner ${
        count ? "#" + count : ""
      }`}</h5>
      <form>
        <div className="row">
          <div className="col-md-6">
            <p className="m20 fs16 fw600">
              YES! I would like to Enroll in the Referral Program
            </p>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="user-sm-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  label="First Name"
                  type="text"
                  value={request?.firstName}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="user-sm-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  label="Last Name"
                  type="text"
                  value={request?.lastName}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="mail-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  label="Email"
                  type="text"
                  value={request?.email}
                  disabled
                />
              </div>
            </div>

            <div className="form-in  icon grey phone">
              <div className="f-in">
                <span className="icon-left">
                  <i className="phone-icon sm"></i>
                </span>
                <div className="code">
                  <input type="tel" value={request?.countryCode} />
                </div>
                <input
                  className="pl"
                  type="text"
                  disabled
                  value={request?.contactNumber}
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="flag-icon"></i>
              </span>
              <div className="f-in">
                <TextField label="Country" value={request?.country} disabled />
              </div>
            </div>

            <div className="form-in grey icon z10">
              <span className="icon-left">
                <i className="coupon-icon"></i>
              </span>
              <div className="f-in">
                <Select
                  id="demo-simple-select"
                  placeholder="Select Coupon"
                  getOptionLabel={(data) => {
                    return data.name;
                  }}
                  getOptionValue={(data) => {
                    return data._id;
                  }}
                  options={coupons}
                  onChange={(data) => {
                    setFieldsToUpdate({
                      value: data._id,
                      key: "couponCode",
                    });
                  }}
                />
              </div>
            </div>

            <div className="d-flexx pt16">
              <span className="fs14">Are you a member of The Sieve ?</span>

              <div className="checkbox ml24">
                <input type="radio" checked={request?.memberOfSieve} />
                <label>Yes</label>
              </div>

              <div className="checkbox ml16">
                <input type="radio" checked={!request?.memberOfSieve} />
                <label>No</label>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <p className="m20 fs16">Your Social Media Profiles</p>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="linkedin-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  placeholder="Linked In"
                  value={request?.socialMediaHandles?.linkedin}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="twitter-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  placeholder="Twitter"
                  value={request?.socialMediaHandles?.twitter}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="insta-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  placeholder="Instagram"
                  value={request?.socialMediaHandles?.instagram}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="facebook-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  placeholder="Facebook"
                  value={request?.socialMediaHandles?.facebook}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="mail-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  placeholder="Number of Email Contacts"
                  value={request?.socialMediaHandles?.emailContacts}
                  disabled
                />
              </div>
            </div>

            <div className="form-in grey icon">
              <span className="icon-left">
                <i className="percentage-icon"></i>
              </span>
              <div className="f-in">
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    placeholder="Add Commission"
                    options={CommissionPercentage}
                    // defaultValue={CommissionPercentage[0]}
                    onChange={(data) => {
                      setFieldsToUpdate({
                        value: data.value,
                        key: "commission",
                      });
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-12 ">
            <div className="form-in grey icon mt24">
              <span className="icon-left">
                <i className="text-icon"></i>
              </span>
              <div className="f-in">
                <TextField
                  label="Message"
                  type="text"
                  multiline
                  rows={4}
                  value={request?.message}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PendingRequestForm;
