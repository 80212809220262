import React from "react";
import CouponForm from "../couponForm";

const AddCoupon = ({ setAddNew }) => {
  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Add New Coupon</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setAddNew(false);
            }}
          >
            BACK
          </button>
        </div>
      </div>
      <CouponForm setAddNew={setAddNew} />
    </div>
  );
};

export default AddCoupon;
