import io from "socket.io-client";
import React from "react";

/*socket initilization**/

const socketUrl = "https://sieveapiqa.appskeeper.in/";

export const socket = io(socketUrl, {
  transports: ["websocket"],
});

export const SocketContext = React.createContext();
