import React, { useEffect, useState } from "react";
import GiftRecipientInfo from "./GiftRecipientInfo/GiftRecipientInfo";
import GiftSenderInfo from "./GiftSenderInfo";
import MembershipInfo from "./MembershipInfo";
import PaymentHistory from "./PaymentHistory";
import Carousel from "src/components/common/Carousel";
import { useSelector } from "react-redux";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

const GiftMemberShip = ({
  usersData,
  setCurrentSlide,
  currentSlide,
  getUserList,
  membershipType,
  pageNo,
  setPage,
}) => {
  const [editMemberships, setEditMemberships] = useState([]);
  const [currentGiftSlide, setCurrentGiftSlide] = useState(0);
  const [sendPersonalizedEmail, setSendPersonalizedEmail] = useState([]);

  const { loading } = useSelector((state) => {
    return state.users;
  });

  useEffect(() => {
    if (usersData && usersData?.length > 0) {
      const data = usersData.map((el) => {
        return {
          isOpen: false,
          isOpenSaved: false,
        };
      });
      const emailPopupData = usersData.map((el) => {
        return {
          open: false,
        };
      });
      setEditMemberships(data);
      setSendPersonalizedEmail(emailPopupData);
    }
  }, [usersData]);

  const handleSendPersonalizedEmail = () => {
    const popupData = [...sendPersonalizedEmail];
    let modalToOpen = popupData[currentSlide];
    modalToOpen = {
      open: !modalToOpen.open,
    };
    popupData[currentSlide] = modalToOpen;
    setSendPersonalizedEmail(popupData);
  };

  return (
    <div>
      {usersData && usersData.length > 0 ? (
        usersData?.length === 1 ? (
          usersData?.map((userData, index) => {
            return (
              <div className="" key={index}>
                <React.Fragment className="full">
                  <div className="recp-info">
                    <GiftRecipientInfo
                      info={userData}
                      membershipType={membershipType}
                      getUserList={getUserList}
                      currentGiftSlide={currentGiftSlide}
                      setCurrentGiftSlide={setCurrentGiftSlide}
                    />
                  </div>
                  <h6 className="fs16 mb24">
                    <span className="text-text">Details found for: </span>
                    {userData?.email}
                  </h6>
                  <div className="membership-row">
                    <GiftSenderInfo
                      info={userData}
                      membershipType={membershipType}
                    />
                    <MembershipInfo
                      info={userData}
                      membershipType={membershipType}
                      getUserList={getUserList}
                      editMemberships={editMemberships[index]}
                      currentGiftSlide={currentGiftSlide}
                      setCurrentGiftSlide={setCurrentGiftSlide}
                      setEditMemberships={({ value, key }) => {
                        const data = [...editMemberships];
                        let newObject = data[index];
                        newObject = {
                          ...newObject,
                          [key]: value,
                        };
                        data[index] = newObject;
                        setEditMemberships(data);
                      }}
                    />
                  </div>
                  {userData?.transactions &&
                    userData?.transactions.length > 0 && (
                      <PaymentHistory
                        paymentData={userData?.transactions || []}
                      />
                    )}
                </React.Fragment>
              </div>
            );
          })
        ) : (
          <Carousel
            setCurrentSlide={setCurrentSlide}
            fetchData={getUserList}
            pageNo={pageNo}
            setPage={setPage}
          >
            {usersData &&
              usersData?.length > 0 &&
              usersData?.map((userData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="recp-info">
                      <GiftRecipientInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                      />
                    </div>
                    <h6 className="fs16 mb24">
                      <span className="text-text">Details found for: </span>
                      {userData?.email}
                    </h6>
                    <div className="membership-row">
                      <GiftSenderInfo
                        info={userData}
                        membershipType={membershipType}
                      />
                      <MembershipInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        editMemberships={editMemberships[index]}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                        setEditMemberships={({ value, key }) => {
                          const data = [...editMemberships];
                          let newObject = data[index];
                          newObject = {
                            ...newObject,
                            [key]: value,
                          };
                          data[index] = newObject;
                          setEditMemberships(data);
                        }}
                      />
                    </div>
                    {currentSlide === index &&
                      userData?.transactions &&
                      userData?.transactions.length > 0 && (
                        <PaymentHistory
                          paymentData={userData?.transactions || []}
                        />
                      )}
                  </React.Fragment>
                );
              })}
          </Carousel>
        )
      ) : null}

      {usersData && usersData.length > 0 && (
        // && !usersData[currentSlide]?.isFreeTrial &&

        <div className="btns-lower">
          <button
            className="btn-white fs16"
            disabled={usersData[currentSlide]?.isFreeTrial}
            onClick={() => {
              const data = [...editMemberships];
              let newObject = editMemberships[currentSlide];
              newObject = {
                ...newObject,
                isOpen: true,
              };
              data[currentSlide] = newObject;
              setEditMemberships(data);
            }}
          >
            EDIT
          </button>
          <button
            className="btn fs16"
            disabled={loading}
            onClick={handleSendPersonalizedEmail}
          >
            SEND PERSONALIZED EMAIL
          </button>
        </div>
      )}
      {usersData &&
        usersData.length > 0 &&
        usersData[currentSlide] &&
        sendPersonalizedEmail &&
        sendPersonalizedEmail.length > 0 &&
        sendPersonalizedEmail[currentSlide] && (
          <SEND_PERSONALIZED_EMAIL_MODAL
            open={sendPersonalizedEmail?.[currentSlide]?.open}
            setOpen={handleSendPersonalizedEmail}
            email={
              !membershipType
                ? usersData[currentSlide].email
                : usersData[currentSlide]?.giftedBy?.email
            }
          />
        )}
    </div>
  );
};

export default GiftMemberShip;
