const EXPIRY_DATE_CONFIRMATION_TEXT = `Are you sure you want to edit “Expiry Date”?`;

const CHANGES_SAVED_TEXT = "Your Changes Have Been Saved";
const DELETE_CONTENT_TEXT = "Are you sure, You want to delete this content?";
const DELETE_EDITOR_PICK_TEXT =
  "Are you sure, You want to delete this editor's pick?";
const DELETE_COUPON_TEXT = "Are you sure, You want to delete this coupon?";
const ENABLE_MEBERSHIP_RATE =
  "Are you sure, You want to Enable Monthly Membership Rate?";
const DISABLE_MEBERSHIP_RATE =
  "Are you sure, You want to Disable Monthly Membership Rate?";

const CONFIRM_BLOCK_TEXT = "Are you sure you want to block this Sub Admin?";
const CONFIRM_UNBLOCK_TEXT = "Are you sure you want to unblock this Sub Admin?";

export {
  EXPIRY_DATE_CONFIRMATION_TEXT,
  CHANGES_SAVED_TEXT,
  DELETE_CONTENT_TEXT,
  ENABLE_MEBERSHIP_RATE,
  DISABLE_MEBERSHIP_RATE,
  CONFIRM_BLOCK_TEXT,
  CONFIRM_UNBLOCK_TEXT,
  DELETE_EDITOR_PICK_TEXT,
  DELETE_COUPON_TEXT,
};
