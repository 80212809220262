import { createSlice } from "@reduxjs/toolkit";
import {
  postStaticContent,
  updateStaticContent,
  getStaticContent,
} from "../../services/tutorial.service";

const initialState = {
  data: {},
  loading: false,
  message: "",
  images: [],
  videos: [],
  isImagesDragged: false,
};

const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },

    updatedStaticData(state, { payload }) {
      state.data = payload;
    },
    getUpdatedLists(state, { payload }) {
      state.images = payload?.images;
      state.videos = payload?.videos;
      state.message = payload?.message;
    },
    setImagesDragged(state, { payload }) {
      state.isImagesDragged = payload;
    },
  },
  extraReducers: {
    // setContent
    [postStaticContent.pending]: (state) => {
      state.loading = true;
    },
    [postStaticContent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data?.data;
      state.awsData = [...state.awsData, payload?.data?.data];
      state.message = payload?.data?.message;
    },
    [postStaticContent.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.data = null;
    },

    // updateContent
    [updateStaticContent.pending]: (state) => {
      state.loading = true;
    },
    [updateStaticContent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data?.data;
      state.message = payload?.data?.message;
    },
    [updateStaticContent.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.data = null;
    },

    // getStaticContent
    [getStaticContent.pending]: (state) => {
      state.loading = true;
    },
    [getStaticContent.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.loading = false;
      state.images = data?.images;
      state.videos = data?.videos;
      state.message = "Data fetched successfully.";
    },
    [getStaticContent.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.data = {};
    },
  },
});

export default tutorialSlice.reducer;
export const { updatedStaticData, getUpdatedLists, setImagesDragged } =
  tutorialSlice.actions;
