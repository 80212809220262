import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorAlert, successAlert } from "src/utils/alerts";
import axiosInstance from "src/utils/axios";
import { axiosBasicPost } from "src/utils/axios";

import { setSessionStorage } from "src/helpers";
import appConfig from "../../appConfig";

//Login As Admin
export const login = createAsyncThunk(
  "/auth/login",
  // Callback Function

  async ({ email, password, callback }, { rejectWithValue }) => {
    try {
      const response = await axiosBasicPost({
        path: `${appConfig.REACT_APP_API_BASE_URL}admin/login`,
        data: { email, password },
      });

      if (response && response.data?.statusCode === 200) {
        callback(response.data);
        successAlert(response.data?.message);
        const accessToken = response.data.data?.accessToken;
        const userType = response.data.data?.userType;
        const userId = response.data.data?._id;
        const module = response.data.data?.module;
        setSessionStorage("authToken", accessToken);
        setSessionStorage("userType", userType);
        setSessionStorage("userId", userId);
        if (module && module.length > 0) {
          setSessionStorage("module", JSON.stringify(module));
        }
      }
      return response;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data?.message);
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

//Logout As Admin
export const logout = createAsyncThunk(
  "auth/logout",
  async ({ token, callback }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("admin/logout", { token });
      callback(response?.data);
      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
        setSessionStorage("authToken", "");
        setSessionStorage("userType", "");
        setSessionStorage("userId", "");
        setSessionStorage("module", "");
        localStorage.clear();
      }
      return response;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data);
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

//Forgot Password As Admin
export const forgotPassword = createAsyncThunk(
  "/auth/forgot",
  // Callback Function
  async ({ email, callback }, { rejectWithValue }) => {
    try {
      const response = await axiosBasicPost({
        path: `${appConfig.REACT_APP_API_BASE_URL}admin/forgot-password`,
        data: { email },
      });
      callback(response?.data);
      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data?.message);
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "/auth/verifyOtp",
  // Callback Function
  async ({ email, otp, callback }, { rejectWithValue }) => {
    try {
      const response = await axiosBasicPost({
        path: `${appConfig.REACT_APP_API_BASE_URL}admin/verify-otp`,
        data: { email, otp },
      });
      callback(response.data);
      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data.message);
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

// Resend OTP

export const resendOtp = createAsyncThunk(
  "/auth/resendOtp",
  // Callback Function
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axiosBasicPost({
        path: `${appConfig.REACT_APP_API_BASE_URL}admin/resend-otp`,
        data: { email },
      });
      if (response?.data?.statusCode === 200) {
        successAlert(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data.message);
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

//Reset Password As Admin
export const resetPassword = createAsyncThunk(
  "/auth/reset",
  // Callback Function
  async ({ email, password, otp, callback }, { rejectWithValue }) => {
    try {
      const response = await axiosBasicPost({
        path: `${appConfig.REACT_APP_API_BASE_URL}admin/reset-password`,
        data: { email, password, otp },
      });
      if (response?.data?.statusCode === 200) {
        callback(response?.data);
        successAlert(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data) {
        errorAlert(error?.response?.data?.message);
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

//Change Password As Admin
export const updatePassword = createAsyncThunk(
  "/auth/update",
  // Callback Function
  async ({ email, password, newPassword }, { rejectWithValue }) => {
    try {
      return await axiosInstance.post("/admin/update-password", {
        email,
        password,
        newPassword,
      });
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);
