import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";

// Icons
import Button from "src/components/common/Button";
import IconButton from "@material-ui/core/IconButton";

//Local Imports
import { getGroupsList } from "src/redux/services/group.service";
import axios from "src/utils/axios";
import AddGroupModal from "./components/addGroup";
import AddTopicsModal from "./components/addTopics";
import {
  API_MESSAGES,
  NOT_AUTHORIZED_TEXT,
  VALIDATION_MESSAGES,
} from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import { isAddAccess, isEditAccess } from "src/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

const TopicManagement = () => {
  const dispatch = useDispatch();

  const { count = 0, groupData } = useSelector((state) => {
    return {
      count: state?.groups?.count,
      groupData: state?.groups?.groupsList,
    };
  });

  const [groupsList, setGroupList] = useState([]);
  const [editGroupList, setEditGroupList] = useState({});
  const [editGroupInput, setGroupInput] = useState({});
  const [errors, setErrors] = useState({});
  const [addGroup, setAddGroup] = useState(false);
  const [addTopic, setAddTopic] = useState(false);
  const [topicErrors, setTopicErrors] = useState({});

  const [editTopicInput, setEditTopicInput] = useState({});
  const [expansionData, setExpansionData] = useState({});

  useEffect(() => {
    dispatch(getGroupsList({}));
  }, []);

  useEffect(() => {
    setGroupList(groupData);
  }, [groupData]);

  const classes = useStyles();

  const handleSuccessData = () => {
    dispatch(getGroupsList({}));
  };

  const handleGroupEditClick = (id, type = "", name = "") => {
    const updatedEditGroupList = { ...editGroupList };
    const updatedInputList = { ...editGroupInput };

    if (type === "save" && updatedInputList[id]) {
      axios
        .put(`admin/editGroup`, {
          name: updatedInputList[id],
          status: true,
          groupId: id,
        })
        .then(() => {
          dispatch(getGroupsList({}));
          successAlert(API_MESSAGES.editGroupSuccess);
          if (updatedEditGroupList && updatedEditGroupList[id]) {
            delete updatedEditGroupList[id];
            delete updatedInputList[id];
            setEditGroupList(updatedEditGroupList);
            setGroupInput(updatedInputList);
          }
        })
        .catch((error) => {
          let err =
            error && error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(err);
          if (updatedEditGroupList && updatedEditGroupList[id]) {
            delete updatedEditGroupList[id];
            delete updatedInputList[id];
            setEditGroupList(updatedEditGroupList);
            setGroupInput(updatedInputList);
          }
        });
    } else {
      updatedEditGroupList[id] = "save";
      updatedInputList[id] = name;
      setEditGroupList(updatedEditGroupList);
      setGroupInput(updatedInputList);
    }
  };

  const onGroupChange = (e, id) => {
    const updtedGroupInputList = { ...editGroupInput };
    updtedGroupInputList[id] = e.target.value;
    setGroupInput(updtedGroupInputList);
    setTimeout(() => {
      document.getElementById(`group-${id}`).focus();
    }, 100);
  };

  const handleTopicBlurInput = (id) => {
    let err = {
      ...topicErrors,
    };
    if (
      editTopicInput &&
      editTopicInput[id] &&
      Object.keys(editTopicInput[id]).length > 0 &&
      (editTopicInput[id].name === "" || editTopicInput[id].name.length > 25)
    ) {
      err = { ...err, [id]: id };
      setTopicErrors(err);
    }
  };

  const handleTopicFocusInput = (id) => {
    let err = {
      ...topicErrors,
    };
    if (topicErrors && Object.keys(topicErrors).length > 0 && topicErrors[id]) {
      delete err[id];
      setTopicErrors(err);
    }

    setTimeout(() => {
      document.getElementById(`topic-${id}`).focus();
    }, 100);
  };

  const refetchData = (isSuccess, message) => {
    if (isSuccess) {
      handleSuccessData();
      successAlert(message);
    } else {
      errorAlert(message || "Something Went Wrong");
    }
  };

  const handleTopicDataChange = (value, id, type) => {
    let newObj = { ...editTopicInput[id] };
    if (newObj && Object.keys(newObj).length > 0) {
      newObj.name = value;
    }
    const newData = { ...editTopicInput, [id]: newObj };
    setEditTopicInput(newData);
    setTimeout(() => {
      document.getElementById(`topic-${id}`).focus();
    }, 100);
  };

  const handleTopicStatusDataChange = (value, id, type) => {
    let newObj = { ...editTopicInput[id] };
    if (newObj && Object.keys(newObj).length > 0) {
      if (type === "status") {
        newObj.status = value;
      }
    }
    const newData = { ...editTopicInput, [id]: newObj };
    setEditTopicInput(newData);
  };

  const handleExpansionData = (id) => {
    let obj = { ...expansionData };
    if (obj[id]) {
      delete obj[id];
    } else {
      obj = { ...obj, [id]: true };
    }
    setExpansionData(obj);
  };

  const CustomTableCell = ({ row, name }) => {
    const classes = useStyles();
    if (name === "status") {
      return (
        <TableCell align="left" className={classes.tableCell}>
          {editTopicInput && editTopicInput[row._id] ? (
            <Switch
              defaultChecked={editTopicInput[row._id].status}
              onChange={(e) =>
                handleTopicStatusDataChange(e.target.checked, row._id, "status")
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            <Switch
              checked={row.status}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        </TableCell>
      );
    } else {
      const topicNameData =
        row.cardCount && row.cardCount > 0
          ? `${row.name} ${" "}(${row.cardCount})`
          : row.name;
      return (
        <TableCell align="left" className={classes.tableCell}>
          {editTopicInput && editTopicInput[row._id] ? (
            <div className="editable">
              <Input
                id={`topic-${row._id}`}
                defaultValue={editTopicInput[row._id].name}
                name={name}
                onBlur={() => handleTopicBlurInput(row._id)}
                onFocus={() => handleTopicFocusInput(row._id)}
                onChange={(e) =>
                  handleTopicDataChange(e.target.value, row._id, "topicName")
                }
                className={classes.input}
              />
              <br />
              {topicErrors &&
                Object.keys(topicErrors).length > 0 &&
                topicErrors[row._id] && (
                  <span className="d-block error-msg mt10">
                    {editTopicInput[row._id].name === ""
                      ? VALIDATION_MESSAGES.topicInput
                      : VALIDATION_MESSAGES.maxLengthTopic}
                  </span>
                )}
            </div>
          ) : (
            topicNameData
          )}
        </TableCell>
      );
    }
  };

  const handleBlurInput = (id) => {
    let err = {
      ...errors,
    };
    if (
      editGroupInput &&
      Object.keys(editGroupInput).length > 0 &&
      (editGroupInput[id] === "" || editGroupInput[id].length > 20)
    ) {
      err = { ...err, [id]: id };
      setErrors(err);
    }
  };
  const handleFocusInput = (id) => {
    let err = {
      ...errors,
    };
    if (errors && Object.keys(errors).length > 0 && errors[id]) {
      delete err[id];
      setErrors(err);
    }
  };

  const handleEditClick = (id, topic, groupId) => {
    let data = { ...editTopicInput };
    data = { ...data, [id]: { ...topic, groupId } };
    setEditTopicInput(data);
  };

  const handleTopicSaveClick = (id) => {
    const data = editTopicInput && editTopicInput[id] ? editTopicInput[id] : {};

    const groupObj =
      groupsList && groupsList.length > 0
        ? groupsList.find((g) => g._id === data.groupId)
        : {};

    let topicObj = {};
    if (
      groupObj &&
      Object.keys(groupObj).length > 0 &&
      groupObj.topics &&
      groupObj.topics.length > 0
    ) {
      topicObj = groupObj.topics.find((t) => t._id === id);
    }

    let obj = {
      groupId: data.groupId || "",
      topicId: data._id || "",
      status: data.status || false,
    };

    if (
      topicObj.name &&
      data.name &&
      topicObj.name.trim() !== data.name.trim()
    ) {
      obj = {
        ...obj,
        name: data.name || "",
      };
    }

    const updatedInputList = { ...editTopicInput };

    axios
      .put(`admin/updateTopicStatusName`, {
        ...obj,
      })
      .then(() => {
        dispatch(getGroupsList({}));
        successAlert(API_MESSAGES.editTopicSuccess);
        if (updatedInputList && updatedInputList[id]) {
          delete updatedInputList[id];
          setEditTopicInput(updatedInputList);
        }
      })
      .catch((error) => {
        let err =
          error && error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(err);
        if (updatedInputList && updatedInputList[id]) {
          delete updatedInputList[id];
          setEditTopicInput(updatedInputList);
        }
      });
  };

  return (
    <div className="main-div">
      {addGroup && (
        <AddGroupModal
          open={addGroup}
          setOpen={setAddGroup}
          refetchData={refetchData}
        />
      )}

      {addTopic && (
        <AddTopicsModal
          open={addTopic}
          setOpen={setAddTopic}
          groupData={groupData}
          groupCount={count}
          refetchData={refetchData}
        />
      )}

      <div className="heading">
        <div className="title">
          <h6>Topic Management</h6>
        </div>

        <div className="ml-div">
          <div className="btn-grp">
            <div>
              <Button
                text="+ NEW GROUP"
                onHandleClick={() => {
                  const isAdd = isAddAccess("GROUP_MANAGEMENT");
                  if (isAdd) {
                    setAddGroup((prev) => !prev);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              />
            </div>

            <div>
              <Button
                text="+ NEW TOPIC"
                onHandleClick={() => {
                  const isAdd = isAddAccess("TOPICS_MANAGEMENT");
                  if (isAdd) {
                    setAddTopic((prev) => !prev);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="topic-row header">
        <div className="topic-left">
          <div className="topic-title">
            <div>
              <h5 className="">Group</h5>
            </div>
          </div>
        </div>

        <div className="th-title">
          <table>
            <thead>
              <tr>
                <th>Topic Name</th>
                <th>
                  Set Status (
                  <span className="text-text fw500"> Inactive / Active </span>)
                </th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      {/*  */}

      {groupsList &&
        groupsList.length > 0 &&
        groupsList.map((g, index) => {
          return (
            <div className="topic-row" key={index}>
              <div className="topic-left">
                {expansionData && expansionData[g._id] && (
                  <div>{/* <h5 className="">Group</h5> */}</div>
                )}
                <div className="topic-title">
                  {editGroupList && !editGroupList[g._id] && (
                    <h6>
                      {g.name || ""}{" "}
                      {g.cardCount && g.cardCount > 0
                        ? `${" "}(${g.cardCount})`
                        : ""}
                    </h6>
                  )}
                  {editGroupList &&
                    Object.keys(editGroupList).length > 0 &&
                    editGroupList[g._id] && (
                      <div className="editable">
                        <Input
                          id={`group-${g._id}`}
                          defaultValue={g.name}
                          onBlur={() => handleBlurInput(g._id)}
                          onFocus={() => handleFocusInput(g._id)}
                          onChange={(e) => onGroupChange(e, g._id)}
                          className={
                            errors &&
                            Object.keys(errors).length > 0 &&
                            errors[g._id]
                              ? `${classes.input} error`
                              : `${classes.input}`
                          }
                          type="text"
                        />
                        <br />
                        {errors &&
                          Object.keys(errors).length > 0 &&
                          errors[g._id] && (
                            <span className="d-block error-msg mt10">
                              {editGroupInput[g._id] === ""
                                ? VALIDATION_MESSAGES.groupInput
                                : VALIDATION_MESSAGES.maxLengthGroup}
                            </span>
                          )}
                      </div>
                    )}
                  {editGroupList && !editGroupList[g._id] && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const isEdit = isEditAccess("GROUP_MANAGEMENT");
                        if (isEdit) {
                          handleGroupEditClick(g._id, "edit", g.name);
                        } else {
                          errorAlert(NOT_AUTHORIZED_TEXT);
                        }
                      }}
                    >
                      <i className="edit-icon"></i>
                    </IconButton>
                  )}

                  {editGroupList &&
                    Object.keys(editGroupList).length > 0 &&
                    editGroupList[g._id] && (
                      <Button
                        text={"Save"}
                        disabled={
                          errors &&
                          Object.keys(errors).length > 0 &&
                          errors[g._id]
                            ? true
                            : false
                        }
                        onClick={() => handleGroupEditClick(g._id, "save")}
                      ></Button>
                    )}
                </div>
              </div>

              <div className="topic-body white-table">
                <Paper className={classes.root}>
                  {expansionData && expansionData[g._id] ? (
                    <Table className={classes.table} aria-label="caption table">
                      {/* <thead>
                        <tr>
                          <th>Topic Name</th>
                          <th>Set Status ( Inactive / Active )</th>
                          <th>Action</th>
                        </tr>
                      </thead> */}
                      <TableBody>
                        {g &&
                          g.topics.length > 0 &&
                          g.topics.map((row) => {
                            return (
                              <TableRow key={row._id}>
                                <CustomTableCell {...{ row, name: "name" }} />
                                <CustomTableCell {...{ row, name: "status" }} />
                                <TableCell className={classes.selectTableCell}>
                                  {editTopicInput && editTopicInput[row._id] ? (
                                    <Button
                                      text={"Save"}
                                      onClick={() =>
                                        handleTopicSaveClick(row._id)
                                      }
                                      disabled={
                                        topicErrors &&
                                        Object.keys(topicErrors).length > 0 &&
                                        topicErrors[row._id]
                                          ? true
                                          : false
                                      }
                                    ></Button>
                                  ) : (
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => {
                                        const isEdit =
                                          isEditAccess("TOPICS_MANAGEMENT");
                                        if (isEdit) {
                                          handleEditClick(row._id, row, g._id);
                                        } else {
                                          errorAlert(NOT_AUTHORIZED_TEXT);
                                        }
                                      }}
                                    >
                                      <i className="edit-icon"></i>
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  ) : (
                    <span className="fw600">
                      {g.topics && g.topics.length > 0
                        ? g.topics.length === 1
                          ? `${g.topics.length} Topic`
                          : `${g.topics.length} Topics`
                        : "No Topics"}
                    </span>
                  )}
                </Paper>

                {g.topics && g.topics.length > 0 && (
                  <button
                    className="btn-plus"
                    aria-label="expansion"
                    onClick={() => handleExpansionData(g._id)}
                  >
                    {expansionData && expansionData[g._id] ? (
                      <i className="minus-icon"></i>
                    ) : (
                      <i className="plus-icon"></i>
                    )}
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(TopicManagement);
