import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { firstLetterUpper, isDeleteAccess, isEditAccess } from "src/helpers";
import Action from "src/components/common/Action";
import moment from "moment-timezone";
import ModalComp from "src/components/common/modal";
import { DELETE_COUPON_TEXT } from "src/constant/modals";
import { errorAlert, successAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import customAxios from "src/utils/axios";

const heading = [
  "Sr. No.",
  "Name",
  "Code",
  "Status",
  "Discount Type",
  "Discount",
  "Start Date",
  "End Date",
  "Action",
];

const CouponsList = ({ list, fetchCoupons, setCouponToEdit }) => {
  const [isOpen, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const onDelete = (couponId) => {
    customAxios
      .delete(`admin/delete-coupon?couponId=${couponId}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          successAlert(res?.data?.message || "");
          setOpen(false);
          setIdToDelete("");
          fetchCoupons();
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
        setIdToDelete("");
      });
  };

  return (
    <>
      <div className="light-table full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell key={`key_${index + 1}`} align="left">
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.name || "-"}</TableCell>
                    <TableCell>{item.code || "-"}</TableCell>
                    <TableCell>
                      <span
                        className={`status-label fill-grey upper ${
                          item.status === "ACTIVE" ? "fill-green" : ""
                        }`}
                      >
                        {item.status ? firstLetterUpper(item.status) : "-"}
                      </span>
                    </TableCell>
                    <TableCell>
                      {item.type === "PERCENTAGE_DISCOUNT"
                        ? `Percentage`
                        : "Free Months"}
                    </TableCell>
                    <TableCell>
                      {item.discount
                        ? `${item.discount}%`
                        : `${
                            item.freeMonths == 1
                              ? item.freeMonths + " Month"
                              : item.freeMonths + " Months"
                          }` || "-"}
                    </TableCell>
                    <TableCell>
                      {item?.startDate
                        ? moment(item?.startDate).utc().format("DD MMM, YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {item?.endDate
                        ? moment(item?.endDate).utc().format("DD MMM, YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      <Action
                        id={item._id}
                        isView={false}
                        setDeleteId={() => {
                          const isDelete = isDeleteAccess("COUPON_MANAGEMENT");
                          if (isDelete) {
                            setIdToDelete(item._id);
                            setOpen(true);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                        setEditContentId={(id) => {
                          const isEdit = isEditAccess("COUPON_MANAGEMENT");
                          if (isEdit) {
                            setCouponToEdit(item);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <ModalComp
        open={isOpen}
        title="Confirmation"
        setOpen={() => setOpen(false)}
        content={DELETE_COUPON_TEXT}
        onClickYes={() => {
          onDelete(idToDelete);
        }}
      />
      {list && list.length === 0 && (
        <div className="no-content">
          <h6>No Coupon Added Yet</h6>
          <p>
            You haven’t added anything yet. Click on Add new button to add new
            coupon for coupon management.
          </p>
        </div>
      )}
    </>
  );
};

export default CouponsList;
