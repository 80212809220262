import React from "react";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import DraggbleComp from "../draggble";
import CancelButton from "../modal-cancel-btn";

const ModalComp = ({
  open,
  setOpen,
  title,
  content,
  actions = true,
  onClickYes,
}) => {
  return (
    <>
      <Dialog
        open={open}
        // className={classFilter}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={DraggbleComp}
        aria-labelledby="draggable-dialog-title"
      >
        <>
          <div className="modal-text basic-modal">
            {title && (
              <div className="heading">
                <h6 className="fs18 mb0">{title}</h6>
              </div>
            )}
            <div className="close-btn">
              <CancelButton
                onClose={() => {
                  setOpen(false);
                }}
              />
            </div>

            <DialogContent>
              <DialogContentText className="text-center fs18 fw400 text-black">
                {content}
              </DialogContentText>
            </DialogContent>

            {actions && (
              <div className="foot">
                <button
                  className="btn-white"
                  autoFocus
                  onClick={() => {
                    setOpen(false);
                  }}
                  text="No"
                >
                  NO
                </button>
                <button
                  className="btn"
                  autoFocus
                  onClick={() => {
                    onClickYes();
                  }}
                  text="Yes"
                >
                  YES
                </button>
              </div>
            )}
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ModalComp;
