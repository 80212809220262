import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const ReportPaidTable = ({ heading, data }) => {
  const tableData = data && Object.keys(data).length > 0 && [data];

  return (
    <>
      <div className="light-table full table-sec">
        <Table>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.live}</TableCell>
                    <TableCell>{item?.expired}</TableCell>
                    <TableCell>{item?.lapsed}</TableCell>
                    <TableCell>{item?.cancelled}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ReportPaidTable;
