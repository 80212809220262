import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
const heading = ["Registrations", "From Start", "Count"];
import { firstLetterUpper } from "src/helpers";

const FreeTrialTab = ({ salesData }) => {
  const fromStartData =
    (salesData &&
      salesData.fromStart &&
      Object.keys(salesData.fromStart).length > 0 &&
      Object.keys(salesData.fromStart).map((el) => {
        const field = salesData.fromStart[el];
        const key = el.replace("Users", "");
        return {
          name: firstLetterUpper(key),
          fromStartCount: field,
          count: salesData?.count ? salesData?.count[el] : "-",
        };
      })) ||
    [];
  return (
    <div className="full light-table yellow-line">
      <Table>
        <TableHead>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {fromStartData &&
            fromStartData.length > 0 &&
            fromStartData.map((item, index) => {
              return (
                <TableRow key={`key_${index + 1}`}>
                  <TableCell>{item.name || "-"}</TableCell>
                  <TableCell>{item.fromStartCount || "-"}</TableCell>
                  <TableCell>{item.count || "-"}</TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell>Conversion Rate</TableCell>
            <TableCell>
              <b>{salesData && salesData.conversionRate}%</b>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default FreeTrialTab;
