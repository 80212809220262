export const salesAgentColumns = [
  { key: "sr", label: "SNo.", _props: { scope: "col", align: "middle" } },
  {
    key: "name",
    label: "Name",
    _props: { scope: "col", align: "middle" },
  },
  {
    key: "email",
    label: "Email",
    _props: { scope: "col", align: "middle" },
  },
  {
    key: "commission",
    label: "Commission",
    _props: { scope: "col", align: "middle" },
  },
  {
    key: "action",
    label: "Action",
    _props: { scope: "col", align: "middle" },
  },
];
