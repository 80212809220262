import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { dateFormat } from "src/helpers";
import Action from "src/components/common/Action";
import ModalComp from "src/components/common/modal";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import Pagination from "@mui/material/Pagination";

const heading = [
  "S.No.",
  "User Type",
  "Modules",
  "Type of API",
  "Source",
  "Date & Time",
  "Error Message",
];

const ErrorList = ({ tableData, curPage, totalCount, setCurrPage }) => {
  const [open, setOpen] = useState(false);
  const [errorToView, setErrorToView] = useState();

  return (
    <>
      <div className="main-div h-auto  mt24">
        <div className="heading">
          <div className="title">
            <h6 className="fw600">Error Logs</h6>
          </div>
        </div>
        <div className="light-table full table-sec">
          <Table>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
            <TableBody>
              {tableData &&
                tableData.length > 0 &&
                tableData.map((item, index) => {
                  return (
                    <TableRow key={`key_${index + 1}`}>
                      <TableCell>
                        {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                      </TableCell>
                      <TableCell>{item?.userType}</TableCell>
                      <TableCell>{item?.moduleName}</TableCell>
                      <TableCell>{item?.route}</TableCell>
                      <TableCell>{item?.apiType}</TableCell>
                      <TableCell>
                        {item?.createdAt ? dateFormat(item?.createdAt) : "-"}
                      </TableCell>
                      <TableCell>
                        <Action
                          isEdit={false}
                          isDelete={false}
                          handleViewClick={() => {
                            setOpen(true);
                            setErrorToView(index);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {totalCount > 1 && (
          <Pagination
            count={totalCount}
            variant="outlined"
            shape="rounded"
            defaultPage={curPage}
            onChange={(type, page) => {
              setCurrPage(page);
            }}
          />
        )}
        <div>
          {tableData && tableData.length === 0 && (
            <div className="no-content">
              <h6>Error Logs Not Found</h6>
            </div>
          )}
        </div>
      </div>
      <ModalComp
        actions={false}
        open={open}
        title="Error Message"
        setOpen={setOpen}
        content={
          tableData &&
          tableData[errorToView] &&
          tableData[errorToView]?.errorLog &&
          JSON.parse(tableData[errorToView]?.errorLog).message
        }
      />
    </>
  );
};

export default ErrorList;
