import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./assets/scss/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-carousel/lib/styles.css";
import { ConfirmProvider } from "material-ui-confirm";

// Local imports
import AppRoutes from "./routes";
import { socket, SocketContext } from "./utils/sockets";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <>
      <SocketContext.Provider value={socket}>
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: { autoFocus: true },
            confirmationText: "Yes",
            cancellationText: "No",
          }}
        >
          <BrowserRouter>
            <Suspense fallback={loading}>
              <AppRoutes />
            </Suspense>
            <ToastContainer className="toast-boxes" autoClose={5000} />
          </BrowserRouter>
        </ConfirmProvider>
      </SocketContext.Provider>
    </>
  );
};

export default App;
