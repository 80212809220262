import React from "react";

const Loader = () => {
  return (
    <div className="main-loader">
      <div className="logo-pulse">
        <div className="spinner">
          <svg
            className="loader-svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5006 16.0003C31.5006 24.5609 24.5609 31.5006 16.0003 31.5006C7.43973 31.5006 0.5 24.5609 0.5 16.0003C0.5 7.43973 7.43973 0.5 16.0003 0.5C24.5609 0.5 31.5006 7.43973 31.5006 16.0003Z"
              stroke="#2B2E36"
              className="svg-elem-1"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7617 10.9336C12.3281 10.4795 12.3125 10.0156 12.3125 9.39258C12.3125 8.07031 12.8438 7.24707 15.2344 7.24707C19.4062 7.24707 21.0664 9.9668 21.8125 13.9834L22.25 13.9707L21.8652 8.06934H21.4668C20.9082 8.99316 19.9336 8.00195 19.168 7.62207C17.5898 6.83789 17.2539 6.82227 15.3418 6.82227C13.4277 6.82227 12.4395 7.40137 11.3438 8.33594C11.3262 8.43652 11.3145 8.54297 11.3105 8.65527C11.3066 8.75391 11.3105 8.85645 11.3242 8.96289C11.3945 9.53223 11.7402 10.207 12.7617 10.9336ZM10.9922 8.68066C10.8867 10.1221 10.7148 12.4561 16.1543 14.043C19.8633 15.1357 22.7441 16.3145 22.6191 19.6836L22.6133 19.9209C22.5684 21.1621 22.5332 22.2217 21.6992 23.0664C21.7969 21.626 20.6758 18.6602 15.2734 17.0684C11.584 15.9697 9.5332 14.3359 9.53711 11.998C9.53906 11.1367 9.67188 10.1348 10.9922 8.66602V8.68066ZM19.6055 22.291C19.6055 21.5508 19.4082 20.9756 18.9863 20.5029C20.5762 21.3906 21.2012 22.2139 21.3379 22.8809C21.377 23.0654 21.377 23.2383 21.3496 23.3965C19.998 24.5674 18.0742 25.375 16.0176 25.2354C13.4492 25.0605 12.8945 24.5322 12.668 24.3174C12.6387 24.2891 12.6152 24.2666 12.5938 24.251C12.4297 24.1318 10.7559 23.6045 10.1855 23.9512H9.77344L9.375 17.751L9.82617 17.7256C10.584 22.2412 12.0723 24.7734 15.9121 24.7734C18.1172 24.7734 19.6055 23.9502 19.6055 22.291Z"
              fill="#2B2E36"
              className="svg-elem-2"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
