import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
  selectStartDate,
  selectEndDate,
  minDate,
  maxDate,
  startDate,
  endDate,
  disableEndDate,
  setCurrPage,
}) => {
  return (
    <div className="date-pick date-input">
      <DatePicker
        selectsStart
        selected={startDate}
        placeholderText="Start-Date"
        startDate={startDate}
        minDate={minDate}
        endDate={endDate}
        onChange={(d) => {
          selectStartDate(d);
        }}
      />
      <span className="sep">-</span>
      <DatePicker
        selectsEnd
        placeholderText="End-Date"
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={maxDate}
        disabled={disableEndDate}
        onChange={(d) => {
          // setCurrPage(1);
          selectEndDate(d);
        }}
      />
    </div>
  );
};

export default DateRangePicker;
