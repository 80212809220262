import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  createUser,
  sendPersonalizedEmail,
} from "src/redux/services/user.service";

const initialState = {
  users: [],
  loading: false,
  message: "",
  count: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    // getUsers data
    [getUsers.pending]: (state) => {
      state.loading = true;
      state.users = [];
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.users = payload?.data?.data.result || [];
      state.message = payload?.data?.message;
      state.count = payload?.data?.count;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
      state.users = [];
    },

    ///sendPersonalizedEmail
    [sendPersonalizedEmail.pending]: (state) => {
      state.loading = true;
    },
    [sendPersonalizedEmail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
    },
    [sendPersonalizedEmail.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },

    // createUser data
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.message = payload?.data?.message;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
  },
});

export default userSlice.reducer;
