import React, { useEffect, useState } from "react";
import DataTable from "../Components/DataTable";
import Select from "react-select";
import {
  ByTypeOptionsSales,
  MembershipOptionsSales,
  SourceOptionsSales,
  StatusOptionsForSales,
} from "src/constant/global";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";

const ByGeography = ({ setLoading, startDate = null, endDate = null }) => {
  const [geographyData, setGeographyData] = useState();
  const [membershipTYpe, setMembershipTYpe] = useState("");
  const [byType, setType] = useState("");
  const [bySource, setBySource] = useState("");
  const [byStatus, setStatus] = useState("");
  const [byCoupon, setCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);

  useEffect(() => {
    getCouponList();
  }, []);

  const getCouponList = () => {
    setLoading(true);
    customAxios
      .get(`admin/list-coupons`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setCouponList(response?.data?.data?.result);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  useEffect(() => {
    getByGeography();
  }, [
    startDate,
    endDate,
    byCoupon,
    byStatus,
    bySource,
    byType,
    membershipTYpe,
  ]);

  const getByGeography = () => {
    setLoading(true);
    let url = `admin/sales-management-global/paid-memberships-by-geography?`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&`;
    }
    if (membershipTYpe !== "") {
      url = url + `membershipTYpe=${membershipTYpe}&`;
    }

    if (byType !== "") {
      url = url + `byType=${byType}&`;
    }

    if (bySource !== "") {
      url = url + `bySource=${bySource}&`;
    }

    if (byStatus !== "") {
      url = url + `byStatus=${byStatus}&`;
    }

    if (byCoupon !== "") {
      url = url + `byCoupon=${byCoupon}&`;
    }

    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setGeographyData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Geography</h6>
        </div>
      </div>
      <div className="select-option">
        <Select
          options={MembershipOptionsSales}
          placeholder="Select Membership Type"
          onChange={(data) => {
            setMembershipTYpe(data.value);
          }}
        />
        <Select
          options={ByTypeOptionsSales}
          placeholder="Select Type "
          onChange={(data) => {
            setType(data.value);
          }}
        />
        <Select
          placeholder="Select Source "
          options={SourceOptionsSales}
          onChange={(data) => {
            setBySource(data.value);
          }}
        />
        <Select
          placeholder="Select Coupon "
          options={couponList}
          getOptionLabel={(data) => {
            return data.name.toUpperCase();
          }}
          getOptionValue={(data) => {
            return data._id;
          }}
          onChange={(data) => {
            setCoupon(data._id);
          }}
        />
        <Select
          placeholder="Select Status "
          options={StatusOptionsForSales}
          onChange={(data) => {
            setStatus(data.value);
          }}
        />
      </div>
      <DataTable data={geographyData} title="Area" />
    </div>
  );
};

export default ByGeography;
