import React from "react";

const CancelButton = ({ onClose, disabled = false }) => {
  return (
    <div>
      <button
        type="button"
        className="close"
        aria-label="Close"
        disabled={disabled}
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default CancelButton;
