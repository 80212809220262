import React, { useEffect, useState } from "react";
import PendingRequestForm from "./components/pending-request-form";
import Carousel from "react-multi-carousel";
import { responsive } from "src/components/common/Carousel";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";

export const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  setCurrentSlide,
  fetchData,
  pageNo,
  setPage,
  ...rest
}) => {
  const {
    carouselState: { currentSlide, totalItems },
  } = rest;
  if (setCurrentSlide) {
    setCurrentSlide(currentSlide);
  }

  const [dataCount, setDataCount] = useState(0);

  return (
    <div className="slider-arrows">
      <button
        className=""
        onClick={() => {
          previous();
        }}
        disabled={currentSlide === 0}
      >
        <i className="caret-left-black-icon"></i>
      </button>
      <p>{`${currentSlide + 1}/ ${totalItems}`}</p>
      <button
        className=""
        onClick={() => {
          if (currentSlide === totalItems - 1 && fetchData && setPage) {
            setPage(pageNo + 1);
            fetchData({ pageNo: pageNo + 1, isAddSliderData: true });
            setDataCount(totalItems);
          } else {
            next();
          }
        }}
        disabled={dataCount === totalItems && currentSlide === totalItems - 1}
      >
        <i className="caret-right-black-icon"></i>
      </button>
    </div>
  );
};

const PendingRequests = ({
  pendingRequestList,
  getReferralRequests,
  getProfile,
  coupons,
  pageNo,
  setPage,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);

  useEffect(() => {
    if (pendingRequestList && pendingRequestList.length > 0) {
      let newArray = [...fieldsToUpdate];
      pendingRequestList.forEach((el) => {
        newArray.push({
          commission: 0,
          couponCode: "",
        });
      });
      setFieldsToUpdate(newArray);
    }
  }, [pendingRequestList]);

  const handleAcceptOrRejectRequest = (requestStatus) => {
    const dataToSend = {
      salesAgentId: pendingRequestList[currentSlide]?._id,
      commission:
        requestStatus === "APPROVED"
          ? fieldsToUpdate[currentSlide]?.commission
          : 0,
      couponId:
        requestStatus === "APPROVED"
          ? fieldsToUpdate[currentSlide]?.couponCode.trim()
          : "",
      requestStatus: requestStatus,
    };
    if (requestStatus !== "APPROVED") {
      delete dataToSend.commission;
      delete dataToSend.couponId;
    }

    customAxios
      .patch("admin/sales-agent/approve-reject-sales-agent", {
        ...dataToSend,
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          successAlert(response?.data?.message);
          getReferralRequests();
          getProfile();
          const data = [...fieldsToUpdate];
          data.splice(currentSlide, 1);
          setFieldsToUpdate(data);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const handleFieldsToUpdate = ({ key, value }) => {
    const data = [...fieldsToUpdate];
    let newObject = data[currentSlide];
    newObject = {
      ...newObject,
      [key]: value,
    };
    data[currentSlide] = newObject;
    setFieldsToUpdate(data);
  };

  const getDisabledStatus = () => {
    if (fieldsToUpdate && fieldsToUpdate.length > 0) {
      const field = fieldsToUpdate[currentSlide];
      return !field?.couponCode || !field?.commission;
    }
  };

  return (
    <div className="referral-form slider-over">
      {pendingRequestList && pendingRequestList.length === 1
        ? pendingRequestList.map((request, index) => {
            return (
              <PendingRequestForm
                request={request}
                fieldsToUpdate={fieldsToUpdate[index]}
                setFieldsToUpdate={handleFieldsToUpdate}
                coupons={coupons}
              />
            );
          })
        : pendingRequestList &&
          pendingRequestList.length > 0 && (
            <Carousel
              responsive={responsive}
              renderButtonGroupOutside={true}
              arrows={false}
              customButtonGroup={
                <ButtonGroup
                  setCurrentSlide={setCurrentSlide}
                  pageNo={pageNo}
                  setPage={setPage}
                  fetchData={getReferralRequests}
                />
              }
            >
              {pendingRequestList.map((request, index) => {
                return (
                  <PendingRequestForm
                    request={request}
                    count={index + 1}
                    fieldsToUpdate={fieldsToUpdate[index]}
                    setFieldsToUpdate={handleFieldsToUpdate}
                    coupons={coupons}
                  />
                );
              })}
            </Carousel>
          )}

      {pendingRequestList && pendingRequestList.length > 0 && (
        <div className="btns-lower pt24">
          <button
            className="btn-white"
            onClick={() => {
              handleAcceptOrRejectRequest("REJECT");
            }}
          >
            REJECT PARTNER AND SEND REJECT EMAIL
          </button>
          <button
            className="btn "
            onClick={() => handleAcceptOrRejectRequest("APPROVED")}
            disabled={getDisabledStatus()}
          >
            APPROVE PARTNER AND SEND WELCOME EMAIL
          </button>
        </div>
      )}

      {pendingRequestList && pendingRequestList.length === 0 && (
        <div className="no-content">
          <h6>No Pending Requests</h6>
        </div>
      )}
    </div>
  );
};

export default PendingRequests;
