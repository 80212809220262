import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/common/Button";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { isAddAccess } from "src/helpers";
import { getEditorPickList } from "src/redux/services/editor-pick.service";
import { errorAlert } from "src/utils/alerts";
import AddEditorPick from "./add-editor-pick";
import EditEditorPick from "./edit-editor-pick";
import EditorPickList from "./editor-pick-list";

const EditorPickEmailer = () => {
  const [addNew, setAddNew] = useState(false);
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [edit, setEdit] = useState(false);
  const [editorPickIdToEdit, setEditorPickIdToEdit] = useState("");

  const dispatch = useDispatch();
  const { loading, editorPickList } = useSelector((state) => {
    return state.editorPick;
  });

  useEffect(() => {
    fetchEditorPageList();
  }, [curPage, addNew, edit]);

  const fetchEditorPageList = () => {
    let url = `admin/editor-pick/list?limit=${MAX_PAGE_LIMIT}&page=${curPage}`;
    dispatch(
      getEditorPickList({
        url,
        callback: (res) => {
          const totalPagesCount =
            res.data.total && res.data.total > 0
              ? Math.ceil(res.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        },
      })
    );
  };
  return (
    <>
      {!addNew && !edit && (
        <div className="main-div">
          <div className="heading">
            <div className="title">
              <h6 className="">Editor’s Pick Management</h6>
            </div>

            <div className="ml-div">
              <div>
                <Button
                  text=" ADD NEW"
                  onHandleClick={() => {
                    const isAdd = isAddAccess("EDITOR_PICK_EMAILER");
                    if (isAdd) {
                      setAddNew(true);
                    } else {
                      errorAlert(NOT_AUTHORIZED_TEXT);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {loading && <Loader />}
          <EditorPickList
            list={editorPickList}
            curPage={curPage}
            totalCount={totalCount}
            setCurrPage={setCurrPage}
            fetchEditorPageList={fetchEditorPageList}
            setEditorPickToEdit={(id) => {
              setEditorPickIdToEdit(id);
              setEdit(true);
            }}
          />
        </div>
      )}
      {addNew && <AddEditorPick setAddNew={setAddNew} />}
      {edit && (
        <EditEditorPick setEdit={setEdit} editorPickId={editorPickIdToEdit} />
      )}
    </>
  );
};

export default EditorPickEmailer;
