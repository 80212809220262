import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";

const ComplimentaryMemberships = ({ setLoading, selectedDate }) => {
  const [data, setData] = useState();

  const graphData = data && [
    { name: "Live", value: data && data?.live },
    { name: "Expired/Cancelled", value: data && data?.expiredOrCancelled },
  ];

  useEffect(() => {
    getComlimentaryData();
  }, []);

  const getComlimentaryData = (isSendEmailReport = false) => {
    setLoading(true);
    customAxios
      .get(
        `admin/reports-analytics/complimentary-memberships?isSendEmailReport=${isSendEmailReport}`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setData(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getComlimentaryData(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Complimentary Memberships</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="pie-div">
        <PieChart width={300} height={270}>
          <Pie
            data={graphData}
            cx={100}
            cy={100}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {graphData &&
              graphData.length > 0 &&
              graphData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.name === "Live" ? "#2667f8" : "#bfd1fd"}
                />
              ))}
          </Pie>
          <Legend
            payload={
              graphData &&
              graphData.length > 0 &&
              graphData.map((item) => ({
                id: item.name,
                type: "rect",
                color: item.name === "Live" ? "#2667f8" : "#bfd1fd",
                value: `${item.value}` + " " + `${item.name}`,
              }))
            }
          />
        </PieChart>

        <div>
          <h4>{data && data?.totalMemberships}</h4>
          <p>Total Memberships</p>
        </div>
      </div>
    </div>
  );
};

export default ComplimentaryMemberships;
