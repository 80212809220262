import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  GeographyOptions,
  MembershipOptions,
  PaymentPlanOptions,
  StatusOptions,
} from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ReportPaidTable from "../components/ReportPaidTable";

const heading = ["Live", "Expired", "Lapsed", "Cancelled"];

const All_Paid_Memberships = ({
  setLoading,
  selectedDate,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [paidMemberships, setPaidMemberships] = useState();
  const [membershipType, setMembershipType] = useState("");
  const [planType, setPlanType] = useState(PaymentPlanOptions[0].value);
  const [paymentType, setPaymentType] = useState(StatusOptions[0].value);
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);

  useEffect(() => {
    getPidMemberships();
  }, [selectedDate]);

  useEffect(() => {
    if (membershipType || planType || paymentType || byGeography) {
      getPidMemberships();
    }
  }, [membershipType, planType, paymentType, byGeography]);

  const getPidMemberships = (isSendEmailReport = false) => {
    setLoading(true);

    let url = `admin/reports-analytics/all-paid-memberships?planType=${planType}&paymentType=${paymentType}&byGeography=${byGeography}&isSendEmailReport=${isSendEmailReport}&`;
    if (selectedDate !== null) {
      url = url + `selectedDate=${selectedDate.toISOString()}&`;
    }
    if (membershipType && membershipType !== "ALL") {
      url = url + `membershipType=${membershipType}&`;
    }

    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setPaidMemberships(res?.data?.data);
          } else {
            successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getPidMemberships(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">All Paid Memberships</h6>
        </div>
        <div className="ml-auto">
          <button className="btn-msg" onClick={() => sendReportOnMail()}>
            <i className="msg-icon"></i>
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          options={GeographyOptions}
          defaultValue={GeographyOptions[0]}
          onChange={(data) => {
            setGeography(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              allPaidMembershipsByGeography: data.value,
            });
          }}
        />
        <Select
          options={MembershipOptions}
          defaultValue={MembershipOptions[0]}
          onChange={(data) => {
            setMembershipType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              allPaidMembershipsMembershipType: data.value,
            });
          }}
        />
        <Select
          options={PaymentPlanOptions}
          defaultValue={PaymentPlanOptions[0]}
          onChange={(data) => {
            setPlanType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              allPaidMembershipsPlanType: data.value,
            });
          }}
        />
        <Select
          options={StatusOptions}
          defaultValue={StatusOptions[0]}
          onChange={(data) => {
            setPaymentType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              allPaidMembershipsPaymentType: data.value,
            });
          }}
        />
      </div>
      <div>
        <ReportPaidTable heading={heading} data={paidMemberships} />
      </div>
    </div>
  );
};

export default All_Paid_Memberships;
