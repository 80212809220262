import React from "react";
import CouponForm from "../couponForm";

const EditCoupon = ({ setEdit, couponToEdit }) => {
  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Edit Coupon</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setEdit(false);
            }}
          >
            BACK
          </button>
        </div>
      </div>

      <CouponForm isEdit={true} couponToEdit={couponToEdit} setEdit={setEdit} />
    </div>
  );
};

export default EditCoupon;
