import React, { useState } from "react";
import { Dialog } from "@mui/material";

import axios from "src/utils/axios";
import CancelButton from "../../../components/common/modal-cancel-btn";
import { errorMessage } from "src/helpers";
import { API_MESSAGES , VALIDATION_MESSAGES} from "src/constant/global";

export default function AddCategoryModal(props) {
  const { open, setOpen, refetchData } = props;
  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState(false);

  const handleAddCategory = () => {
    if (categoryName) {
      axios
        .post("admin/addCategory", { name: categoryName })
        .then(() => {
          setOpen(false);
          refetchData(true, API_MESSAGES.addCategorySuccess);
        })
        .catch((error) => {
          setOpen(false);
          let message = "";

          if (error?.response && error?.response?.data) {
            message =
            errorMessage(error);
          }
          
          refetchData(false, message||API_MESSAGES.addCategory);
        });
    }
  };

  return (
    <Dialog
      open={open}
      style={{ padding: "50px" }}
      onClose={() => setOpen(false)}
    >
      <div className="modal-text">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="heading flex-center">
          <h6>Add New Category</h6>
        </div>

        <div className="text pb24">
          <div className="form-in">
            <label>Category Name (Max 16 Char)</label>

            <input
              maxLength={16}
              type="text"
              className={error? 'error': ''}
              onBlur={() => {
                if (categoryName === "") {
                  setError(true);
                }
              }}
              onFocus={() => {
                if (error) {
                  setError(false);
                }
              }}
              placeholder="Add New Category Name Here*"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <br />
            {error && <span className="error-msg">{VALIDATION_MESSAGES.categoryInput}</span>}
          </div>
        </div>

        <div className="foot">
          <button
            disabled={error|| !categoryName}
            className="btn"
            text="Add"
            onClick={() => handleAddCategory()}
          >
            ADD
          </button>
        </div>
      </div>
    </Dialog>
  );
}
